import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { formatMoney } from "../../../../../utils/StringUtils";
import { InfoButton } from "./components/InfoButton";
import {
  handleRewardBalanceClick,
  handleTotalAdjustmentsClick,
  handleTotalPayoutClick,
  handleTotalRewardsClick,
} from "./rewardsDialogHandlers";
import { useSiteContext } from "../../../../SiteProvider";
import { useEffect } from "react";
import { MOBILE_SCREEN_SIZE } from "../../../../AppNavigation/constants";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DashboardPreviewHeader } from "../DashboardPreviewHeader";
import { SiteBrandingType, StoreBrandingType } from "../../../../../types/misc";
import useStoreType from "../../../../../utils/hooks/useStoreType";
import { CashoutContainer } from "./styled";
import { loadRewards } from "@/redux/actions/rewards";

const BalanceSummary = () => {
  const storeType = useStoreType();
  const isMobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  const { siteBrandingType } = useSiteContext();
  const mainEntity = useAppSelector(({ mainEntity }) => mainEntity);
  const rewards = useAppSelector(({ rewards }) => rewards.rewards);
  const loading = useAppSelector(({ rewards }) => rewards.loading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      mainEntity?.id &&
      (siteBrandingType === SiteBrandingType.CustomStore ||
        siteBrandingType === SiteBrandingType.BrandStore)
    ) {
      dispatch(loadRewards([mainEntity.id]));
    } else {
      dispatch(loadRewards());
    }
  }, [dispatch, siteBrandingType, mainEntity]);

  const lifetimeEarnings = rewards?.lifetimeEarnings || 0;
  const lifetimePayouts = rewards?.lifetimePayouts || 0;
  const lifetimeAdjustments = rewards?.lifetimeAdjustments || 0;

  const rewardBalance =
    lifetimeEarnings - lifetimePayouts - lifetimeAdjustments;
  const linkPrefix =
    storeType === StoreBrandingType.CustomDTCStore ? "" : "/dashboard";

  if (loading) return null;

  return (
    <div>
      <DashboardPreviewHeader
        title={"Your rewards"}
        linkTo={`${linkPrefix}/rewards/balance`}
        subtitle={isMobileView ? undefined : "View your reward summary"}
        indicator="grey"
        marginBottom={isMobileView ? 6 : undefined}
      />
      <CashoutContainer
        sx={{
          ".balance-section": {
            [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
              border: "none",
              padding: 0,
            },
            ".row": {
              [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                height: "auto",
                padding: "10px 0",
                "&:last-of-type": {
                  paddingBottom: 0,
                },
              },
              ".value": {
                [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                  gap: 1,
                },
              },
            },
          },
        }}
      >
        <div className="balance-section">
          <div className="row">
            <div className="label">Total rewards</div>
            <div className="value">
              {formatMoney(lifetimeEarnings)}{" "}
              <div role="button" onClick={handleTotalRewardsClick}>
                <InfoButton />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="label">Total payouts</div>
            <div className="value">
              {formatMoney(lifetimePayouts)}{" "}
              <div role="button" onClick={handleTotalPayoutClick}>
                <InfoButton />
              </div>
            </div>
          </div>
          {lifetimeAdjustments ? (
            <div className="row">
              <div className="label">Total adjustments</div>
              <div className="value">
                {formatMoney(lifetimeAdjustments)}{" "}
                <div role="button" onClick={handleTotalAdjustmentsClick}>
                  <InfoButton />
                </div>
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="label">Reward balance</div>
            <div className="value">
              {formatMoney(rewardBalance)}{" "}
              <div role="button" onClick={handleRewardBalanceClick}>
                <InfoButton />
              </div>
            </div>
          </div>
        </div>
      </CashoutContainer>
    </div>
  );
};

export default BalanceSummary;
