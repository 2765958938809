import { useState } from "react";

import "./getApp.scss";
import { joinClassNames, validatePhone } from "../../../../utils/StringUtils";
import { useLocationTrackingParams } from "../../../providers/LocationTrackingProvider";
import { Box } from "@mui/material";
import { MOBILE_SCREEN_SIZE } from "../../../AppNavigation/constants";
import { StoreError } from "../../../StoreComponents/StoreError";
import { useMediaQuery } from "@mui/material";
import { useAppSelector } from "../../../../redux/hooks";
import { SolidButton } from "../../../StoreComponents/StoreButton";
import { ERROR_MESSAGES } from "../../../../utils/errors/errorUtils";
import { formatPhoneNumber } from "@brandclub/common-ui";
import { getSendStatusMessage, sendText } from "./utils";
import { useSendStatus } from "./useSendStatus";

const TextTheApp = ({
  hideHeader = false,
  textTheAppCallBack,
  className = "",
}: {
  hideHeader?: boolean;
  textTheAppCallBack?: ({
    phoneNumber,
    email,
    name,
  }: {
    phoneNumber: string;
    email: string;
    name: string;
  }) => void;
  className?: string;
}) => {
  const [number, setNumber] = useState("");
  const { error, setError, sendStatus, setSendStatus } = useSendStatus({
    onStatusReset() {
      setNumber("");
    },
  });
  const userProfile = useAppSelector((state) => state.userProfile);
  const isSignedIn = !!userProfile;
  const userPhoneNumber = userProfile?.phoneNumber;
  const formattedPhoneNumber = formatPhoneNumber(userPhoneNumber);
  const isMobileView = useMediaQuery(`(max-width: ${MOBILE_SCREEN_SIZE}px)`);
  const [queryParams] = useLocationTrackingParams();

  const getValidPhoneNumber = () => {
    if (userPhoneNumber) {
      // remove all non-digit characters
      return userPhoneNumber.replace(/\D/g, "");
    }
    if (validatePhone(number)) {
      return `+1${number}`;
    }
    return undefined;
  };

  const handleSendText = async () => {
    try {
      const validPhoneNumber = getValidPhoneNumber();
      if (!validPhoneNumber) {
        setError("Please enter a valid phone number.");
        return;
      }
      setError("");
      setSendStatus("sending");
      if (textTheAppCallBack) {
        await textTheAppCallBack({
          phoneNumber: validPhoneNumber,
          email: "gettextinvite@stackline.com",
          name: "gettextinvite@stackline.com",
        });
      } else {
        await sendText({ phoneNumber: validPhoneNumber, queryParams });
      }
      setSendStatus("sent");
    } catch (e) {
      console.error("Error sending text invite", e);
      setError(ERROR_MESSAGES.default[0]);
      setSendStatus("notSend");
    }
  };
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
        [theme.breakpoints.down("md")]: {
          alignItems: "center",
        },
      })}
    >
      <div
        className={joinClassNames(className, "text_app_container")}
        style={{
          width: isMobileView ? "100%" : undefined,
        }}
      >
        <div className={`text_box ${sendStatus}`}>
          <div className="textme_header">Download the mobile app</div>
          {isSignedIn && userPhoneNumber ? (
            <SolidButton
              sx={{
                height: 50,
                fontSize: 16,
                width: 355,
                marginTop: "10px",
                [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                  width: "100%",
                  height: 40,
                  fontSize: 12,
                  maxWidth: 300,
                },
              }}
              className="solid_textlink_to_button"
              onClick={sendStatus === "notSend" ? handleSendText : undefined}
              disabled={sendStatus === "sending"}
            >
              {getSendStatusMessage(
                sendStatus,
                `Text link to ${formattedPhoneNumber}`
              )}
            </SolidButton>
          ) : (
            <Box
              className="textme_input"
              sx={{
                marginTop: "10px",
                height: isMobileView ? "auto" : undefined,
                padding: isMobileView ? "5px" : undefined,
              }}
            >
              {sendStatus !== "sent" && (
                <div
                  className="text_prefix"
                  style={{
                    fontSize: isMobileView ? "14px" : undefined,
                  }}
                >
                  +1
                </div>
              )}
              {sendStatus !== "sent" && (
                <input
                  className={sendStatus}
                  disabled={["sending", "sent"].includes(sendStatus)}
                  placeholder="Phone number"
                  value={number}
                  onChange={(e) => {
                    setError("");
                    setNumber(e.target.value);
                  }}
                  style={{
                    fontSize: isMobileView ? "14px" : undefined,
                  }}
                />
              )}
              <SolidButton
                className={`text_button ${sendStatus}`}
                onClick={sendStatus === "notSend" ? handleSendText : undefined}
                disabled={sendStatus === "sending"}
                style={{
                  fontSize: isMobileView ? "14px" : undefined,
                  height: isMobileView ? "40px" : undefined,
                  width:
                    isMobileView && sendStatus !== "sent" ? "auto" : undefined,
                  paddingLeft: isMobileView ? "14px" : undefined,
                  paddingRight: isMobileView ? "14px" : undefined,
                }}
              >
                {getSendStatusMessage(sendStatus, "Send text")}
              </SolidButton>
            </Box>
          )}
        </div>
      </div>
      <StoreError
        errorMessage={error}
        sx={{
          marginTop: 2.5,
          width: "max-content",
        }}
      />
    </Box>
  );
};

export default TextTheApp;
