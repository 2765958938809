import { TopBrand } from "../../../redux/types";
import { UserLoginMachineUserProfile } from "./types";

// connect retailer if not connected to any retailer previously
export const shouldConnectRetailer = (
  userProfile?: UserLoginMachineUserProfile
) => {
  const connectedStateValues = Object.values(
    userProfile?.extendedAttributes || {}
  ).filter((val) => val === "valid"); // e.g. connectedRetailer_1_state
  const lastScanAnyRetailer = (userProfile?.extendedAttributes as any)
    ?.lastScanAnyRetailer;

  return (
    !!userProfile && connectedStateValues.length < 1 && !lastScanAnyRetailer
  );
};

export const MIN_N_BRANDCLUB_MEMBERSHIPS = 4;

export const hasJoinedMinBrandclubs = (topBrands: TopBrand[] | undefined) => {
  return (
    !!topBrands &&
    topBrands.filter((b) => b.brandclubId !== "BRANDCLUB").length >=
      MIN_N_BRANDCLUB_MEMBERSHIPS
  );
};
