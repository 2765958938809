import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { ShippingGroupPreview } from "./ShippingGroupPreview";
import { UserInputUpdateType } from "../../CheckoutMachine/types/GlobalCheckoutMachine";
import { useCheckout } from "../../CheckoutMachine";
import { useOrder } from "../../OrderProvider";
import { ChevronDownIcon } from "@brandclub/common-ui";
import { ShoppingCartItem } from "../../types";
import { SummaryProduct } from "../SummaryProduct";

export const getErrorProducts = (items: ShoppingCartItem[]) => {
  let res: ShoppingCartItem[] = items;
  res = res.filter(
    (item) => item.status === "error" && item?.userApprovalStatus !== "deleted"
  );
  return res;
};

const DeliverySection = () => {
  const { openDelivery: open, setOpenDelivery: setOpen } = useOrder();
  const { snapshot, send: checkoutSend } = useCheckout();
  const order = snapshot.context.retailerCheckoutOrderStatus;
  const shippingGroups =
    order?.userRetailerCheckoutOrder?.shippingOptions?.shippingGroups;
  const [openOptions, setOpenOptions] = useState<boolean[]>([]);

  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const length = shippingGroups?.length ?? 0;
    setOpenOptions(new Array(length).fill(open));
  }, [open, shippingGroups?.length]);

  const items = order?.userRetailerCheckoutOrder?.items;

  const errorProducts = getErrorProducts(items ?? []);

  const handleUpdateShippingOption = async (
    deliveryOption?: {
      name: string;
      cssSelector: string;
    },
    deliveryTimeWindow?: {
      name: string;
      cssSelector: string;
    }
  ) => {
    try {
      checkoutSend({
        type: "CHECKOUT_USER_INPUT_EVENT",
        inputEvent: UserInputUpdateType.UPDATE_SHIPPING_OPTION,
        data: {
          deliveryOption,
          deliveryTimeWindow,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box className="section">
      <div
        className="section_head"
        style={{ cursor: "pointer" }}
        onClick={() => toggleOpen()}
      >
        <div className="title">Delivery</div>
        <ChevronDownIcon
          style={{
            transition: "transform 0.3s",
            width: "33px",
            height: "33px",
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
          }}
        ></ChevronDownIcon>
      </div>
      <div className="shipping_group_list">
        {shippingGroups?.map((e, index) => {
          return (
            <ShippingGroupPreview
              key={index}
              shippingGroup={e}
              index={index}
              handleUpdateShippingOption={handleUpdateShippingOption}
              deliveryOpen={openOptions}
              openItem={(index: number) => {
                const newOpenOptions = [...openOptions];
                newOpenOptions[index] = !newOpenOptions[index];
                setOpenOptions(newOpenOptions);
              }}
            />
          );
        })}
        {errorProducts.length > 0 ? (
          <>
            {errorProducts.map((e) => {
              return <SummaryProduct product={e as any} />;
            })}
          </>
        ) : null}
      </div>
    </Box>
  );
};

export default DeliverySection;
