import "./App.css";
import AppRoutes from "./components/AppRoutes";
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "./redux/hooks";
import {
  getAppConfig,
  getBrandAppConfigs,
} from "./redux/reducers/appConfig/thunk";
import axiosInstance from "./utils/axios";
import { getAuthorizationHeader, initializeAuth } from "./Auth";
import StoreFullScreenLoading from "./components/StoreComponents/StoreFullScreenLoading";
import { GetAppConfigFromServerResponse } from "./api/rest/unauthenticated/AppConfig";
import {
  FingerprintJSProvider,
  buildAppInstallationInfo,
  IAppInstallationInfo,
} from "@brandclub/common-ui";
import ConfigUtils from "./utils/ConfigUtils";

export const updateAppInstallationInfoInLandingPage =
  async (appInstallationInfo: {
    deviceInfo: any;
    appInfo: any;
  }): Promise<any> => {
    const headers: Record<any, any> = {
      ...(await getAuthorizationHeader()),
    };
    const res = await axiosInstance.post(
      "/appInstallation/update",
      appInstallationInfo,
      {
        headers,
      }
    );

    const { status, payload } = res.data || {};
    return status === "success" ? payload : undefined;
  };

const App = () => {
  const authConfig = useAppSelector(({ appConfig }) => appConfig?.authConfig);
  const userId = useAppSelector(({ userProfile }) => userProfile?.userId);
  const [isAuthInitialized, setIsAuthInitialized] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      if (authConfig) {
        await initializeAuth(authConfig);
        setIsAuthInitialized(true);

        try {
          if (userId) {
            const appId = ConfigUtils.getWebAppId();
            const appInstallationInfo: IAppInstallationInfo =
              await buildAppInstallationInfo({ userId, appId });
            if (appInstallationInfo) {
              await updateAppInstallationInfoInLandingPage(appInstallationInfo);
            }
          }
        } catch (e) {
          console.error(
            "An error occurred when building appInstallationInfo: ",
            e
          );
        }
      } else {
        const payload = (await dispatch(getAppConfig()))
          .payload as GetAppConfigFromServerResponse;
        if (payload.appConfig?.domainConfig) {
          // if there's no appConfig, get the brand app config as soon as we have the appConfig response to avoid race conditions
          dispatch(
            getBrandAppConfigs({
              appId: payload.appConfig.domainConfig.domainName,
            })
          );
        }
      }
    })();
  }, [authConfig, dispatch, userId]);

  // we should let the app load until we have the app config and cognito initialized
  if (!authConfig || !isAuthInitialized) {
    return <StoreFullScreenLoading />;
  }

  return (
    <FingerprintJSProvider
      hostDomainName={`https://${window.location.hostname}`}
    >
      <AppRoutes />
    </FingerprintJSProvider>
  );
};

export default App;
