import { assign, createMachine, DoneActorEvent, fromPromise } from "xstate";
import _get from "lodash/get";
import {
  UserRetailerCheckoutBatch,
  UserRetailerCheckoutOrder,
  UserRetailerCheckoutSessionStatus,
  UserRetailerShoppingCart,
} from "../types";
import {
  cancelCheckout,
  getRetailerCheckoutOrderStatus,
  initiateRetailerCheckout,
  RetailerCheckoutOrderStatus,
  retryRetailerLoginDuringCheckout,
} from "@/api/rest/checkoutApi";
import {
  CheckoutStatusChangeEvent,
  UserInputUpdateType,
  GlobalCheckoutMachineContext,
  GlobalCheckoutMachineEvents,
  GlobalCheckoutState,
  LoginSubmitEvent,
  UpdateRetailerConnectionInfoList,
} from "./types/GlobalCheckoutMachine";
import { getTOtp } from "../../../api";
import { encryptData } from "../../../utils/encryptionUtils";
import { CheckoutUserInputMachine } from "./CheckoutUserInputMachine";
import { CheckoutUserInputEvent } from "./types/CheckoutUserInputMachine";
import { serverSideSyncApolloClient } from "@/redux/reduxApolloClient";
import { SearchResult } from "../../../types/search";
import { UserPurchaseHistory } from "@/types/misc";
import { getUserRetailerCheckoutOrderDetailBreakup } from "@/api/graphQl/authenticated/Purchase";
import { isPurchaseCheckoutSuccess } from "../utils";
import { getStepFromCartProducts } from "../utils";
import { getActiveCarts } from "@/redux/reducers/checkout/thunk";
import { isAllOutOfStock, isCheckoutInProgress } from "./utils";

export type GlobalCheckoutMachineType = typeof globalCheckoutMachine;

export const globalCheckoutMachine = (() => {
  const machineId = "globalCheckoutMachine";
  return createMachine(
    {
      id: machineId,
      initial: "waiting",
      types: {} as unknown as {
        context: GlobalCheckoutMachineContext;
        events: GlobalCheckoutMachineEvents;
      },
      on: {
        UPDATE_RETAILER_CONNECTION_INFO_LIST: {
          actions: ["updateRetailerConnectionInfoListFromRedux"],
        },
        RESET_CHECKOUT: {
          target: "#globalCheckoutMachine.handleResetCheckout",
        },
        CHECKOUT_NEXT_RETAILER: {
          target: "#globalCheckoutMachine.checkoutNextRetailer",
        },
        INITIATE_CHECKOUT: [
          {
            target: "#globalCheckoutMachine.loginScreen",
            guard: "isRetailerNotConnected",
          },
          {
            target: "#globalCheckoutMachine.initiateRetailerCheckout",
          },
        ],
      },
      context: ({
        input: { context },
      }: {
        input: { context: GlobalCheckoutMachineContext };
      }) => context,
      states: {
        waiting: {
          entry: [
            assign({
              globalCheckoutState: GlobalCheckoutState.IDLE,
            }),
          ],
        },
        loginScreen: {
          entry: [
            assign({
              globalCheckoutState: GlobalCheckoutState.LOGIN_SCREEN,
            }),
          ],
          on: {
            ON_LOGIN_INFO_SUBMIT: [
              {
                target: "retryLogin",
                guard: "isRetryLoginState",
                actions: ["updateConnectionInfo"],
              },
              {
                target: "initiateRetailerCheckout",
                actions: ["updateConnectionInfo"],
              },
            ],
            ON_LOGIN_SCREEN_CLOSE: {
              target: "waiting",
            },
          },
        },
        retryLogin: {
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "retryLogin",
            onDone: {
              target: "checkoutInProgress",
              actions: [assign({ checkoutRetailerConnectionState: undefined })],
            },
          },
        },
        initiateRetailerCheckout: {
          entry: [
            assign({
              globalCheckoutState: GlobalCheckoutState.INITIATING_CHECKOUT,
            }),
          ],
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "initiateRetailerCheckout",
            onDone: [
              {
                target: "checkoutSummary",
                actions: [
                  "updateUserRetailerCheckoutBatch",
                  "updateUserRetailerCheckoutOrder",
                  "updateRetailerCheckoutOrderStatus",
                ],
                guard: ({ event }) => {
                  const retailerCheckoutOrderStatus = (
                    event as DoneActorEvent<{
                      retailerCheckoutOrderStatus: RetailerCheckoutOrderStatus;
                    }>
                  )?.output.retailerCheckoutOrderStatus;
                  return (
                    retailerCheckoutOrderStatus
                      ?.userRetailerCheckoutSessionStatus?.status ===
                    UserRetailerCheckoutSessionStatus.CHECKOUT_WAITING_FOR_USER_INPUT
                  );
                },
              },
              {
                target: "checkoutInProgress",
                actions: [
                  "updateUserRetailerCheckoutBatch",
                  "updateUserRetailerCheckoutOrder",
                  "updateRetailerCheckoutOrderStatus",
                ],
              },
            ],
            onError: {
              actions: assign({
                error: ({ event }) =>
                  event.error instanceof Error
                    ? event.error
                    : {
                        message: "An unknown error occurred",
                        name: "UnknownError",
                      },
              }),
              target: "checkoutError",
            },
          },
        },
        checkoutInProgress: {
          invoke: {
            id: "fetchUpdatedCartItems",
            src: "fetchUpdatedCartItems",
            input: ({ context }) => ({ context }),
            onDone: {
              actions: [
                assign({
                  checkoutAllCarts: ({ event, context }) =>
                    event.output ?? context.checkoutAllCarts,
                }),
                "updateProductCards",
              ],
            },
          },
          entry: [
            assign({
              globalCheckoutState: GlobalCheckoutState.CHECKOUT_IN_PROGRESS,
              animationCompleted: false,
            }),
            "updateProductCards",
          ],
          initial: "waiting",
          states: {
            waiting: {
              on: {
                ON_CHECKOUT_STATUS_CHANGE: [
                  {
                    actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
                    target: "#globalCheckoutMachine.handleFailedAttempt",
                    guard: "isRetryableFailureAttempt",
                  },
                  {
                    actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
                    target: "#globalCheckoutMachine.checkoutSummary",
                    guard: "isCheckoutSummaryStage",
                  },
                  {
                    actions: [
                      "handleStatusChangeError",
                      "updateRetailerCheckoutOrderStatusFromPoller",
                    ],
                    target: "#globalCheckoutMachine.checkoutError",
                    guard: "isCheckoutErrorStage",
                  },
                  {
                    actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
                    target: "#globalCheckoutMachine.checkoutInProgress",
                    reenter: true,
                    guard: "hasCartChanges",
                  },
                  {
                    actions: [
                      "updateRetailerCheckoutOrderStatusFromPoller",
                      "updateProductCards",
                    ],
                  },
                ],
                ON_CHECKOUT_ANIMATION_COMPLETE: [
                  {
                    target: "#globalCheckoutMachine.checkoutSummary",
                    actions: ["updateAnimationComplete"],
                  },
                ],
              },
            },
          },
        },
        checkoutSummary: {
          entry: [
            assign({
              globalCheckoutState: GlobalCheckoutState.CHECKOUT_SUMMARY,
            }),
          ],
          initial: "waiting",
          states: {
            waiting: {
              on: {
                ON_CHECKOUT_STATUS_CHANGE: [
                  {
                    actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
                    target: "#globalCheckoutMachine.handleFailedAttempt",
                    guard: "isRetryableFailureAttempt",
                  },
                  {
                    actions: [
                      "updateRetailerCheckoutOrderStatusFromPoller",
                      "handleStatusChangeError",
                    ],
                    target: "#globalCheckoutMachine.checkoutError",
                    guard: "isCheckoutErrorStage",
                  },
                  {
                    actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
                    target:
                      "#globalCheckoutMachine.waitForCheckoutSuccessDataLoad",
                    guard: "isCheckoutSuccessStage",
                  },
                  {
                    actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
                    target: "#globalCheckoutMachine.checkoutInProgress",
                    guard: ({ event }) => {
                      const status =
                        event.data.retailerCheckoutOrderStatus
                          .userRetailerCheckoutSessionStatus?.status;
                      if (!status) {
                        return false;
                      }
                      return isCheckoutInProgress(status);
                    },
                  },
                  {
                    actions: [
                      "updateRetailerCheckoutOrderStatusFromPoller",
                      assign({
                        userInputUpdateType: undefined,
                      }),
                    ],
                    guard: ({ event }) =>
                      event.data.retailerCheckoutOrderStatus
                        .userRetailerCheckoutSessionStatus?.status ===
                      UserRetailerCheckoutSessionStatus.CHECKOUT_WAITING_FOR_USER_INPUT,
                  },
                  {
                    actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
                  },
                ],
                CHECKOUT_USER_INPUT_EVENT: {
                  target: "#globalCheckoutMachine.handleCheckoutUserInput",
                  actions: [
                    assign({
                      userInputUpdateType: ({ event }) => event.inputEvent,
                    }),
                  ],
                },
              },
            },
          },
        },
        handleCheckoutUserInput: {
          entry: assign({
            userInputError: undefined,
          }),
          invoke: {
            input: ({ context, event }) => ({
              context: {
                ...context,
                userInput: event as CheckoutUserInputEvent,
              },
            }),
            src: CheckoutUserInputMachine,
            onDone: [
              {
                target: "checkoutError",
                guard: ({ event }) => {
                  return !!event.output.error?.message;
                },
                actions: [
                  assign({
                    userInputError: ({ event }) => event.output.error,
                    userInputUpdateType: undefined,
                  }),
                ],
              },
              {
                target: "checkoutSummary",
                guard: ({ event }) => {
                  return !event.output.error?.message;
                },
                actions: [
                  assign({
                    userInputError: undefined,
                    retailerCheckoutOrderStatus: ({ event, context }) =>
                      event.output.retailerCheckoutOrderStatus ??
                      context.retailerCheckoutOrderStatus,
                  }),
                ],
              },
              {
                target: "checkoutUserInputUpdateInProgress",
                actions: [
                  assign(({ event }) => {
                    const userInputUpdateType = (
                      event as DoneActorEvent<{
                        userInputUpdateType: UserInputUpdateType;
                      }>
                    )?.output.userInputUpdateType;
                    return {
                      userInputUpdateType,
                    };
                  }),
                ],
              },
            ],
            onError: {
              target: "checkoutError",
              actions: [
                assign({
                  error: ({ event }) =>
                    event.error instanceof Error
                      ? event.error
                      : {
                          message: "An unknown error occurred",
                          name: "UnknownError",
                        },
                }),
              ],
            },
          },
        },
        handleResetCheckout: {
          invoke: {
            src: "cancelCurrentCheckout",
            input: ({ context }) => ({ context }),
            onDone: {
              target: "waiting",
              actions: [
                ({ context }) => {
                  // this will refresh the page and reset the state
                  context.navigate(0);
                },
              ],
            },
          },
        },
        checkoutUserInputUpdateInProgress: {
          entry: [
            assign({
              globalCheckoutState:
                GlobalCheckoutState.CHECKOUT_USER_INPUT_UPDATE_IN_PROGRESS,
            }),
          ],
          on: {
            ON_CHECKOUT_STATUS_CHANGE: [
              {
                actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
                target: "#globalCheckoutMachine.handleFailedAttempt",
                guard: "isRetryableFailureAttempt",
              },
              {
                actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
                target: "#globalCheckoutMachine.checkoutSummary",
                guard: "isCheckoutSummaryStage",
              },
              {
                actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
                target: "#globalCheckoutMachine.checkoutNextRetailer",
                guard: "isCheckoutSuccessStageAndNextRetailerAvailable",
              },
              {
                actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
                target: "#globalCheckoutMachine.waitForCheckoutSuccessDataLoad",
                guard: "isCheckoutSuccessStage",
              },
              {
                actions: [
                  "handleStatusChangeError",
                  "updateRetailerCheckoutOrderStatusFromPoller",
                ],
                target: "#globalCheckoutMachine.checkoutError",
                guard: "isCheckoutErrorStage",
              },
              {
                actions: ["updateRetailerCheckoutOrderStatusFromPoller"],
              },
            ],
          },
          exit: [assign({ userInputUpdateType: undefined })],
        },
        handleFailedAttempt: {
          invoke: {
            input: ({ context, event }) => ({ context, event }),
            src: "handleFailedAttempt",
            onDone: [
              {
                target: "loginScreen",
                actions: ["updateCheckoutRetailerConnectionState"],
                guard: "isLoginError",
              },
              {
                target: "checkoutSummary",
                guard: "isCheckoutSummaryStage",
              },
            ],
          },
        },
        waitForCheckoutSuccessDataLoad: {
          entry: [
            assign({
              globalCheckoutState:
                GlobalCheckoutState.WAIT_FOR_CHECKOUT_SUCCESS_DATA_LOAD,
            }),
          ],
          invoke: {
            input: ({ context }) => ({ context }),
            src: "waitForCheckoutSuccessDataLoad",
            onDone: {
              target: "checkoutSuccess",
            },
            onError: {
              target: "checkoutError",
              actions: [
                assign({
                  error: ({ event }) =>
                    event.error instanceof Error
                      ? event.error
                      : {
                          message: "An unknown error occurred",
                          name: "UnknownError",
                        },
                }),
              ],
            },
          },
        },
        checkoutSuccess: {
          entry: [
            assign({
              globalCheckoutState: GlobalCheckoutState.CHECKOUT_SUCCESS,
            }),
          ],
          on: {
            RESET_CHECKOUT: "#globalCheckoutMachine.handleResetCheckout",
          },
        },
        checkoutError: {
          entry: [
            assign({
              error: ({ context }) =>
                context.error ?? {
                  message: "An unknown error occurred",
                  name: "UnknownError",
                },
            }),
          ],
          on: {
            RESET_CHECKOUT: "#globalCheckoutMachine.handleResetCheckout",
          },
        },
        checkoutNextRetailer: {
          invoke: {
            input: ({ context }) => ({ context }),
            src: "checkoutNextRetailer",
            onDone: [
              {
                target: "loginScreen",
                actions: [
                  assign(
                    ({
                      event,
                    }: {
                      event: DoneActorEvent<{
                        activeCheckoutRetailerId: number;
                      }>;
                    }) => ({
                      activeCheckoutRetailerId:
                        event?.output?.activeCheckoutRetailerId,
                    })
                  ),
                ],
                guard: ({ context, event }) => {
                  const { activeCheckoutRetailerId } = event.output;
                  return (
                    context.retailerConnectionInfoList?.find(
                      (info) => info.retailerId === activeCheckoutRetailerId
                    )?.credential?.connectionStatus !== "valid"
                  );
                },
              },
              {
                target: "initiateRetailerCheckout",
                actions: [
                  assign(
                    ({
                      event,
                    }: {
                      event: DoneActorEvent<{
                        activeCheckoutRetailerId: number;
                      }>;
                    }) => ({
                      activeCheckoutRetailerId:
                        event?.output?.activeCheckoutRetailerId,
                    })
                  ),
                ],
              },
            ],
          },
        },
      },
    },
    {
      actors: {
        initiateRetailerCheckout: fromPromise<
          {
            userRetailerCheckoutBatch: UserRetailerCheckoutBatch;
            userRetailerCheckoutOrder: UserRetailerCheckoutOrder;
            retailerCheckoutOrderStatus: RetailerCheckoutOrderStatus;
          },
          { context: GlobalCheckoutMachineContext }
        >(async ({ input: { context }, signal }) => {
          const { currentStamp, token } = (await getTOtp()) ?? {};
          const loginInfo = context.retailerConnectionInfoList?.find(
            (info) => info.retailerId === context.activeCheckoutRetailerId
          )?.credential;

          if (
            !token ||
            !context.activeCheckoutRetailerId ||
            !context.retailerIds
          ) {
            throw new Error("Invalid input");
          }

          let connectionInfo = undefined;

          if (loginInfo?.username && loginInfo?.password) {
            connectionInfo = encryptData(
              [
                {
                  retailerId: context.activeCheckoutRetailerId,
                  credential: {
                    username: loginInfo?.username,
                    password: loginInfo?.password,
                  },
                },
              ],
              token
            );
          }
          const checkoutBatchId = context?.checkoutBatch?.checkoutBatchId ?? "";

          const { userRetailerCheckoutBatch, userRetailerCheckoutOrder } =
            await initiateRetailerCheckout(
              {
                retailerIds: context.retailerIds,
                checkoutBatchId,
                retailerId: context?.activeCheckoutRetailerId,
                currentStamp,
                connectionInfo,
                checkoutMode: "serverSide",
              },
              signal
            );

          const retailerCheckoutOrderStatus =
            await getRetailerCheckoutOrderStatus({
              orderId: userRetailerCheckoutOrder?.orderId,
            });

          return {
            userRetailerCheckoutBatch,
            userRetailerCheckoutOrder,
            retailerCheckoutOrderStatus,
          };
        }),
        handleFailedAttempt: fromPromise(
          async ({
            input: { context },
          }: {
            input: { context: GlobalCheckoutMachineContext };
          }) => {
            const { lastFailedAttempt } =
              context?.retailerCheckoutOrderStatus
                ?.userRetailerCheckoutSessionStatus ?? {};
            return { lastFailedAttempt };
          }
        ),
        retryLogin: fromPromise(
          async ({
            input: { context, event },
          }: {
            input: {
              context: GlobalCheckoutMachineContext;
              event: LoginSubmitEvent;
            };
          }) => {
            const { currentStamp, token } = (await getTOtp()) ?? {};
            const loginInfo = event.data.connectionInfo.credential;
            if (
              !token ||
              !loginInfo ||
              !context.activeCheckoutRetailerId ||
              !context.retailerIds
            ) {
              throw new Error("Invalid input");
            }
            const connectionInfo = encryptData(
              [
                {
                  retailerId: context.activeCheckoutRetailerId,
                  credential: {
                    username: loginInfo?.username,
                    password: loginInfo?.password,
                  },
                },
              ],
              token
            );
            await retryRetailerLoginDuringCheckout({
              orderId: context.activeCheckoutOrder?.orderId ?? "",
              currentStamp,
              connectionInfo,
            });
          }
        ),
        cancelCurrentCheckout: fromPromise<
          RetailerCheckoutOrderStatus,
          { context: GlobalCheckoutMachineContext }
        >(async ({ input: { context } }) => {
          if (
            !context.activeCheckoutOrder?.orderId ||
            !context.activeCheckoutRetailerId
          ) {
            throw new Error("Invalid input");
          }
          return cancelCheckout({
            orderId: context.activeCheckoutOrder?.orderId,
            retailerId: context.activeCheckoutRetailerId,
          });
        }),
        checkoutNextRetailer: fromPromise(
          async ({
            input: { context },
          }: {
            input: { context: GlobalCheckoutMachineContext };
          }) => {
            if (
              !context.activeCheckoutOrder?.orderId ||
              !context.activeCheckoutRetailerId
            ) {
              throw new Error("Invalid input");
            }
            await cancelCheckout({
              orderId: context.activeCheckoutOrder?.orderId,
              retailerId: context.activeCheckoutRetailerId,
            });
            const { checkoutBatch, retailerCheckoutOrderStatus } = context;
            if (!checkoutBatch?.retailerIds) {
              return;
            }
            const currentRetailerId =
              retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
                ?.retailerId ?? 0;
            const index = checkoutBatch?.retailerIds.indexOf(currentRetailerId);
            if (
              index === -1 ||
              index === checkoutBatch?.retailerIds?.length - 1
            ) {
              return;
            }
            const nextRetailerId = checkoutBatch?.retailerIds?.[index + 1];
            return {
              activeCheckoutRetailerId: nextRetailerId,
            };
          }
        ),
        waitForCheckoutSuccessDataLoad: fromPromise(
          async ({
            input: { context },
          }: {
            input: { context: GlobalCheckoutMachineContext };
          }) => {
            const { retailerCheckoutOrderStatus } = context;
            let interval: Timer | undefined = undefined;
            try {
              await Promise.race([
                new Promise((resolve) => {
                  interval = setInterval(async () => {
                    const res = await serverSideSyncApolloClient.query<{
                      UserRetailerCheckoutOrders: SearchResult<UserPurchaseHistory>;
                    }>({
                      query: getUserRetailerCheckoutOrderDetailBreakup,
                      variables: {
                        checkoutBatchIds: [
                          retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
                            ?.checkoutBatchId,
                        ],
                        page: {
                          start: 0,
                          size: 10,
                        },
                      },
                      fetchPolicy: "network-only",
                    });
                    const summaryRetailers = _get(
                      res,
                      ["data", "UserRetailerCheckoutOrders", "items"],
                      []
                    );

                    const items = summaryRetailers.filter(
                      (d) =>
                        d.checkoutBatchId ===
                          retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
                            ?.checkoutBatchId && isPurchaseCheckoutSuccess(d)
                    );

                    if (items.length > 0) {
                      resolve(true);
                    }
                  }, 1e3);
                }),
                new Promise((resolve) => {
                  // resolve in 5 seconds
                  setTimeout(() => {
                    resolve(false);
                  }, 5e3);
                }),
              ]);
            } catch (e: unknown) {
              console.error(e);
            } finally {
              clearInterval(interval);
            }
          }
        ),
        fetchUpdatedCartItems: fromPromise<
          UserRetailerShoppingCart[] | undefined,
          { context: GlobalCheckoutMachineContext }
        >(async ({ input: { context } }) => {
          const cartsResponse = await context.dispatch(getActiveCarts());
          if (cartsResponse.meta.requestStatus === "fulfilled") {
            const { allCarts } = cartsResponse.payload as {
              allCarts?: UserRetailerShoppingCart[];
            };
            return allCarts;
          }
        }),
      },
      actions: {
        enterCheckoutFlow: ({ context }) => {
          context.navigate("checkout");
        },
        updateProductCards: assign(({ context }) => {
          const { activeCheckoutRetailerId, retailerIds } = context;
          const carts =
            context.checkoutAllCarts?.find(
              (item) => item.retailerId === activeCheckoutRetailerId
            )?.items || [];

          if (!retailerIds) {
            return context;
          }
          return {
            enterAnimationConfig: {
              title:
                retailerIds?.length > 1
                  ? `Preparing order ${
                      retailerIds?.indexOf(activeCheckoutRetailerId as number) +
                      1
                    } of ${retailerIds?.length}`
                  : `Preparing order`,
              checkoutCards: getStepFromCartProducts(carts),
            },
          };
        }),
        updateRetailerConnectionInfoListFromRedux: assign(({ event }) => {
          const { retailerConnectionInfoList } = (
            event as UpdateRetailerConnectionInfoList
          ).data;
          return { retailerConnectionInfoList };
        }),
        updateUserRetailerCheckoutBatch: assign({
          checkoutBatch: ({ event }) => {
            const { userRetailerCheckoutBatch } = (
              event as DoneActorEvent<{
                userRetailerCheckoutBatch: UserRetailerCheckoutBatch;
              }>
            ).output;
            return userRetailerCheckoutBatch;
          },
        }),
        updateUserRetailerCheckoutOrder: assign(({ event }) => {
          const { userRetailerCheckoutOrder } = (
            event as DoneActorEvent<{
              userRetailerCheckoutOrder: UserRetailerCheckoutOrder;
            }>
          ).output;
          return { activeCheckoutOrder: userRetailerCheckoutOrder };
        }),
        updateRetailerCheckoutOrderStatus: assign(({ event }) => {
          const { retailerCheckoutOrderStatus } = (
            event as DoneActorEvent<{
              retailerCheckoutOrderStatus: RetailerCheckoutOrderStatus;
            }>
          ).output;
          return { retailerCheckoutOrderStatus };
        }),
        updateRetailerCheckoutOrderStatusFromPoller: assign(
          ({ context, event }) => {
            const { retailerCheckoutOrderStatus } = (
              event as CheckoutStatusChangeEvent
            ).data;
            const otpStatus = _get(
              retailerCheckoutOrderStatus,
              "retailerSyncSessions[0].eventSequence.type"
            );
            return {
              retailerCheckoutOrderStatus,
              otpStatus: otpStatus,
            };
          }
        ),
        handleStatusChangeError: assign({
          error: ({ event, context }) => {
            if (event.type !== "ON_CHECKOUT_STATUS_CHANGE") {
              throw new Error("Invalid event type");
            }
            if (
              event.data.retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
                ?.status === "checkout_error"
            ) {
              return {
                message:
                  event.data.retailerCheckoutOrderStatus
                    ?.userRetailerCheckoutOrder.errorMessage ??
                  "An unknown error occurred",
                name: "CheckoutError",
              };
            }

            return context.error;
          },
        }),
        updateCheckoutRetailerConnectionState: assign(({ context }) => {
          const { retailerCheckoutOrderStatus } = context;
          const { lastFailedAttempt } =
            retailerCheckoutOrderStatus?.userRetailerCheckoutSessionStatus ??
            {};
          if (lastFailedAttempt?.type === "login") {
            return {
              checkoutRetailerConnectionState: {
                retailerId:
                  retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
                    ?.retailerId,
                type: "RETRY_LOGIN",
                errorMessage: "please try again",
              } as GlobalCheckoutMachineContext["checkoutRetailerConnectionState"],
            };
          }
          return { checkoutRetailerConnectionState: undefined };
        }),
        resetAnimation: assign(() => {
          return { animationCompleted: false };
        }),
        updateAnimationComplete: assign(() => {
          return { animationCompleted: true };
        }),
        updateConnectionInfo: assign(({ context, event }) => {
          const { connectionInfo } = (event as LoginSubmitEvent).data;
          const { retailerConnectionInfoList = [] } = context;
          const index = retailerConnectionInfoList.findIndex(
            (info) => info.retailerId === connectionInfo.retailerId
          );

          if (index === -1) {
            retailerConnectionInfoList.push(connectionInfo);
          } else {
            retailerConnectionInfoList[index] = connectionInfo;
          }
          return {
            retailerConnectionInfoList: retailerConnectionInfoList ?? [],
          };
        }),
      },
      guards: {
        isRetailerNotConnected: ({ context }) => {
          const isRetailerNotConnected =
            !context.retailerConnectionInfoList?.find(
              (info) =>
                info.retailerId === context.activeCheckoutRetailerId &&
                info.credential?.connectionStatus === "valid"
            );
          return isRetailerNotConnected;
        },
        isLoginError: ({ context }) => {
          return (
            context?.retailerCheckoutOrderStatus
              ?.userRetailerCheckoutSessionStatus?.lastFailedAttempt?.type ===
            "login"
          );
        },
        isCheckoutSummaryStage: ({ context, event }) => {
          const { retailerCheckoutOrderStatus } = (
            event as CheckoutStatusChangeEvent
          ).data;
          return !!(
            retailerCheckoutOrderStatus?.userRetailerCheckoutSessionStatus
              ?.status ===
              UserRetailerCheckoutSessionStatus.CHECKOUT_WAITING_FOR_USER_INPUT &&
            context?.animationCompleted
          );
        },
        isAnimationCompleteAndCheckoutSummaryStage: ({ context, event }) => {
          return !!(
            context.retailerCheckoutOrderStatus
              ?.userRetailerCheckoutSessionStatus?.status ===
              UserRetailerCheckoutSessionStatus.CHECKOUT_WAITING_FOR_USER_INPUT &&
            event?.type === "ON_CHECKOUT_ANIMATION_COMPLETE"
          );
        },
        isCheckoutSuccessStage: ({ event }) => {
          const { retailerCheckoutOrderStatus } = (
            event as CheckoutStatusChangeEvent
          ).data;
          return (
            retailerCheckoutOrderStatus?.userRetailerCheckoutOrder?.status ===
            "checkout_success"
          );
        },
        isCheckoutErrorStage: ({ event, context }) => {
          const { retailerCheckoutOrderStatus } = (
            event as CheckoutStatusChangeEvent
          ).data;
          const allOutOfStock =
            context.checkoutBatch?.retailerIds?.length === 1 &&
            isAllOutOfStock(retailerCheckoutOrderStatus);
          return (
            retailerCheckoutOrderStatus?.userRetailerCheckoutOrder?.status ===
              "checkout_error" || allOutOfStock
          );
        },
        hasCartChanges: ({ context, event }) => {
          if (event.type !== "ON_CHECKOUT_STATUS_CHANGE") {
            return false;
          }
          const currentCart = context.checkoutAllCarts?.find(
            ({ retailerId }) => retailerId === context.activeCheckoutRetailerId
          );
          const incomingCartItems =
            event.data.retailerCheckoutOrderStatus.userRetailerCheckoutOrder
              ?.items;
          if (!currentCart?.items || !incomingCartItems) {
            return false;
          }
          if (currentCart.items.length !== incomingCartItems.length) {
            return true;
          }

          const updatedCartItems = new Map(
            incomingCartItems.map((item) => [item.retailerSku, item])
          );
          // check if any items or their quantities have changed
          return currentCart.items.some((item) => {
            const incomingItem = updatedCartItems.get(item.retailerSku);
            return !incomingItem || item.quantity !== incomingItem.quantity;
          });
        },
        isRetryableFailureAttempt: ({ event }) => {
          const retailerCheckoutOrderStatus = (
            event as CheckoutStatusChangeEvent
          )?.data.retailerCheckoutOrderStatus;
          return !!(
            retailerCheckoutOrderStatus?.userRetailerCheckoutSessionStatus
              ?.lastFailedAttempt?.type &&
            retailerCheckoutOrderStatus?.userRetailerCheckoutSessionStatus
              ?.status ===
              UserRetailerCheckoutSessionStatus.CHECKOUT_WAITING_FOR_USER_INPUT
          );
        },
        isRetryLoginState: ({ context }) => {
          return (
            context?.retailerCheckoutOrderStatus
              ?.userRetailerCheckoutSessionStatus?.lastFailedAttempt?.type ===
            "login"
          );
        },
        isCheckoutSuccessStageAndNextRetailerAvailable: ({
          context,
          event,
        }) => {
          const { checkoutBatch } = context;
          const { retailerCheckoutOrderStatus } = (
            event as CheckoutStatusChangeEvent
          ).data;
          if (
            retailerCheckoutOrderStatus?.userRetailerCheckoutOrder?.status !==
            "checkout_success"
          ) {
            return false;
          }
          if (!checkoutBatch?.retailerIds) {
            return false;
          }
          const currentRetailerId =
            retailerCheckoutOrderStatus?.userRetailerCheckoutOrder
              ?.retailerId ?? 0;
          const index = checkoutBatch?.retailerIds.indexOf(currentRetailerId);
          if (
            index === -1 ||
            index === checkoutBatch?.retailerIds?.length - 1
          ) {
            return false;
          }
          return true;
        },
      },
    }
  );
})();
