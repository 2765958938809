import { styled } from "@mui/material";
import React from "react";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../AppNavigation/constants";
import { alpha } from "@mui/material/styles";

const Container = styled("div")(({ theme }: { theme: any }) => ({
  padding: "25px 0",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  backgroundColor: alpha(theme.palette.secondary.main, 0.2),
  borderRadius: 8,
  alignItems: "center",
  paddingLeft: 25,
  paddingRight: 25,
  paddingVertical: 18,
  gap: 26,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    padding: "18px 0",
    paddingLeft: 25,
    paddingRight: 25,
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: "18px 0",
    paddingLeft: 15,
    paddingRight: 15,
  },
  ".title": {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.primary.main,
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 14,
    },
  },
  ".text": {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.primary.main,
    [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 12,
    },
  },
  ".icon": {
    width: 50,
    height: 50,
    borderRadius: 50,
  },
}));
const RewardBlock = ({ title, text }: { title?: string; text?: string }) => {
  return (
    <Container>
      <div className="content" style={{ gap: 4, flex: 1 }}>
        <div className="title">{title}</div>
        <div className="text">{text}</div>
      </div>
      <img
        alt=""
        src={"https://media.brandclub.com/brandclub/icons/rewardblock.webp"}
        className="icon"
      ></img>
    </Container>
  );
};
export default RewardBlock;
