import { getCurrentReferralRewardCampaigns } from "../../api/graphQl/authenticated/ReferralReward";
import ConfigUtils from "../../utils/ConfigUtils";
import { setReferralReward } from "../reducers/referralReward";
import reduxApolloClient from "../reduxApolloClient";
import { AppDispatch } from "../store";
import ReduxStore from "../types";

/* --------------------- referralReward ---------------------*/

export const loadReferralReward =
  () =>
  async (
    dispatch: AppDispatch
  ): Promise<ReduxStore["referralReward"] | null> => {
    try {
      const { data } = await reduxApolloClient.query({
        query: getCurrentReferralRewardCampaigns,
        fetchPolicy: "no-cache",
        context: {
          uri: `${ConfigUtils.getGraphQlUri()}?id=getCurrentReferralRewardCampaigns`,
        },
      });
      if (data && data.CurrentReferralRewardCampaigns) {
        const amountForInstallingUser =
          data?.CurrentReferralRewardCampaigns?.[0]?.extendedAttributes?.reward
            .rewardAmountForInstaller || null;
        const amountForReferringUser =
          data?.CurrentReferralRewardCampaigns?.[0]?.extendedAttributes?.reward
            .rewardAmountForReferrer || null;
        const referralReward = {
          amountForInstallingUser,
          amountForReferringUser,
        };
        dispatch(setReferralReward(referralReward));
        return referralReward;
      }
    } catch (e) {
      console.error("loadReferralReward error: ", e);
    }
    return null;
  };
