import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Stripe from "stripe";
import { ProfileContainer } from "../Profile";
import {
  addPaymentMethod,
  getPaymentMethods,
  removePaymentMethod,
  updatePaymentMethod,
} from "../../../../../api";
import { useAppSelector } from "../../../../../redux/hooks";
import { Address } from "../../../../../redux/types";
import {
  formatExpiry,
  getDateFromExpiry,
  idExpiryValid,
  removeSpaces,
} from "../../../../../utils/StringUtils";
import {
  ButtonLoadingEllipsis,
  OutlineButton,
  SolidButton,
} from "../../../../StoreComponents/StoreButton";
import StoreInput from "../../../../StoreComponents/StoreInput";
import StoreSelect from "../../../../StoreComponents/StoreSelect";

import cardValidator from "card-validator";
import { CardNumberVerification } from "card-validator/dist/card-number";

import { DashboardSolidButtonGrey } from "../DashboardOutlet";
import { DashboardHeaderContainer } from "../DashboardHeaderContainer";
import GenericDialog from "../../../../StoreComponents/StoreDialogs/GenericDialog";
import { StoreError } from "../../../../StoreComponents/StoreError";
import {
  getCardMasking,
  NumberFormatCustom,
} from "@/components/StoreComponents/NumberFormatCustom";

const WALLET_PATH = "/dashboard/wallet";

type AddressOption = {
  value: string;
  displayName: string;
  address: Partial<Address> | null;
};

const AddNewAddressOption: AddressOption = {
  value: "new",
  displayName: "+ Add new address",
  address: null,
};

const EditCard = ({ mode = "new" }: { mode: "new" | "edit" }) => {
  const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiration, setExpiration] = useState("");
  const [cvv, setCvv] = useState("");
  const [address1, setAddress1] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [error, setError] = useState<string | undefined>();
  const [deleting, setDeleting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cardInfo, setCardInfo] =
    useState<CardNumberVerification["card"] | undefined>(undefined);

  const [, setLoading] = useState(true);
  const [addressOptions, setAddressOptions] = useState<AddressOption[]>([
    AddNewAddressOption,
  ]);
  const [selectedAddressId, setSelectedAddressId] = useState<string>("");

  const addresses = useAppSelector((state) => state?.userProfile?.addresses);
  const defaultShippingAddressId = useAppSelector(
    ({ userProfile }) => userProfile?.defaultShippingAddressId || null
  );

  const navigate = useNavigate();
  const { cardId } = useParams();

  useEffect(() => {
    if (defaultShippingAddressId && !selectedAddressId) {
      setSelectedAddressId(defaultShippingAddressId);
    }
  }, [defaultShippingAddressId, selectedAddressId]);

  const handleSelectedAddress = (addressId: string) => {
    setSelectedAddressId(addressId);
    if (addressId === "new") {
      setAddress1("");
      setCity("");
      setState("");
      setZipCode("");
    } else {
      const selectedAddress = addressOptions?.find(
        (option) => option.value === addressId
      );

      if (selectedAddress?.address) {
        setAddress1(selectedAddress.address.address1 || "");
        setCity(selectedAddress.address.city || "");
        setState(selectedAddress.address.state || "");
        setZipCode(selectedAddress.address.zipCode || "");
      }
    }
  };

  const fetchUserPayment = async () => {
    setLoading(true);
    const res = await getPaymentMethods();

    if (res.data.status === "success") {
      const newPaymentMethods: Stripe.PaymentMethod[] = res.data.paymentMethods;
      const currentPaymentMethod = newPaymentMethods.find(
        ({ id }) => id === cardId
      );
      if (currentPaymentMethod) {
        const { card, billing_details } = currentPaymentMethod;

        setName(billing_details?.name || "");
        setCardNumber(`**** ${card?.last4}`);
        if (card?.exp_month !== undefined) {
          const formatExpriation = formatExpiry(
            card?.exp_year,
            card?.exp_month
          );
          setExpiration(formatExpriation);
        }

        setCvv("***");
        if (billing_details?.address) {
          const currentAddress = {
            id: "current",
            address1: billing_details?.address?.line1 || "",
            city: billing_details?.address?.city || "",
            state: billing_details?.address?.state || "",
            zipCode: billing_details?.address?.postal_code || "",
          };

          setAddressOptions((prev) => {
            const addressName = `${currentAddress?.address1} , ${currentAddress?.city}, ${currentAddress?.state} ${currentAddress?.zipCode}`;
            const newAddressOptions = [
              {
                value: "current",
                displayName: addressName,
                address: currentAddress,
              },
              ...prev,
            ];
            return newAddressOptions;
          });
        }
      } else {
        navigate(WALLET_PATH);
      }
    }
    setLoading(false);
  };

  const areValuesValidToSubmit = () => {
    if (!cardNumber) {
      return false;
    } else if (!name) {
      return false;
    } else if (!cvv) {
      return false;
    } else if (!idExpiryValid(expiration)) {
      return false;
    }
    if (state && city && address1 && zipCode) {
      return true;
    } else {
      return false;
    }
  };

  const initAddressOptions = () => {
    const addressOptions: AddressOption[] = [];

    addresses?.forEach((address, index) => {
      if (
        address.address1 &&
        address.city &&
        address.state &&
        address.zipCode
      ) {
        const addressName = `${address?.address1} , ${address?.city}, ${address?.state} ${address?.zipCode}`;
        addressOptions.push({
          value: address.id ?? addressName + index,
          displayName: addressName,
          address: address,
        });
      }
    });
    addressOptions.push(AddNewAddressOption);
    setAddressOptions(addressOptions);
  };

  useEffect(() => {
    if (addressOptions.find((option) => option.value === "current")) {
      handleSelectedAddress("current");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressOptions]);

  useEffect(() => {
    if (addresses?.length) {
      initAddressOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresses]);

  useEffect(() => {
    if (mode === "edit" && cardId) {
      fetchUserPayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardId, mode]);

  const handleDelete = async () => {
    setDeleting(true);
    const res = await removePaymentMethod({ paymentMethodId: cardId ?? "" });
    if (res.data.status === "success") {
      navigate(WALLET_PATH);
    } else {
      setError("Error deleting payment, please try latter");
    }
    setDeleting(false);
  };

  const showConfirmDeleteDialog = () => {
    GenericDialog.show({
      title: "Are you sure you want to delete this payment method?",
      text: "Once deleted, this action cannot be undone.",

      actions: [
        {
          text: "Cancel",
          onClick: () => {},
          variant: "outline",
        },
        {
          text: "Delete card",
          onClick: handleDelete,
          variant: "solid",
        },
      ],
    });
  };

  const clearError = () => {
    setError(undefined);
  };

  const handleAddCard = async () => {
    const ERR_TEXT = "An unknown error occurred. Please try again.";
    try {
      setError(undefined);
      setIsSubmitting(true);

      const number = removeSpaces(cardNumber);

      if (
        city &&
        address1 &&
        state &&
        zipCode &&
        cvv &&
        idExpiryValid(expiration) &&
        name &&
        number
      ) {
        const { month, year } = getDateFromExpiry(expiration);
        const res = await addPaymentMethod({
          card: {
            cvc: cvv,
            exp_month: month,
            exp_year: year,
            number,
          },
          billing_details: {
            address: {
              city: city,
              line1: address1,
              line2: undefined,
              postal_code: zipCode,
              state: state,
            },
            name,
          },
        });
        if (res.data.status === "success") {
          navigate(WALLET_PATH);
        } else {
          setError(res.data.errorMessage);
        }
      } else {
        setError("Please enter valid card details.");
      }
      // }
    } catch (e) {
      console.error(e);
      setError(ERR_TEXT);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditCard = async () => {
    const ERR_TEXT = "An unknown error occurred. Please try again.";
    try {
      setError(undefined);
      setIsSubmitting(true);
      const number = removeSpaces(cardNumber);

      if (
        city &&
        address1 &&
        state &&
        zipCode &&
        cvv &&
        idExpiryValid(expiration) &&
        name &&
        number
      ) {
        const { month, year } = getDateFromExpiry(expiration);
        const res = await updatePaymentMethod({
          paymentMethodId: cardId as string,
          card: {
            exp_month: month,
            exp_year: year,
          },
          billing_details: {
            address: {
              city,
              line1: address1,
              line2: undefined,
              postal_code: zipCode,
              state: state,
            },
            name,
          },
        });
        if (res.data.status === "success") {
          navigate(WALLET_PATH);
        } else {
          setError(res.data.errorMessage);
        }
      } else {
        setError("Please enter valid card details.");
      }
    } catch (e) {
      console.error(e);
      setError(ERR_TEXT);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSave = () => {
    if (mode === "edit") {
      handleEditCard();
    } else {
      handleAddCard();
    }
  };

  const numberFormatStr = getCardMasking(cardInfo);

  const sendingRequest = isSubmitting || deleting;

  return (
    <ProfileContainer>
      <div className="profile_header_container" style={{}}>
        <div className="profile_header">
          <DashboardHeaderContainer sx={{ marginBottom: "20px" }}>
            <div className="title">
              {mode === "new" ? "Add card" : "Edit card information"}
            </div>
          </DashboardHeaderContainer>
        </div>

        {mode === "new" ? null : (
          <div className="header_action">
            <DashboardSolidButtonGrey
              disabled={sendingRequest}
              onClick={showConfirmDeleteDialog}
            >
              {deleting ? (
                <>
                  Deleting
                  <ButtonLoadingEllipsis baseFontSize={16} />
                </>
              ) : (
                "Delete card"
              )}
            </DashboardSolidButtonGrey>
          </div>
        )}
      </div>

      <div className="fields_section_header">Card information</div>
      <div className="profile_fields">
        <StoreInput
          label="Name on card"
          value={name}
          onChange={(e) => {
            clearError();
            setName(e.target.value);
          }}
          sx={{ maxWidth: "100%" }}
        />
        <StoreInput
          label="Card number"
          disabled={mode === "edit"}
          value={mode === "edit" ? `${cardNumber}` : cardNumber}
          onChange={(e) => {
            clearError();
            if (mode === "edit") {
              return;
            } else {
              const cardInfo = cardValidator.number(e.target.value);
              setCardInfo(cardInfo?.card);
              setCardNumber(e.target.value);
            }
          }}
          inputProps={
            mode === "edit"
              ? {}
              : {
                  format: numberFormatStr ?? "#### #### #### ######",
                }
          }
          InputProps={
            mode === "edit"
              ? {}
              : {
                  inputComponent: NumberFormatCustom as any,
                }
          }
          sx={{ maxWidth: "100%" }}
        />
        <StoreInput
          label="Expiration"
          value={expiration}
          onChange={(e) => {
            clearError();
            setExpiration(e.target.value);
          }}
          inputProps={{
            format: "##/##",
          }}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
          }}
          sx={{ maxWidth: "100%" }}
        />
        <StoreInput
          label="CVV"
          disabled={mode === "edit"}
          value={cvv}
          onChange={(e) => {
            clearError();
            if (mode === "edit") {
              return;
            } else {
              setCvv(e.target.value);
            }
          }}
          sx={{ maxWidth: "100%" }}
        />
        <StoreSelect
          label="Billing address"
          value={selectedAddressId}
          onChange={(e) => {
            clearError();
            handleSelectedAddress(e.target.value as string);
          }}
          items={addressOptions}
          fullWidth
        />
      </div>
      {/* new address form */}
      {selectedAddressId === "new" ? (
        <>
          <div style={{ marginTop: 40 }}>
            <div className="fields_section_header">Billing Address</div>
          </div>
          <div className="profile_fields">
            <StoreInput
              label="Street address"
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
              sx={{ maxWidth: "100%" }}
            />

            <StoreInput
              label="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              sx={{ maxWidth: "100%" }}
            />
            <StoreInput
              label="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
              sx={{ maxWidth: "100%" }}
            />
            <StoreInput
              label="Zip code"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              sx={{ maxWidth: "100%" }}
            />
          </div>
        </>
      ) : null}
      <StoreError errorMessage={error} />
      <div className="profile_action">
        <Link to={WALLET_PATH} style={{ textDecoration: "none" }}>
          <OutlineButton trackedAdditionalData={{ cardId }}>
            Cancel
          </OutlineButton>
        </Link>
        <div style={{ flex: 1 }} />
        <SolidButton
          trackedAdditionalData={{ cardId }}
          disabled={!areValuesValidToSubmit() || sendingRequest}
          onClick={handleSave}
        >
          {isSubmitting ? (
            <>
              Saving
              <ButtonLoadingEllipsis baseFontSize={12} />
            </>
          ) : (
            "Save"
          )}
        </SolidButton>
      </div>
    </ProfileContainer>
  );
};

export default EditCard;
