import { getProfile } from "../../api";
import { AppDispatch } from "../store";
import { clearUserProfile, setUserProfile } from "../reducers/userProfile";
import ReduxStore, { Address } from "../types";

export const reloadProfile =
  (refreshCache?: boolean) => async (dispatch: AppDispatch) => {
    // On failure, we should force the user to logout
    try {
      const profileRes = await getProfile({ refreshCache });
      if (profileRes && profileRes.status !== "error") {
        dispatch(setUserProfile(profileRes));
        return profileRes;
      } else {
        dispatch(clearUserProfile());
        return null;
      }
    } catch (e) {
      console.error("reloadProfile error: ", e);
      return null;
    }
  };

export const updateAddresses =
  (addresses: Address[]) =>
  async (dispatch: AppDispatch, getState: () => ReduxStore) => {
    try {
      const { userProfile } = getState();
      const newProfile = { ...userProfile, addresses };
      dispatch(setUserProfile(newProfile));
    } catch (e) {
      console.error("loadReferralReward error: ", e);
    }
  };
