import { getRetailerCheckoutOrderStatus } from "@/api/rest/checkoutApi";
import { useRef, useEffect, useCallback } from "react";
import { useCheckout } from "./CheckoutMachine";

const ServerSideCheckoutStatusPoller = () => {
  const { send, snapshot } = useCheckout();
  const activeCheckoutOrder = snapshot.context.activeCheckoutOrder;
  const checkoutState = snapshot.value;
  const status = snapshot.status;
  const aborterRef = useRef(new AbortController());
  const pollIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const isCheckoutStatusFinal =
    status === "done" ||
    checkoutState === "checkoutError" ||
    checkoutState === "checkoutSuccess";

  const fetchOrderForPoll = useCallback(
    async (orderId: string) => {
      aborterRef.current.abort();
      const controller = new AbortController();
      aborterRef.current = controller;
      try {
        const res = await getRetailerCheckoutOrderStatus(
          {
            orderId,
          },
          controller.signal
        );
        send({
          type: "ON_CHECKOUT_STATUS_CHANGE",
          data: {
            retailerCheckoutOrderStatus: res,
          },
        });
        return res;
      } catch (e) {
        console.error("polling error", e);
      }
    },
    [send]
  );
  useEffect(() => {
    if (!activeCheckoutOrder?.orderId || isCheckoutStatusFinal) {
      return;
    }
    pollIntervalRef.current = setInterval(async () => {
      fetchOrderForPoll(activeCheckoutOrder.orderId);
    }, 3e3);

    return () => {
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
      }
    };
  }, [isCheckoutStatusFinal, activeCheckoutOrder?.orderId, fetchOrderForPoll]);

  return <> </>;
};

export default ServerSideCheckoutStatusPoller;
