import { ImageCropper, PendingIcon } from "@brandclub/common-ui";
import { styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

const Container = styled("div")({
  position: "relative",
  width: 80,
  height: 80,
  backgroundColor: "#f6f8fa",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  borderRadius: 999,

  ".action": {
    position: "absolute",
    width: 19,
    height: 19,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    top: 3,
    right: 3,
  },
  ".photo": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 999,
  },
});

const accept = { "image/*": [".jpeg", ".jpg", ".png", ".webp"] };

const getSupportedFileTypes = () => {
  const types: string[] = [];
  Object.keys(accept).forEach((key) => {
    // @ts-ignore
    const values: string[] = accept[key]; // key: image/*
    types.push(
      ...values.map(
        (value) => `${key.replace("*", "")}${value.replace(".", "")}`
      )
    );
  });
  return types;
};

const ProfilePhoto = ({
  initImage,
  image,
  setImage,
}: {
  initImage: string;
  image: File | undefined;
  setImage: (img: File) => void;
}) => {
  // const [loading, setLoading] = useState(false);
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const [cropperOpen, setCropperOpen] = useState(false);

  const supportedFileTypes = getSupportedFileTypes();

  const validateImage = (files: readonly File[] | null) => {
    if (!files) {
      alert("Please select a file to upload");
      return false;
    } else if (files.length > 1) {
      alert("Please just upload one file");
      return false;
    } else {
      if (!supportedFileTypes.includes(files[0].type)) {
        alert(
          `Please upload a file with the following format: ${supportedFileTypes.join(
            ", "
          )}, provided: ${files[0].type}`
        );
        return false;
      }
      return true;
    }
  };

  const fileSelectedHandler = (files: readonly File[]) => {
    if (files && files.length && validateImage(files)) {
      setCurrentFile(files[0]);
      setCropperOpen(true);
    }
  };

  const { getInputProps, open, acceptedFiles } = useDropzone({
    noKeyboard: true,
    multiple: false,
    accept: accept,
  });

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      fileSelectedHandler(acceptedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  const onFileChange = async (newFile: File | null) => {
    if (newFile) {
      setImage(newFile);
    }
  };

  return (
    <Container>
      <>
        {image ? (
          <img src={URL.createObjectURL(image)} alt="" className="photo" />
        ) : initImage ? (
          <img src={initImage} alt="" className="photo" />
        ) : null}
        <div className="action" role="button" onClick={open}>
          <PendingIcon color="primary" sx={{ fontSize: 19 }} />
        </div>
      </>

      <input {...getInputProps()} />
      <ImageCropper
        open={cropperOpen}
        setOpen={setCropperOpen}
        file={currentFile}
        onFileChange={onFileChange}
        cropperProps={{
          aspect: 1 / 1,
          cropShape: "round",
        }}
      />
    </Container>
  );
};

export default ProfilePhoto;
