import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import StoreInput from "../../../../StoreComponents/StoreInput";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { formatPhone } from "../../../../../utils/StringUtils";
import {
  ButtonLoadingEllipsis,
  OutlineButton,
  NeutralButton,
  SolidButton,
} from "../../../../StoreComponents/StoreButton";
import StoreSelect from "../../../../StoreComponents/StoreSelect";
import StoreDatePicker from "../../../../StoreComponents/StoreDatePicker";
import moment from "moment";
import {
  updateProfile,
  updateProfilePhotoUrl,
  uploadUserProfilePhoto,
} from "../../../../../api";

import { setUserProfile } from "../../../../../redux/reducers/userProfile";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../../AppNavigation/constants";

import ProfilePhoto from "./ProfilePhoto";
import { StoreError } from "../../../../StoreComponents/StoreError";
import { DashboardHeaderContainer } from "../DashboardHeaderContainer";
import { signOut } from "../../../../../Auth";

export const ProfileContainer = styled("div")(({ theme }) => ({
  ".profile_header_container": {
    // height: 44,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    profile_avatar: { marginRight: 20 },
    ".profile_header": {
      flex: 1,
      fontWeight: 700,
      fontSize: 16,
      color: theme.palette.primary.main,
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        fontSize: 22,
      },
    },
    ".header_action": {
      a: {
        textDecoration: "none",
      },
    },
  },

  ".fields_section_header": {
    fontWeight: 600,
    fontSize: 16,
    marginBottom: 20,
    color: theme.palette.primary.main,
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      fontSize: 16,
    },
  },
  ".profile_fields": {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: "34px",
    rowGap: "20px",
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  ".profile_action": {
    marginTop: 30,
    display: "flex",
    alignItems: "center",

    gap: 15,
  },
  ".error": {
    color: "#E57373",
  },
}));

const SignOutButton = () => {
  return (
    <NeutralButton
      onClick={async () => {
        await signOut();
      }}
      sx={{
        width: "143px",
        maxWidth: "143px",
        minWidth: "fit-content",
      }}
    >
      Sign out
    </NeutralButton>
  );
};
const Profile = () => {
  const tabletView = useMediaQuery(`(max-width: ${TABLET_SCREEN_SIZE}px)`);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] =
    useState<Date | string | null | undefined>();
  const [gender, setGender] = useState("");
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState<File | undefined>(undefined);

  const [error, setError] = useState("");

  const dispatch = useAppDispatch();
  const userProfile = useAppSelector(({ userProfile }) => userProfile);

  const fieldEnterCallback = () => {
    setError("");
    setSaved(false);
  };
  const initFields = () => {
    const { family_name, given_name, birthdate, email, phoneNumber, gender } =
      userProfile as any;

    const dob = moment(birthdate).toDate();
    setFirstName(given_name);
    setLastName(family_name);
    setContactEmail(email);
    setPhoneNumber(phoneNumber);
    setDateOfBirth(dob);
    setGender(gender);
    setProfilePhoto(undefined);
  };

  const cancelClick = () => {
    initFields();
  };

  useEffect(() => {
    if (userProfile) {
      initFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  const handleSave = async () => {
    try {
      setSaving(true);
      const dob = moment(dateOfBirth).format("MM/DD/YYYY");

      let newProfile = null;
      if (
        gender !== userProfile?.gender ||
        dob !== userProfile?.birthdate ||
        firstName !== userProfile?.given_name ||
        lastName !== userProfile?.family_name ||
        contactEmail !== userProfile?.email
      ) {
        const req = {
          gender: gender || "other",
          birthdate: dob,
          given_name: firstName,
          family_name: lastName,
          name: `${firstName} ${lastName}`,
          email: contactEmail,
        };

        const res = await updateProfile(req);
        newProfile = res?.data.result;
      }

      if (profilePhoto) {
        const { path } = await uploadUserProfilePhoto({ file: profilePhoto });
        const res = await updateProfilePhotoUrl({ path });

        if (res?.data.status === "failed") {
        } else {
          newProfile = res.data.user;
        }
      }
      if (newProfile) {
        dispatch(setUserProfile(newProfile));
      }
    } catch (e) {
    } finally {
      setSaving(false);
      setSaved(true);
    }
  };

  const canSave =
    gender !== userProfile?.gender ||
    moment(dateOfBirth).format("MM/DD/YYYY") !== userProfile?.birthdate ||
    firstName !== userProfile?.given_name ||
    lastName !== userProfile?.family_name ||
    contactEmail !== userProfile?.email ||
    profilePhoto;

  return (
    <ProfileContainer>
      <div className="profile_header_container" style={{}}>
        <div className="profile_header">
          {tabletView ? (
            <div className="profile_header">
              <DashboardHeaderContainer
                sx={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="title">Personal</div>
                <SignOutButton />
              </DashboardHeaderContainer>
            </div>
          ) : (
            <DashboardHeaderContainer
              sx={{
                marginBottom: "30px",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="title">
                <ProfilePhoto
                  initImage={userProfile?.profileImageUrl || ""}
                  image={profilePhoto}
                  setImage={setProfilePhoto}
                />
                <span>Personal</span>
              </div>
              <SignOutButton />
            </DashboardHeaderContainer>
          )}
        </div>
      </div>
      <div className="profile_fields">
        <StoreInput
          label="First name"
          value={firstName}
          onChange={(e) => {
            fieldEnterCallback();
            setFirstName(e.target.value);
          }}
          sx={{ maxWidth: "100%" }}
        />
        <StoreInput
          label="Last name"
          value={lastName}
          onChange={(e) => {
            fieldEnterCallback();
            setLastName(e.target.value);
          }}
          sx={{ maxWidth: "100%" }}
        />
        <StoreInput
          label="Contact email"
          value={contactEmail}
          onChange={(e) => {
            fieldEnterCallback();
            setContactEmail(e.target.value);
          }}
          sx={{ maxWidth: "100%" }}
        />
        <StoreInput
          disabled={true}
          label="Phone number"
          value={formatPhone(phoneNumber || "", "national")}
          onChange={(e) => {}}
          sx={{ maxWidth: "100%" }}
        />

        <StoreDatePicker
          value={dateOfBirth}
          onChange={(v) => {
            fieldEnterCallback();
            setDateOfBirth(v);
          }}
          label={"Date of birth"}
          inputProps={{
            sx: { maxWidth: "100%" },
          }}
          // showCalendarIcon
        />

        <StoreSelect
          label={"Gender"}
          value={gender}
          onChange={(e) => {
            fieldEnterCallback();
            setGender(e.target.value as string);
          }}
          items={[
            { displayName: "Male", value: "male" },
            { displayName: "Female", value: "female" },
            { displayName: "Other", value: "other" },
          ]}
          fullWidth
        />
      </div>
      {error ? (
        <>
          <StoreError
            sx={{
              marginTop: "10px",
              marginBottom: "0px",
              justifyContent: "center",
            }}
            errorMessage={error}
          />
        </>
      ) : null}
      <div className="profile_action">
        <OutlineButton disabled={!canSave || saving} onClick={cancelClick}>
          Cancel
        </OutlineButton>
        <div style={{ flex: 1 }}></div>
        <SolidButton disabled={!canSave || saving} onClick={handleSave}>
          {saved ? (
            "Saved!"
          ) : saving ? (
            <>
              Saving
              <ButtonLoadingEllipsis baseFontSize={12} />
            </>
          ) : (
            "Save"
          )}
        </SolidButton>
      </div>
    </ProfileContainer>
  );
};

export default Profile;
