import { CardNumberVerification } from "card-validator/dist/card-number";
import React from "react";
import {
  NumericFormat,
  PatternFormat,
  removePatternFormat,
} from "react-number-format";

export const getCardMasking = (card?: CardNumberVerification["card"]) => {
  const gaps = card?.gaps;
  const lengths = card?.lengths;

  if (gaps?.length && lengths?.length) {
    const maxLength = Math.max(...lengths);
    const arr: string[] = Array(maxLength).fill("#");
    let res = "";
    let idx = 1;
    arr.forEach((num) => {
      if (gaps.includes(idx)) {
        res += num;
        res += " ";
      } else {
        res += num;
      }
      idx++;
    });
    return res;
  }
  return undefined;
};

interface NumberFormatCustomProps {
  inputRef: (instance: typeof NumericFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  format: string;
  value: string;
}

export const NumberFormatCustom = React.forwardRef(
  (props: NumberFormatCustomProps, ref: React.Ref<unknown>) => {
    const { onChange, ...other } = props;
    const pursedValue = removePatternFormat(
      props.value,
      {
        from: { start: 0, end: 0 },
        to: { start: 0, end: props.value.length },
        lastValue: "",
      },
      props as any
    );
    return (
      <PatternFormat
        {...other}
        value={pursedValue}
        getInputRef={ref}
        onValueChange={(value: any) => {
          onChange({
            target: {
              name: props.name,
              value: value.formattedValue,
            },
          });
        }}
        valueIsNumericString={true}
        displayType={"input"}
      />
    );
  }
);
