import { useEffect } from "react";
import { clearBrandEntity } from "@/redux/reducers/brandEntity";
import { useSiteBrandingType } from "@/components/SiteProvider";
import { useAppSelector, useAppDispatch } from "@/redux/hooks";
import { StoreBrandingType, SiteBrandingType } from "@/types/misc";
import { useParams } from "react-router-dom";

const useSiteBrandingTypeForEntityManagement = () => {
  const domainStoreBrandingType = useAppSelector(
    ({ appConfig }) => appConfig?.domainConfig.storeBrandingType
  );
  const siteBrandingType = useSiteBrandingType();
  const params = useParams();

  if (domainStoreBrandingType !== StoreBrandingType.UniversalStore) {
    return domainStoreBrandingType;
  }

  if (
    params.stacklineSku &&
    siteBrandingType === SiteBrandingType.UniversalStore
  ) {
    // If a stacklineSku parameter exists, treat the page as a brand store
    // since product-specific pages are always brand-associated
    return SiteBrandingType.BrandStore;
  }
  return siteBrandingType;
};

/**
 * A hook that manages the brand entity state based on the page context.
 *
 * This hook automatically clears the brand entity from the state when both:
 * - The current page is a UniversalStore page
 * - A brand entity is currently defined in the state
 *
 * This prevents brand entity data from persisting in contexts where it shouldn't be used.
 *
 * @returns void
 */
const useSiteEntityManager = () => {
  const siteBrandingType = useSiteBrandingTypeForEntityManagement();
  const isBrandEntityDefined = useAppSelector((state) =>
    Boolean(state.brandEntity?.entity)
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      siteBrandingType === SiteBrandingType.UniversalStore &&
      isBrandEntityDefined
    ) {
      dispatch(clearBrandEntity());
    }
  }, [isBrandEntityDefined, siteBrandingType, dispatch]);
};

export default useSiteEntityManager;
