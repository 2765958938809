import { useContext } from "react";
import { UserLoginContext } from "@/components/pages/Auth/UserLoginProvider";

export const useSignedIn = () => {
  const { snapshot } = useContext(UserLoginContext);

  if (!snapshot.context.user) {
    throw new Error("user not found in UserLoginMachine context");
  }

  return snapshot.context.user;
};
