import { useLocation, useNavigationType } from "react-router-dom";
import { useEffect, useRef } from "react";
import { userLoginMachine } from "./UserLoginMachines";
import { ActorRefFrom } from "xstate";

export const useSyncBrowserNavigationWithUserLoginState = (
  send: ActorRefFrom<typeof userLoginMachine>["send"]
) => {
  const location = useLocation();
  const navigationType = useNavigationType();
  const prevPath = useRef({
    pathname: location.pathname,
    search: location.search,
  });

  useEffect(() => {
    if (prevPath.current.pathname === location.pathname) {
      // do not send any more updates if the pathname has not changed
      return;
    }
    switch (navigationType) {
      case "POP": {
        send({
          type: "BROWSER_NAVIGATION",
          pathname: location.pathname,
          // we create a new object to disconnect the reference and accurately capture the previous path at the time of the event
          prevPath: { ...prevPath.current },
        });
        break;
      }
      default: {
        send({
          type: "SYNC_STATE_WITH_ROUTE",
          pathname: location.pathname,
          // we create a new object to disconnect the reference and accurately capture the previous path at the time of the event
          prevPath: { ...prevPath.current },
        });
      }
    }
    prevPath.current.pathname = location.pathname;
    prevPath.current.search = location.search;
  }, [navigationType, location.pathname, location.search, send]);
};
