import { Auth, Hub, Logger } from "aws-amplify";
import {
  BrandclubStoreConfigUtils,
  tryGetVisitorId,
} from "@brandclub/common-ui";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { IAppAuthConfig } from "../redux/types";
import { AuthHelper } from "../components/pages/Auth/AuthHelper";

interface CognitoUserAttributes {
  sub: string;
  birthdate?: string;
  email_verified?: boolean;
  gender?: string;
  name?: string;
  phone_number_verified?: boolean;
  phone_number?: string;
  given_name?: string;
  family_name?: string;
  email: string;
  picture?: string;
}

export interface CognitoUserInfo {
  id: string;
  username: string;
  attributes: CognitoUserAttributes;
}
Logger.LOG_LEVEL = "NONE";

const sharedUserInfoCookieName =
  BrandclubStoreConfigUtils.getAppStage() === "prod"
    ? "bcUserInfo"
    : `bcUserInfo-${BrandclubStoreConfigUtils.getAppStage()}`;

const sharedAuthCookieName =
  BrandclubStoreConfigUtils.getAppStage() === "prod"
    ? "bcSessionToken"
    : `bcSessionToken-${BrandclubStoreConfigUtils.getAppStage()}`;
const sharedVisitorIdCookieName =
  BrandclubStoreConfigUtils.getAppStage() === "prod"
    ? "bcVisitorId"
    : `bcVisitorId-${BrandclubStoreConfigUtils.getAppStage()}`;

const getAWSCognitoConfig = (authConfig: IAppAuthConfig) => {
  return {
    region: authConfig.region,
    userPoolId: authConfig.userPoolId,
    userPoolWebClientId: authConfig.userPoolClientId,
    oauth: {
      domain: authConfig.signInDomain,
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: `${origin}/signinredirect`,
      redirectSignOut: `${origin}/signoutredirect`,
      responseType: "code",
    },
    Auth: {
      userPoolId: authConfig.userPoolId,
      clientId: authConfig.userPoolClientId,
      identityPoolId: authConfig.identityPoolId,
      userPoolWebClientId: authConfig.userPoolClientId,
      ClientId: authConfig.userPoolClientId,
      region: authConfig.region,
    },
  };
};

type AwsCognitoConfig = ReturnType<typeof getAWSCognitoConfig>;

const initializeAmplify = (config: AwsCognitoConfig) => {
  AuthHelper.configure(config);
};

export const initializeAuth = async (authConfig: IAppAuthConfig) => {
  try {
    const cognitoConfig = getAWSCognitoConfig(authConfig);
    const IdentityPoolId = cognitoConfig?.Auth?.identityPoolId;
    if (IdentityPoolId) {
      const cognitoIdentity = new CognitoIdentityClient({
        credentials: fromCognitoIdentityPool({
          identityPoolId: IdentityPoolId,
          clientConfig: { region: authConfig.region },
        }),
      });
      initializeAmplify(cognitoConfig);
      return cognitoIdentity.config.credentials;
    }
    initializeAmplify(cognitoConfig);
  } catch (err) {
    console.error(err);
  }
};

const getCognitoJWT = async () => {
  const session = await Auth.currentSession();
  const accessToken = session.getAccessToken();
  const jwt = accessToken.getJwtToken();
  return jwt;
};

type GetUserSignedInStateResponse =
  | {
      signedIn: true;
      userInfo: CognitoUserInfo;
    }
  | {
      signedIn: false;
      userInfo: null;
    };

export const getUserSignedInState =
  async (): Promise<GetUserSignedInStateResponse> => {
    try {
      const session = await Auth.currentSession();
      if (!session || !session.isValid()) {
        return { signedIn: false, userInfo: null };
      }
      const userInfo: CognitoUserInfo | undefined =
        await Auth.currentUserInfo();
      if (!userInfo || !userInfo.username) {
        return { signedIn: false, userInfo: null };
      }
      return { signedIn: true, userInfo };
    } catch (e) {
      return { signedIn: false, userInfo: null };
    }
  };

export const signOut = async () => {
  try {
    localStorage.removeItem(sharedAuthCookieName);
    localStorage.removeItem(sharedUserInfoCookieName);
    await Auth.signOut();
  } catch (e) {
    console.error(e);
  }
};

export const getJwt = async () => {
  try {
    return await getCognitoJWT();
  } catch (e) {
    if (e !== "No current user") {
      console.error(e);
    }
    return "";
  }
};

export const getVisitorIdHeader = async () => {
  const uniqueVisitorId = await tryGetVisitorId();
  const uniqueVisitorIdFromCookie = getVisitorIdCookie();
  const uniqueVisitorIdToUse =
    uniqueVisitorIdFromCookie && uniqueVisitorIdFromCookie !== ""
      ? uniqueVisitorIdFromCookie
      : uniqueVisitorId;
  const headers: any = {};
  if (uniqueVisitorIdToUse) {
    headers["x-bc-visitor-id"] = uniqueVisitorIdToUse;
  }
  return headers;
};

export const getAuthorizationHeader = async () => {
  const uniqueVisitorId = await tryGetVisitorId();
  const uniqueVisitorIdFromCookie = getVisitorIdCookie();
  const uniqueVisitorIdToUse =
    uniqueVisitorIdFromCookie && uniqueVisitorIdFromCookie !== ""
      ? uniqueVisitorIdFromCookie
      : uniqueVisitorId;
  const jwt = await getJwt();
  const headers: any = {};
  if (jwt) {
    headers["Authorization"] = `Bearer ${jwt}`;
  }
  if (uniqueVisitorIdToUse) {
    headers["x-bc-visitor-id"] = uniqueVisitorIdToUse;
  }
  return headers;
};

export const setOtpAuth = async (jwt: string, userInfo: any) => {
  userInfo = {
    ...userInfo,
    ...(userInfo.cognitoUserAttributes || {}),
    username: userInfo.username || userInfo.userId,
  };
  localStorage.setItem(sharedAuthCookieName, jwt);
  localStorage.setItem(sharedUserInfoCookieName, JSON.stringify(userInfo));
  Hub.dispatch("auth", {
    event: "signIn_Complete",
    data: { username: userInfo.username },
  });
};

export const setSharedAuthCookie = async () => {
  let jwt = await getJwt();
  // get top level domain
  const topLevelDomain = window.location.hostname
    .split(".")
    .slice(-2)
    .join(".");
  if (jwt && jwt !== "") {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 10);
    const expires = date.toUTCString();
    document.cookie = `${sharedAuthCookieName}=${jwt}; path=/; domain=.${topLevelDomain}; expires=${expires}; SameSite=None; Secure`;
  }
};

export const clearSharedAuthCookie = () => {
  // get top level domain
  const topLevelDomain = window.location.hostname
    .split(".")
    .slice(-2)
    .join(".");
  document.cookie = `${sharedAuthCookieName}=; path=/; domain=.${topLevelDomain}; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=None; Secure`;
};

export const getSharedAuthCookie = () => {
  const name = `${sharedAuthCookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const getVisitorIdCookie = () => {
  const name = `${sharedVisitorIdCookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
