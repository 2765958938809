import React from "react";
import { StoreNavAction } from "../StoreComponents/StoreSinglePageOutlet";
import { AppRoutesWithChildrenProps } from "./routeBuilder";
import SignIn from "../pages/Auth/SignIn";
import SignInVerify from "../pages/Auth/SignInVerify";
const OnBoardingConnect = React.lazy(() => import("../pages/Auth/Connect"));
const OnBoardingConnectRetailer = React.lazy(
  () => import("../pages/Auth/OnBoardingConnectRetailer")
);
const OnBoardingJoinClub = React.lazy(
  () => import("../pages/Auth/OnBoardingJoinClub")
);
const ConnectRetailerInProgress = React.lazy(
  () => import("../pages/Auth/Connect/ConnectRetailerInProgress")
);
const ConnectRetailerOTP = React.lazy(
  () => import("../pages/Auth/Connect/ConnectRetailerOTP")
);
const OnBoardingSuccess = React.lazy(
  () => import("../pages/Auth/OnBoardingSuccess")
);
const SetupUserProfile = React.lazy(
  () => import("../pages/Auth/SetupUserProfile")
);
const FindAccount = React.lazy(
  () => import("../pages/Auth/updatePhone/FindAccount")
);
const VerifyEmail = React.lazy(
  () => import("../pages/Auth/updatePhone/VerifyEmail")
);

export const authRoutes: AppRoutesWithChildrenProps[] = [
  {
    path: "signin",
    element: <SignIn />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.ToShopDomain,
      },
    },
    pageType: "Auth",
    pageId: "SignIn",
  },
  {
    path: "signinverify",
    element: <SignInVerify />,
    handle: {
      pageNavConfig: {
        back: StoreNavAction.Back,
        close: StoreNavAction.ToShopDomain,
      },
    },
    pageType: "Auth",
    pageId: "SignInVerify",
  },
  {
    path: "findaccount",
    element: <FindAccount />,
    handle: {
      pageNavConfig: {
        back: StoreNavAction.Back,
        close: StoreNavAction.ToShopDomain,
      },
    },
    pageType: "Auth",
    pageId: "FindAccount",
  },
  {
    path: "verifyemail",
    element: <VerifyEmail />,
    handle: {
      pageNavConfig: {
        back: StoreNavAction.Back,
        close: StoreNavAction.ToShopDomain,
      },
    },
    pageType: "Auth",
    pageId: "VerifyEmail",
  },
  {
    path: "updatephonenumber",
    element: <SignIn isUpdatePhoneFlow />,
    handle: {
      pageNavConfig: {
        back: StoreNavAction.Back,
        close: StoreNavAction.ToShopDomain,
      },
    },
    pageType: "Auth",
    pageId: "UpdatePhoneNumber",
  },
  {
    path: "setupuserprofile",
    element: <SetupUserProfile />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.SignOut,
      },
    },
    pageType: "Auth",
    pageId: "SetupUserProfile",
  },
  {
    path: "joinbrandclubs",
    element: <OnBoardingJoinClub />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.SignOut,
      },
    },
    pageType: "OnBoarding",
    pageId: "JoinBrandclubs",
  },
  {
    path: "connectretailers",
    element: <OnBoardingConnectRetailer />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.SignOut,
      },
    },
    pageType: "OnBoarding",
    pageId: "ConnectRetailers",
  },
  {
    path: "authenticatewithretailer",
    element: <OnBoardingConnect />,
    pageType: "OnBoarding",
    pageId: "AuthenticateWithRetailer",
  },
  {
    path: "connectretailerinprogress",
    element: <ConnectRetailerInProgress />,
    pageType: "ConnectRetailer",
    pageId: "ConnectRetailerInProgress",
  },
  {
    path: "connectretailerotp",
    element: <ConnectRetailerOTP />,
    pageType: "ConnectRetailer",
    pageId: "ConnectRetailerOTP",
  },
  {
    path: "success",
    element: <OnBoardingSuccess />,
    handle: {
      pageNavConfig: {
        close: StoreNavAction.ToShopDomain,
      },
    },
    pageType: "OnBoarding",
    pageId: "OnBoardingSuccess",
  },
];
