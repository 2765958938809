import {
  AnalyticsTrackingEvent,
  FilterParam,
  getBrandLogo,
} from "@brandclub/common-ui";
import { BrandclubPageType, Page, PageStatus } from "@brandclub/types";
import { useMediaQuery } from "@mui/material";
import { default as React, useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import ReduxStore, { BrandAppThemeConfig } from "../../redux/types";
import { SiteBrandingType } from "../../types/misc";
import { useSiteContext } from "../SiteProvider";
import { getActiveCarts } from "@/redux/reducers/checkout/thunk";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import { TABLET_SCREEN_SIZE, SingleNavType } from "./constants";
import { useStateWithLocationReset } from "../../utils/hooks/useStateWithLocationReset";
import { useAppDispatch } from "../../redux/hooks";
import { escapeUriPath } from "../../utils/StringUtils";
import { useTrackActions } from "../../utils/hooks/useTracking";
import { getCartCounts } from "@/redux/selectors";
import { useSignedIn } from "@/utils/hooks/useSignedIn";

const showHideNav = (nav: Page) => {
  const hasChildPages = nav.childPages?.some(
    (childPage) => childPage.status === PageStatus.Visible
  );
  if (["Collection", "Editorial"].includes(nav.pageType)) {
    if (nav.status === PageStatus.Visible) {
      return true;
    }
    return false;
  }
  if (nav.route.startsWith("/subcategories")) {
    // after grouped categories nav updates subcategories should always be hidden
    // sites created prior to this update have the default value of showInNavigation set to true
    return false;
  }
  if (nav.pageType === BrandclubPageType.CustomCategoryGroup) {
    return nav.status === PageStatus.Visible && hasChildPages;
  }
  return nav.showInNavigation && nav.status === PageStatus.Visible;
};

export const buildRouteLink = (
  widgets: any[] | undefined,
  routePrefix: string,
  route: string,
  persistentQueryParams: string | undefined
) => {
  let routeForLink = widgets?.length ? `${routePrefix}${route}` : "";
  if (persistentQueryParams && routeForLink !== "") {
    if (routeForLink.indexOf("?") > 0) {
      routeForLink = `${routeForLink}&${persistentQueryParams || ""}`;
    } else {
      routeForLink = `${routeForLink}?${persistentQueryParams || ""}`;
    }
  }
  return routeForLink;
};

export const buildMenuNav = ({
  mainEntity,
  brandEntity,
  pages,
  siteBrandingType,
  menuLevel,
  routePrefix,
  persistentQueryParams,
  signedIn,
}: {
  mainEntity: ReduxStore["mainEntity"];
  brandEntity: ReduxStore["brandEntity"];
  pages: Page[];
  siteBrandingType: SiteBrandingType;
  menuLevel: number;
  routePrefix: string;
  persistentQueryParams?: string;
  signedIn: boolean;
}): { desktopNav: SingleNavType[]; mobileNav: SingleNavType[] } => {
  if (
    siteBrandingType === SiteBrandingType.BrandStore ||
    siteBrandingType === SiteBrandingType.CustomStore
  ) {
    // first level menu requires special building
    if (menuLevel === 0) {
      const categoriesPage = pages?.find(
        (x) =>
          showHideNav(x) &&
          (x.displayName === "Categories" || x.displayName === "Shop")
      );
      const collectionsPage = pages?.find(
        (x) => showHideNav(x) && x.displayName === "Collections"
      );
      const editorialsPage = pages?.find(
        (x) => showHideNav(x) && x.displayName === "Editorials"
      );
      const brandShopSubNav: SingleNavType[] = categoriesPage?.childPages
        ? buildMenuNav({
            mainEntity,
            brandEntity,
            pages: categoriesPage?.childPages,
            siteBrandingType,
            menuLevel: menuLevel + 1,
            routePrefix:
              siteBrandingType === SiteBrandingType.CustomStore
                ? `/${escapeUriPath(brandEntity?.entity?.name)}/b/${
                    brandEntity?.entity?.brandId
                  }`
                : routePrefix,
            persistentQueryParams,
            signedIn: signedIn,
          }).desktopNav
        : [];

      [collectionsPage, editorialsPage].forEach((brandExploreChildPage) => {
        if (brandExploreChildPage?.childPages) {
          const threeChildPages = brandExploreChildPage.childPages.slice(0, 3);
          if (threeChildPages?.length > 0) {
            brandShopSubNav.push({
              name: brandExploreChildPage.displayName,
              displayName: brandExploreChildPage.displayName,
              route: buildRouteLink(
                brandExploreChildPage.widgets,
                routePrefix,
                brandExploreChildPage.route,
                persistentQueryParams
              ),
              previewImageUrl: brandExploreChildPage.previewImageUrl,
              metaDescription: brandExploreChildPage.metaDescription,
              subNav: editorialsPage?.childPages
                ? buildMenuNav({
                    mainEntity,
                    brandEntity,
                    pages: threeChildPages,
                    siteBrandingType,
                    menuLevel: menuLevel + 1,
                    routePrefix,
                    persistentQueryParams,
                    signedIn: signedIn,
                  }).desktopNav
                : undefined,
            });
          }
        }
      });

      const brandDashboardNavItems: SingleNavType[] = [
        {
          name: "BrandDashboardMenu",
          displayName: "Dashboard",
          route: buildRouteLink(
            [{}],
            routePrefix,
            "/branddashboard/me",
            persistentQueryParams
          ),
        },
        {
          name: "Rewards",
          displayName: "Rewards",
          subNav: [
            {
              name: "BrandBalance",
              displayName: "Balance",
              route: buildRouteLink(
                [{}],
                routePrefix,
                "/branddashboard/rewards/balance",
                persistentQueryParams
              ),
            },
            {
              name: "BrandActivity",
              displayName: "Activity",
              route: buildRouteLink(
                [{}],
                routePrefix,
                "/branddashboard/rewards/activity",
                persistentQueryParams
              ),
            },
            {
              name: "BrandExpiring",
              displayName: "Expiring",
              route: buildRouteLink(
                [{}],
                routePrefix,
                "/branddashboard/rewards/expiring",
                persistentQueryParams
              ),
            },
          ],
        },
        {
          name: "BrandPurchases",
          route: buildRouteLink(
            [{}],
            routePrefix,
            "/branddashboard/purchase",
            persistentQueryParams
          ),
          displayName: "Purchases",
        },
      ];

      const commonNav: SingleNavType[] = [
        {
          name: "BrandShop",
          displayName: "Shop",
          subNav: brandShopSubNav,
        },
      ];
      const storeNav = commonNav.concat({
        name: "BrandReward",
        displayName: "Rewards",
        route: buildRouteLink(
          [{}],
          routePrefix,
          "/dashboard/rewards/balance",
          persistentQueryParams
        ),
      });
      const storeNavMobile =
        siteBrandingType === SiteBrandingType.CustomStore
          ? []
          : commonNav.concat([
              {
                name: "BrandReward",
                displayName: "Rewards",
                route: buildRouteLink(
                  [{}],
                  routePrefix,
                  "/branddashboard/rewards/balance",
                  persistentQueryParams
                ),
              },
              {
                name: "BrandDashboard",
                displayName: "Dashboard",
                subNav: brandDashboardNavItems,
              },
              {
                name: "BrandOffers",
                displayName: "Offers",
                route: buildRouteLink(
                  [{}],
                  routePrefix,
                  "/offers",
                  persistentQueryParams
                ),
              },
            ]);

      return { desktopNav: storeNav, mobileNav: storeNavMobile };
    }

    const storeNav = pages
      ?.filter((x) => showHideNav(x))
      ?.map(
        ({
          childPages,
          widgets,
          route,
          displayName,
          previewImageUrl,
          metaDescription,
        }) => {
          const subNav = childPages
            ? buildMenuNav({
                mainEntity,
                brandEntity,
                pages: childPages,
                siteBrandingType,
                menuLevel: menuLevel + 1,
                routePrefix,
                persistentQueryParams,
                signedIn: signedIn,
              }).desktopNav
            : undefined;
          return {
            name: displayName,
            displayName,
            route: buildRouteLink(
              widgets,
              routePrefix,
              route,
              persistentQueryParams
            ),
            previewImageUrl,
            metaDescription,
            subNav,
          };
        }
      );
    return { desktopNav: storeNav, mobileNav: storeNav };
  } else {
    // Build Brandclub universal store navigation

    return { desktopNav: [], mobileNav: [] };
  }
};

const getBrandHeaderLogo = (
  siteBrandingType: SiteBrandingType,
  brandId: string | number,
  themeConfig?: BrandAppThemeConfig
): string => {
  const brandLogo = getBrandLogo(brandId);
  switch (siteBrandingType) {
    case SiteBrandingType.UniversalStore:
    case SiteBrandingType.BrandStore:
      return "https://media.brandclub.com/brandclub/image_asset/brandclub_logo_no_star.svg";
    case SiteBrandingType.CustomStore:
      return themeConfig?.appHeaderLogo ?? brandLogo;
    default:
      return brandLogo;
  }
};

export const AppMainNavigation = ({ pageContext, children }: any) => {
  const { siteBrandingType, site, persistentQueryParams, routePrefix } =
    useSiteContext();
  const { signedIn } = useSignedIn();
  const [filterDialogOpen, setFilterDialogOpen] =
    useStateWithLocationReset(false);
  const [filterCount, setFilterCount] = useState(0);
  const [searchOpen, setSearchOpen] = useStateWithLocationReset(false);
  const [searchInput, setSearchInput] = useState("");
  const mainEntity = useAppSelector(({ mainEntity }) => mainEntity);
  const brandEntity = useAppSelector(({ brandEntity }) => brandEntity);
  const domainConfig = useAppSelector(
    ({ appConfig }) => appConfig?.domainConfig
  );
  const themeConfig = useAppSelector(
    (state) => state.appConfig?.brandAppThemeConfig
  );
  const [trackAction] = useTrackActions();
  const brandHeaderLogo = getBrandHeaderLogo(
    siteBrandingType,
    brandEntity?.id || domainConfig?.brandId,
    themeConfig
  );
  const tabletView = useMediaQuery(`(max-width:${TABLET_SCREEN_SIZE}px)`);
  const cartCount = useAppSelector(getCartCounts);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getActiveCarts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const searchTerm = searchParams.get(FilterParam.TitleInclude);
  useEffect(() => {
    setSearchInput(searchTerm || "");
  }, [searchTerm]);

  const handleSearchOpen = (_event: React.MouseEvent<HTMLDivElement>) => {
    setSearchOpen(true);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
  };

  const onSubmitSearch = useCallback(
    (newKeyword?: string) => {
      handleSearchClose();
      let refinedInput = searchInput.trim();
      if (newKeyword) {
        refinedInput = newKeyword.trim();
      }
      if (!refinedInput) {
        return;
      }
      refinedInput = encodeURIComponent(refinedInput);
      let searchRoute = `/search?${FilterParam.TitleInclude}=${refinedInput}`;
      if (pageContext?.subCategoryId) {
        searchRoute += `&subCategoryId[]=${pageContext.subCategoryId}`;
      }
      if (pageContext?.categoryId) {
        searchRoute += `&categoryId[]=${pageContext.categoryId}`;
      }
      if (persistentQueryParams) {
        searchRoute += `&${persistentQueryParams}`;
      }
      trackAction(AnalyticsTrackingEvent.SELECTED_SEARCH_TERM_FROM_DROPDOWN, {
        searchRoute,
        searchTerm: refinedInput,
      });
      navigate(`${searchRoute}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchInput, navigate, handleSearchClose]
  );

  const navMenu = buildMenuNav({
    mainEntity,
    brandEntity,
    pages: site?.pages || [],
    siteBrandingType,
    menuLevel: 0,
    routePrefix,
    persistentQueryParams,
    signedIn,
  });

  const { desktopNav, mobileNav } = navMenu;
  return (
    <>
      {tabletView ? (
        <MobileNav
          cartCount={cartCount}
          handleSearchClose={handleSearchClose}
          handleSearchOpen={handleSearchOpen}
          searchOpen={searchOpen}
          navMenu={mobileNav}
          siteBrandingType={siteBrandingType}
          persistentQueryParams={persistentQueryParams}
          pageContext={pageContext}
          filterDialogOpen={filterDialogOpen}
          setFilterDialogOpen={setFilterDialogOpen}
          filterCount={filterCount}
          setFilterCount={setFilterCount}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          onSubmitSearch={onSubmitSearch}
          brandHeaderLogo={brandHeaderLogo}
        />
      ) : (
        <DesktopNav
          cartCount={cartCount}
          backdropOpen={searchOpen}
          handleSearchClose={handleSearchClose}
          handleSearchOpen={handleSearchOpen}
          searchOpen={searchOpen}
          navMenu={desktopNav}
          siteBrandingType={siteBrandingType}
          routePrefix={routePrefix}
          persistentQueryParams={persistentQueryParams}
          pageContext={pageContext}
          filterDialogOpen={filterDialogOpen}
          setFilterDialogOpen={setFilterDialogOpen}
          filterCount={filterCount}
          setFilterCount={setFilterCount}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          onSubmitSearch={onSubmitSearch}
          site={site}
          brandHeaderLogo={brandHeaderLogo}
        />
      )}
      {children}
    </>
  );
};
