import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAppConfigFromServer } from "../../../api";
import { BrandAppConfig } from "../../types";
import { getBrandAppConfig } from "../../../api";

export const getAppConfig = createAsyncThunk(
  "/appConfig/getAppConfig",
  async () => {
    return getAppConfigFromServer();
  }
);

interface GetBrandAppConfigsRequest {
  appId: string | undefined;
}

export type GetBrandAppConfigsResponse = BrandAppConfig | { error: string };
export const getBrandAppConfigs = createAsyncThunk<
  GetBrandAppConfigsResponse,
  GetBrandAppConfigsRequest
>("/appConfig/getBrandAppConfig", async (req) => {
  if (!req.appId) {
    return { error: "No appId provided" };
  }
  try {
    const response = await getBrandAppConfig({ appId: req.appId });
    return response;
  } catch (e: any) {
    return { error: e.message || JSON.stringify(e) };
  }
});
