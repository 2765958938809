import { CloseButton, hexadecimal } from "@brandclub/common-ui";
import { Dialog, styled } from "@mui/material";
import { create } from "zustand";
import ConnectRetailer from "../../pages/AppPages/Sync/Connect";
import { MOBILE_SCREEN_SIZE } from "../../AppNavigation/constants";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

interface BearState {
  retailerId: number | undefined;
  open: boolean;
  close: () => void;
}

const useConfirmDialogStore = create<BearState>((set) => ({
  retailerId: undefined,
  open: false,
  close: () => set({ open: false }),
}));

const ConnectContainer = styled("div")({
  maxWidth: "580px",
  width: "100%",
  marginTop: "33px",
  ".close": {
    cursor: "pointer",
    position: "absolute",
    top: 20,
    right: 20,
    width: 20,
    height: 20,
    border: "none",
    backgroundColor: "transparent",
    outline: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ".close_img": {
      width: 14,
      height: 14,
    },
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      top: 10,
      right: 10,
      width: 20,
      height: 20,
    },
  },
});

const ConnectRetailerDialog = () => {
  const { open, close, retailerId } = useConfirmDialogStore();
  const location = useLocation();
  useEffect(() => {
    close();
    // close the dialog whenever the location changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        fullScreen={false}
        sx={{
          zIndex: 1400,
        }}
        PaperProps={{
          sx: { zIndex: 1502, width: "100%", maxWidth: "580px" },
        }}
        slotProps={{
          backdrop: {
            sx: (theme) => ({
              backgroundColor: hexadecimal(theme.palette.primary.main)(21),
            }),
          },
        }}
      >
        <ConnectContainer>
          <div className="close">
            <CloseButton iconSize={12.5} onClick={close} />
          </div>
          <ConnectRetailer
            retailerId={retailerId}
            onConnectCallback={async () => {
              close();
            }}
          />
        </ConnectContainer>
      </Dialog>
    </>
  );
};

ConnectRetailerDialog.show = (retailerId: number) => {
  useConfirmDialogStore.setState({
    retailerId,
    open: true,
  });
};

export default ConnectRetailerDialog;
