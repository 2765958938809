import { styled } from "@mui/material";
import { MOBILE_SCREEN_SIZE } from "../../../../AppNavigation/constants";

export const CashoutContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 30,
  ".balance-section": {
    border: `1px solid #dcdddc`,
    borderRadius: 10,
    padding: "10px 30px",
  },
  ".row": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 50,
    ".label": {
      fontSize: 16,
      fontWeight: 500,
      color: theme.palette.primary.main,
      display: "flex",

      alignItems: "center",
    },
    ".value": {
      fontSize: 16,
      fontWeight: 600,
      color: theme.palette.primary.main,
      display: "flex",
      gap: 16,
      alignItems: "center",
    },
  },

  ".row + .row": {
    borderTop: `1px solid #dcdddc`,
  },

  ".get_app .store-message-card__cta": {
    paddingLeft: 100,
    paddingRight: 100,
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      paddingLeft: 75,
      paddingRight: 75,
    },
  },
}));
