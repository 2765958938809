import { gql } from "@apollo/client";

const AMOUNT_COUNT_FRAGMENT = /* GraphQL */ `
  {
    amount
    count
    maxAmount
    maxAmountType
  }
`;
export const getBrandClubById = gql`
  query getBrandClubById(
    $brandclubId: String
    $brandId: Float
    $campaignId: String
  ) {
    GetBrandClub(
      brandclubId: $brandclubId
      brandId: $brandId
      campaignId: $campaignId
    ) {
      imageUrl
      brandclubId
      brandIds
      brandId
      rewardAmount
      campaignRewards
      isMember
      lifetimePayout
      name
      availableSurveyRewardAmount
      availableContentRewardAmount
      availableCampaignRewardsSummary {
        content ${AMOUNT_COUNT_FRAGMENT}
        survey ${AMOUNT_COUNT_FRAGMENT}
        purchase ${AMOUNT_COUNT_FRAGMENT}
        affiliate ${AMOUNT_COUNT_FRAGMENT}
        dailyDeal ${AMOUNT_COUNT_FRAGMENT}
      }
      nextUnlockedRewardAmount
    }
  }
`;

export const searchBrandClubsForUserV2 = gql`
  query searchBrandClubsForUserV2(
    $titleInclude: [String!]
    $titleExclude: [String!]
    $brandIds: [Float!]
    $page: PageFilter!
    $clubsWithRewardOnly: Boolean
    $searchIntent: String
  ) {
    SearchBrandClubsForUserV2(
      titleInclude: $titleInclude
      titleExclude: $titleExclude
      brandIds: $brandIds
      page: $page
      clubsWithRewardOnly: $clubsWithRewardOnly
      searchIntent: $searchIntent
    ) {
      items {
        hasRewards
        totalRewardAmount
        campaignRewards
        brandclubId
        imageUrl
        name
        isMember
        brandId
        expiringRewardAmount
      }
      hasMore
      nextPage
    }
  }
`;

export const getUnauthedSearchBrandClubs = gql`
  query getUnauthedSearchBrandClubs(
    $titleInclude: [String!]
    $titleExclude: [String!]
    $brandIds: [Float!]
    $page: PageFilter
    $clubsWithRewardOnly: Boolean
    $searchIntent: String
  ) {
    UnauthedSearchBrandClubs(
      titleInclude: $titleInclude
      titleExclude: $titleExclude
      brandIds: $brandIds
      page: $page
      clubsWithRewardOnly: $clubsWithRewardOnly
      searchIntent: $searchIntent
    ) {
      items {
        hasRewards
        totalRewardAmount
        campaignRewards
        brandclubId
        imageUrl
        name
        isMember
        brandId
        expiringRewardAmount
      }
      hasMore
      nextPage
    }
  }
`;

export const getCustomerSpendByBrandGQL = gql`
  query getCustomerSpendByBrand(
    $retailPrices: [RangeFilter!]
    $orderDate: RangeFilter
    $brandIds: [Float!]
    $groupIds: [Float!]
    $categoryIds: [Float!]
    $subCategoryIds: [Float!]
    $retailerIds: [Float!]
    $page: PageFilter!
    $sort: [SortFilter!]
  ) {
    CustomerSpendByBrand(
      retailPrices: $retailPrices
      orderDate: $orderDate
      brandIds: $brandIds
      categoryIds: $categoryIds
      groupIds: $groupIds
      subCategoryIds: $subCategoryIds
      retailerIds: $retailerIds
      page: $page
      sort: $sort
    ) {
      brandId
      brandclubId
      brandName
      spend
    }
  }
`;
