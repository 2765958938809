import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "../../initialState";
import { getActiveCarts } from "./thunk";
import { cloneDeep } from "lodash";
import {
  ShoppingCartItem,
  UserRetailerShoppingCart,
} from "../../../components/Checkout/types";

type OptimisticCart = Partial<UserRetailerShoppingCart>;
interface UpdateCartParams {
  carts: OptimisticCart[];
  retailerId: number;
  totalItems: number;
  items: ShoppingCartItem[];
}

const retailerLimits: Record<string, number> = {
  maxQuantityPerProduct: 10,
  maxProductCount: 20,
};

const updateCartByRetailerId = ({
  carts,
  retailerId,
  totalItems,
  items,
}: UpdateCartParams) => {
  const cartsDeepCopy = cloneDeep(carts);
  const cart = cartsDeepCopy.find((cart) => cart.retailerId === retailerId);

  if (cart === undefined) {
    return carts.concat({ retailerId, items, totalItems });
  }

  return cartsDeepCopy.map((cart) => {
    if (cart.retailerId === retailerId) {
      const itemMap = new Map();
      cart.items?.forEach((item) => itemMap.set(item.retailerSku, item));
      items.forEach((item) => {
        const existingItem = itemMap.get(item.retailerSku.toString());
        if (existingItem) {
          existingItem.quantity = Math.max(
            0,
            existingItem.quantity + item.quantity
          );

          if (existingItem.quantity > retailerLimits.maxQuantityPerProduct) {
            throw new Error(
              `You can only have ${retailerLimits.maxQuantityPerProduct} of the same product in your cart`
            );
          }

          if (existingItem.quantity <= 0) {
            itemMap.delete(item.retailerSku.toString());
          }
        } else {
          itemMap.set(item.retailerSku, item);
        }
      });
      const newTotalItems = Math.max(0, (cart.totalItems || 0) + totalItems);

      if (newTotalItems > retailerLimits.maxProductCount) {
        throw new Error(
          `You can only have ${retailerLimits.maxProductCount} products in your cart`
        );
      }

      return {
        ...cart,
        items: Array.from(itemMap.values()),
        totalItems: newTotalItems,
      };
    }

    return cart;
  });
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState: initialState.checkout,
  reducers: {
    setCart(state, action) {
      return {
        ...state,
        cart: action.payload,
      };
    },
    setProducts(state, action) {
      return {
        ...state,
        products: { ...(state.products ?? {}), ...action.payload },
      };
    },
    setAllCarts(state, action) {
      return {
        ...state,
        allCarts: action.payload,
      };
    },
    setOrder(state, action) {
      return {
        ...state,
        order: action.payload,
      };
    },
    toggleCartDrawer(state) {
      return { ...state, open: !state.open };
    },
    closeCartDrawer(state) {
      return { ...state, open: false };
    },
    openCartDrawer(state) {
      return { ...state, open: true };
    },
    addOptimisticShoppingCartItem(state, action: OptimisticCartUpdateAction) {
      const carts = state.optimisticAllCarts ?? state.allCarts;
      try {
        const update = updateCartByRetailerId({
          carts: carts,
          retailerId: action.payload.retailerId,
          items: action.payload.items,
          totalItems: action.payload.totalItems,
        });

        return { ...state, optimisticAllCarts: update };
      } catch (error: unknown) {
        if (error instanceof Error) {
          return { ...state, errorState: error.message };
        }
        if (typeof error === "string") {
          return { ...state, errorState: error };
        }
        return { ...state, errorState: "An error occurred" };
      }
    },
    clearErrorState(state) {
      return { ...state, errorState: undefined };
    },
    removeOptimisticShoppingCartItem(
      state,
      action: OptimisticCartUpdateAction
    ) {
      const update = updateCartByRetailerId({
        carts: state.optimisticAllCarts ?? state.allCarts,
        retailerId: action.payload.retailerId,
        totalItems: action.payload.totalItems,
        items: action.payload.items.map((item) => ({
          ...item,
          quantity: item.quantity,
        })),
      });
      return { ...state, optimisticAllCarts: update };
    },
    clearOptimisticAllCarts(state) {
      return { ...state, optimisticAllCarts: undefined };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActiveCarts.pending, (state) => {
        state.loadingCarts = true;
      })
      .addCase(getActiveCarts.fulfilled, (state, action) => {
        state.loadingCarts = false;
        state.allCarts = action.payload.allCarts;
        state.products = action.payload.products;
      })
      .addDefaultCase((state) => state);
  },
});

export const {
  setAllCarts,
  setCart,
  setProducts,
  openCartDrawer,
  closeCartDrawer,
  toggleCartDrawer,
  setOrder,
  addOptimisticShoppingCartItem,
  removeOptimisticShoppingCartItem,
  clearOptimisticAllCarts,
  clearErrorState,
} = checkoutSlice.actions;

export type CheckoutState = ReturnType<typeof checkoutSlice.reducer>;
export type OptimisticCartUpdateAction = PayloadAction<{
  retailerId: number;
  items: ShoppingCartItem[];
  totalItems: number;
}>;
export type OptimisticCartItemQuantityUpdateAction = PayloadAction<{
  retailerId: number;
  retailerSku: string;
}>;

export default checkoutSlice.reducer;
