import { BrandAppConfig, WebAndBrandAppConfig } from "../../../redux/types";
import axios from "../../../utils/axios";
const getErrorMessage = (e: unknown): string => {
  return e instanceof Error ? e.message : String(e);
};

export const getBrandAppConfig = async (req: { appId: string }) => {
  const res = await axios.post<BrandAppConfig>(
    "/brandapp/getBrandAppConfig",
    req
  );
  return res?.data;
};

export interface ThemeResponse {
  primary: string;
  secondary: string;
  tertiary: string;
  componentStyleOverrides: {
    [key: string]: {
      primary: string;
      secondary: string;
      tertiary: string;
      enabled: boolean;
      capitalize: boolean;
      [key: string]: any;
    };
  };
  barFonts?: {
    fontFamily: string;
    baseFontSize: number;
    fontFamilyLink: string;
    fontWeight: number;
  };
  dtcFonts?: {
    fontFamily: string;
    baseFontSize: number;
    fontFamilyLink: string;
    fontWeight: number;
  };
  [key: string]: any;
}

export const getCustomTheme = async (): Promise<ThemeResponse | undefined> => {
  const appId = window.location.origin.split("https://")[1];
  const { data } = await axios.post("/brandapp/getDTCAppTheme", {
    appId: appId,
  });

  return new Promise<any>((resolve, reject) => {
    if (data) {
      resolve(data);
    } else {
      reject("No data found");
    }
  }).catch((e) => {
    console.error(`Error retrieving custom theme: ${getErrorMessage(e)}`);
    return undefined;
  });
};

export interface GetAppConfigFromServerResponse {
  appConfig: WebAndBrandAppConfig | undefined;
}
export const getAppConfigFromServer =
  async (): Promise<GetAppConfigFromServerResponse> => {
    const { data } = await axios.get(`/config/webApp/getAppConfig`, {});

    if (data) {
      return {
        appConfig: {
          authConfig: data.authConfig,
          domainConfig: data.domainConfig,
        },
      };
    }

    return {
      appConfig: undefined,
    };
  };
