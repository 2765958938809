import { useEffect } from "react";
import Loading from "../../Loading";
import { useAppSelector } from "../../../redux/hooks";
import { StoreBrandingType } from "../../../types/misc";
import { useNavigate } from "react-router-dom";

export default function SignOutRedirect() {
  const navigate = useNavigate();
  const customShopDomainName = useAppSelector(
    (state) => state.appConfig?.domainConfig.customShopDomainName
  );
  const storeBrandingType = useAppSelector(
    (state) => state.appConfig?.domainConfig.storeBrandingType
  );
  const linkTo =
    storeBrandingType === StoreBrandingType.CustomDTCStore &&
    customShopDomainName
      ? `https://${customShopDomainName}/`
      : `/`;

  useEffect(() => {
    if (linkTo) {
      if (linkTo.startsWith("http")) {
        window.location.replace(linkTo);
      } else {
        navigate(linkTo);
      }
    }
  }, [linkTo, navigate]);

  return <Loading fullscreen dot />;
}
