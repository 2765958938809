import React from "react";
import { AppRoutesWithChildrenProps } from "./routeBuilder";
const Sync = React.lazy(() => import("../pages/AppPages/Sync"));
const ConnectFullScreen = React.lazy(
  () => import("../pages/AppPages/Sync/Connect/ConnectFullScreen")
);
const SyncOutlet = React.lazy(
  () => import("../pages/AppPages/Sync/SyncOutlet")
);
const SyncSummary = React.lazy(
  () => import("../pages/AppPages/Sync/SyncSummary/SyncSummary")
);
const SyncSummaryOutlet = React.lazy(
  () => import("../pages/AppPages/Sync/SyncSummary/SyncSummaryOutlet")
);

const syncChildRoutes: AppRoutesWithChildrenProps[] = [
  {
    element: <SyncOutlet />,
    children: [{ path: "", index: true, element: <Sync /> }],
  },
  {
    element: <SyncSummaryOutlet />,
    children: [{ path: "summary/:syncBatchId", element: <SyncSummary /> }],
  },
];

export const syncRoutes: AppRoutesWithChildrenProps[] = [
  { path: "sync/", children: syncChildRoutes },
];

export const connectRoutes: AppRoutesWithChildrenProps[] = [
  { path: "connect/", element: <ConnectFullScreen /> },
];
