import { Suspense, useContext } from "react";
import { UserLoginContext } from "./pages/Auth/UserLoginProvider";

interface AppSuspenseProps {
  children: React.ReactNode;
  fallback: React.ReactNode;
}

/**
  * Suspense component that waits for the user's data to be initialized before rendering the children.
  * @param children - The children to render when the user's data is initialized.
  * @param fallback - The fallback to render while the user's data is being initialized.
  * @returns The Suspense component.
 */
export const AppSuspense: React.FC<AppSuspenseProps> = ({
  children,
  fallback,
}) => {
  const { snapshot } = useContext(UserLoginContext);

  return (
    <Suspense fallback={fallback}>
      {snapshot.value === "initializeUserData" ? fallback : children}
    </Suspense>
  );
};
