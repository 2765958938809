import GenericDialog from "../../../../StoreComponents/StoreDialogs/GenericDialog";

const handleBalanceDialogOpen = (title: string, text: string) => {
  GenericDialog.show({
    title,
    text,

    actions: [
      {
        text: "Close",
        onClick: () => {},
        variant: "solid",
      },
    ],
  });
};

export const handleTotalRewardsClick = () => {
  handleBalanceDialogOpen(
    "Total rewards",
    "The grand total of all rewards you have earned over the past 365 days."
  );
};

export const handleTotalPayoutClick = () => {
  handleBalanceDialogOpen(
    "Total payouts",
    "The amount of funds you have withdrawn using the cash out feature and transferred to your PayPal or Venmo account."
  );
};

export const handleTotalAdjustmentsClick = () => {
  handleBalanceDialogOpen(
    "Total adjustments",
    "The amount of rewards that were adjusted due to order status changes with your retailer."
  );
};
export const handleRewardBalanceClick = () => {
  handleBalanceDialogOpen(
    "Reward balance",
    "The remaining amount of rewards you have earned, but not yet withdrawn using the cash out feature."
  );
};
export const handlePendingRewardsClick = () => {
  handleBalanceDialogOpen(
    "Pending rewards",
    "The amount of rewards that are still pending since the order has not yet been delivered or confirmed with the retailer. Sync your account to update the status."
  );
};
export const handleLockedRewardsClick = () => {
  handleBalanceDialogOpen(
    "Locked rewards",
    "The amount of rewards not yet eligible to be withdrawn. Locked rewards need to be “unlocked” by making purchases and syncing your accounts."
  );
};
export const handleUnlockedRewardsClick = () => {
  handleBalanceDialogOpen(
    "Unlocked rewards",
    "The amount of rewards eligible to be withdrawn using the cash out feature. You may transfer these funds to your PayPal or Venmo."
  );
};
export const handleCancelledRewardsClick = () => {
  handleBalanceDialogOpen(
    "Cancelled rewards",
    "The amount of rewards that were removed due to order cancellation. Sync your account to get new rewards."
  );
};
export const handleCashOutEligibleClick = () => {
  handleBalanceDialogOpen(
    "Cash out eligible",
    "You are eligible to cash out a maximum of $50 daily."
  );
};