import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { Theme } from "@mui/material";
import { MOBILE_SCREEN_SIZE } from "@/components/AppNavigation/constants";
interface DotProps {
  active: boolean;
  theme?: Theme;
}

export const CarouselContainer = styled(Box)({
  position: "relative",
  width: "100%",
  maxWidth: "1200px",
  margin: "0 auto",
});

export const ScrollContainer = styled(Box)({
  overflow: "auto",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "&:hover": {
    cursor: "grab",
  },
  "&:active": {
    cursor: "grabbing",
  },
});

export const SlideContainer = styled(Box)({
  display: "flex",
  transition: "transform 0.3s ease-in-out",
});

export const NavigationButton = styled(IconButton)(({ theme, disabled }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(calc(-50% + 7px))",
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    transform: "translateY(calc(-50% + 5px))",
  },
  display: disabled ? "none" : "flex",
  backgroundColor: `${theme.palette.background.paper} !important`,
  boxShadow: theme.shadows[2],
  padding: 0,
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: `${theme.palette.grey[300]} !important`,
  },
  zIndex: 1,
}));

export const DotContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

export const Dot = styled("button")<DotProps>(({ theme, active }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  padding: 0,
  border: "none",
  backgroundColor: active
    ? `${theme.palette.primary.main} !important`
    : `${theme.palette.grey[300]} !important`,
  cursor: "pointer",
  transition: "background-color 0.3s ease",
}));

export const Slide = styled(Box)({
  flexShrink: 0,
  width: "100%",
});

export const ArrowButton = styled("div")({
  display: "flex",
  "&:before": {
    display: "none !important",
  },
  "&:after": {
    display: "none !important",
  },
  "&.slick-disabled": {
    display: "none",
  },
});

export const NextArrow = () => {
  return (
    <img
      className="perks_icon"
      src="https://media.brandclub.com/brandclub/image_asset/arrow_right_blue.svg"
      alt="arrow_l"
      style={{
        mixBlendMode: "multiply",
        width: "20px",
        height: "20px",
      }}
    />
  );
};

export const PrevArrow = () => {
  return (
    <img
      className="perks_icon"
      src="https://media.brandclub.com/brandclub/image_asset/arrow_left_blue.svg"
      alt="arrow_l"
      style={{
        mixBlendMode: "multiply",
        width: "20px",
        height: "20px",
      }}
    />
  );
};
