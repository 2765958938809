import { useCallback, useEffect } from "react";
import LandingPageWrapper from "../../../pages/AppPages/Pages/components/LandingPageWrapper";
import AllOutOfStockScreen, { useIsAllOutOfStock } from "./AllOutOfStockScreen";
import CheckoutConnect from "./CheckoutConnect";
import { CheckoutMainFlow } from "./CheckoutMainFlow";
import CheckoutMFA from "./CheckoutMFA";
import CheckoutMFAChoose from "./CheckoutMFAChoose";
import CheckoutSuccess from "./CheckoutSuccess";
import EnterCheckoutAnimation from "./EnterCheckoutAnimation";
import { useNavigate } from "react-router-dom";
import { closeCartDrawer } from "@/redux/reducers/checkout";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { CheckoutProvider, useCheckout } from "../../CheckoutMachine";
import { MfaValues } from "@/types/misc";
import CheckoutErrorDialog from "./CheckoutErrorDialog";
import { getAllCarts } from "@/redux/selectors";
import { useSignedIn } from "@/utils/hooks/useSignedIn";
import CheckoutTransition from "../CheckoutTransition";

const useEnterCheckoutFlow = () => {
  const { send: checkoutSend, snapshot } = useCheckout();
  const hasCartItems = useAppSelector((state) => getAllCarts(state).length > 0);
  const hasRetailers = useAppSelector((state) => state.retailers.length > 0);
  const { signedIn } = useSignedIn();
  const navigate = useNavigate();

  useEffect(() => {
    if (!signedIn) {
      // replace the current location with the signin page to avoid the user going back to this page using the browser back button
      navigate("/signin", { replace: true });
      return;
    }
    if (snapshot.context.globalCheckoutState === "IDLE") {
      if (!hasCartItems || !hasRetailers) {
        navigate("/mybag");
        return;
      }
      checkoutSend({ type: "INITIATE_CHECKOUT" });
    }
  }, [
    signedIn,
    checkoutSend,
    hasCartItems,
    hasRetailers,
    snapshot.context.globalCheckoutState,
    navigate,
  ]);

  const resetCheckout = useCallback(() => {
    checkoutSend({ type: "RESET_CHECKOUT" });
  }, [checkoutSend]);

  return resetCheckout;
};

const CheckoutFlow = () => {
  const resetCheckout = useEnterCheckoutFlow();
  const navigate = useNavigate();
  const { snapshot } = useCheckout();

  const { allOutOfStock, isSingleRetailerCheckout } = useIsAllOutOfStock();
  const showAllOutOfStockPage = allOutOfStock && isSingleRetailerCheckout;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(closeCartDrawer());
  }, [navigate, dispatch]);

  const status = snapshot?.context?.globalCheckoutState;
  const otpStatus = snapshot?.context?.otpStatus;
  const includeFooter = showAllOutOfStockPage || status === "CHECKOUT_SUCCESS";
  return (
    <LandingPageWrapper includeFooter={includeFooter}>
      <CheckoutTransition />
      <CheckoutErrorDialog handleReset={resetCheckout} />
      {showAllOutOfStockPage ? (
        <AllOutOfStockScreen />
      ) : otpStatus === MfaValues.MfaChoose ? (
        <CheckoutMFAChoose />
      ) : otpStatus === MfaValues.MfaEnter ? (
        <CheckoutMFA />
      ) : status === "LOGIN_SCREEN" ? (
        <CheckoutConnect />
      ) : status === "CHECKOUT_IN_PROGRESS" ? (
        <EnterCheckoutAnimation />
      ) : status === "CHECKOUT_SUMMARY" ||
        status === "CHECKOUT_USER_INPUT_UPDATE_IN_PROGRESS" ? (
        <CheckoutMainFlow />
      ) : status === "CHECKOUT_SUCCESS" ? (
        <CheckoutSuccess />
      ) : null}
    </LandingPageWrapper>
  );
};

const CheckoutFlowWithProvider = () => {
  return (
    <CheckoutProvider>
      <CheckoutFlow />
    </CheckoutProvider>
  );
};

export default CheckoutFlowWithProvider;
