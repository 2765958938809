import { RetailerCheckoutOrderStatus } from "@/api/rest/checkoutApi";
import {
  UserApprovalStatus,
  UserRetailerCheckoutSessionStatus,
} from "../types";

export const isAllOutOfStock = (order: RetailerCheckoutOrderStatus) => {
  const errorMessage = order.userRetailerCheckoutOrder?.errorMessage;
  const checkoutItems = order.userRetailerCheckoutOrder?.items;

  if (errorMessage === "No items in the cart") {
    return true;
  }
  if (checkoutItems?.length) {
    return checkoutItems.every(
      (item) =>
        item.status === "error" ||
        item.userApprovalStatus === UserApprovalStatus.DELETED
    );
  }

  return false;
};

export const isCheckoutInProgress = (
  status: UserRetailerCheckoutSessionStatus
) => {
  return [
    // add to cart session status
    UserRetailerCheckoutSessionStatus.ADD_TO_CART_IN_PROGRESS,
    UserRetailerCheckoutSessionStatus.ADD_TO_CART_SUCCESS,
    UserRetailerCheckoutSessionStatus.ADD_TO_CART_FAILED,
    // navigate to checkout session status
    UserRetailerCheckoutSessionStatus.NAVIGATE_TO_CHECKOUT_IN_PROGRESS,
    UserRetailerCheckoutSessionStatus.NAVIGATE_TO_CHECKOUT_SUCCESS,
    UserRetailerCheckoutSessionStatus.NAVIGATE_TO_CHECKOUT_FAILED,
    // login session status
    UserRetailerCheckoutSessionStatus.LOGIN_IN_PROGRESS,
    UserRetailerCheckoutSessionStatus.LOGIN_SUCCESS,
    UserRetailerCheckoutSessionStatus.LOGIN_FAILED,
    // validate checkout items session status
    UserRetailerCheckoutSessionStatus.VALIDATING_CHECKOUT_ITEMS_IN_PROGRESS,
    UserRetailerCheckoutSessionStatus.VALIDATING_CHECKOUT_ITEMS_SUCCESS,
    UserRetailerCheckoutSessionStatus.VALIDATING_CHECKOUT_ITEMS_FAILED,
  ].includes(status);
};
