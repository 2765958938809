import { useAppSelector } from "../../../redux/hooks";
import axios from "../../../utils/axios";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { BrandclubFooter } from "@brandclub/common-ui";
import { sumCampaignRewardCounts } from "../../../utils/misc";
import ConfigUtils from "../../../utils/ConfigUtils";
import reduxApolloClient from "../../../redux/reduxApolloClient";
import { getRewardAmountsByStatus } from "../../../api";
import AppLink from "../../AppNavigation/AppLink";
import { styled } from "@mui/material";
import TextTheApp from "@/components/pages/AppPages/GetApp/TextTheApp";
import { RewardSummaryForBrand, useFooterConfigs } from "./useFooterConfigs";

const StyledTextTheApp = styled(TextTheApp)(({ theme }) => ({
  maxWidth: 433,
  width: "100%",
  [`@media only screen and (max-width: 1280px)`]: {
    maxWidth: 369,
  },
  [theme.breakpoints.down("md")]: {
    width: 369, // we do this to allow the style in tablet view to be properly applied
    maxWidth: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    maxWidth: 369,
  },
  ".textme_header": {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  ".solid_textlink_to_button": {
    marginTop: 20.9,
    width: 285,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  ".textme_input": {
    display: "flex",
    alignItems: "center",
    border: `1.5px solid ${theme.palette.primary.main}`,
    borderRadius: 100,
    backgroundColor: theme.palette.common.white,
    padding: 9,
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
    fontSize: 18,
    "& input": {
      flex: 1,
      margin: "0 10px",
      fontWeight: 500,
      color: theme.palette.primary.main,
      border: 0,
      outline: "none",
      fontSize: 18,
      "&::placeholder": {
        color: "#a7afc4",
      },
    },
    "& .text_button": {
      fontWeight: 500,
      border: 0,
      fontSize: 20,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main),
      width: "100%",
      maxWidth: 153,
      borderRadius: 100,
      height: 52,
      padding: 0,
    },
    ".text_prefix": {
      marginLeft: 12, // marginLeft of 21px - 9px of padding,
    },
  },
}));

const StyledFooter = styled(BrandclubFooter)(() => ({
  "&&": { flex: "unset" },
}));

const LandingPageContentWithFooter = (props: PropsWithChildren) => {
  const socialLinks = useAppSelector(({ appConfig }) => appConfig?.socialLinks);
  const userProfile = useAppSelector(({ userProfile }) => userProfile);
  const [rewardSummaryForBrand, setRewardSummaryForBrand] =
    useState<RewardSummaryForBrand>({
      lifetimeEarnings: 0,
      lifetimePayouts: 0,
      lifetimeAdjustments: 0,
      lockedBalance: 0,
      pendingBalance: 0,
      unlockedBalance: 0,
      cancelledBalance: 0,
      hasLoaded: false,
    });
  const currentBalance = useAppSelector(
    ({ rewards }) => rewards?.rewards.currentBalance
  );
  const brandEntity = useAppSelector(({ brandEntity }) => brandEntity);

  const totalRewardCounts = useMemo(() => {
    return brandEntity?.brandclub
      ? sumCampaignRewardCounts(brandEntity.brandclub)
      : 0;
  }, [brandEntity?.brandclub]);

  useEffect(() => {
    (async () => {
      if (brandEntity?.brandclub && userProfile?.userId) {
        const variables = {
          brandIds: brandEntity?.brandclub?.brandIds,
          forceRefresh: true,
        };
        const { data } = await reduxApolloClient.query({
          query: getRewardAmountsByStatus,
          variables,
          context: {
            uri: `${ConfigUtils.getGraphQlUri()}?id=getRewardAmountsByStatus`,
          },
        });
        if (data && data.RewardSummary) {
          setRewardSummaryForBrand({ ...data.RewardSummary, hasLoaded: true });
        }
      }
    })();
  }, [brandEntity?.brandclub, userProfile]);

  const footerConfigs = useFooterConfigs({
    rewardSummaryForBrand,
    totalRewardCounts,
    currentBalance,
  });

  return (
    <>
      {props.children}
      <StyledFooter
        LinkComponent={AppLink}
        axios={axios}
        brandEntity={brandEntity as any}
        footerConfigs={footerConfigs}
        userProfile={userProfile}
        socialLinks={socialLinks}
        PhoneInputComponent={StyledTextTheApp}
      />
    </>
  );
};

export default LandingPageContentWithFooter;
