import { styled } from "@mui/material";
import { motion } from "framer-motion";
import {
  Link,
  Outlet,
  UIMatch,
  useLocation,
  useMatches,
  useNavigate,
} from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { escapeUriPath } from "../../utils/StringUtils";
import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../AppNavigation/constants";

import { BackButton, CloseButton, getBrandLogo } from "@brandclub/common-ui";
import { createContext, RefObject, useContext, useEffect, useRef } from "react";
import { signOut } from "../../Auth";
import { StoreBrandingType } from "../../types/misc";
import { WebAndBrandAppConfig } from "@/redux/types";
import useSiteEntityManager from "@/utils/hooks/useSiteEntityManager";

export const desktopHeaderHeight = 85;
export const mobileHeaderHeight = 60;

export enum StoreNavAction {
  Back = "back",
  SignOut = "signout",
  ToBag = "toBag",
  ToShopDomain = "toShopDomain",
}

const PageHeaderStyled = styled(motion.div)({
  position: "sticky",
  top: 0,
  backgroundColor: "#ffffff",
  height: desktopHeaderHeight,
  width: "100%",
  boxShadow: "0 5px 10px 0 rgba(181, 176, 176, 0.16)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    height: mobileHeaderHeight,
  },
  ".control_container": {
    width: "100px",
    height: desktopHeaderHeight,
    [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      width: "50px",
      height: mobileHeaderHeight,
    },
  },
  ".back_button": {
    position: "absolute",
    left: "2%",
    cursor: "pointer",
    width: 30,
    height: 30,
    padding: 0,
    outline: "none",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "none",
    ".button_icon": {
      maxHeight: 30,
      maxWidth: 30,
      objectFit: "contain",
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        maxHeight: 24,
        maxWidth: 24,
      },
    },
  },
  ".close_button": {
    position: "absolute",
    right: "2%",
    cursor: "pointer",
    width: 30,
    height: 30,
    padding: 0,
    outline: "none",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "none",
    ".button_icon": {
      maxHeight: 20,
      maxWidth: 20,
      objectFit: "contain",
      [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        maxHeight: 16,
        maxWidth: 16,
      },
    },
  },
  ".single_logo": {
    cursor: "pointer",
    objectFit: "contain",
    height: "101%",
    maxWidth: 150,
  },
});

const PageBodyStyled = styled(motion.div)({
  display: "flex",
  flexDirection: "column",
  "> div": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
});

export const PageBody = () => {
  const location = useLocation();
  useScrollSinglePageWrapperToTop(location.pathname);
  return (
    <PageBodyStyled
      initial={{ opacity: 0 }}
      className="page_body_outlet"
      sx={{
        justifyContent: "space-between",
        minHeight: `calc(100% - ${desktopHeaderHeight}px)`,
        height: `calc(100% - ${desktopHeaderHeight}px)`, // handle mobile here
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          minHeight: `calc(100% - ${mobileHeaderHeight}px)`,
          height: `calc(100% - ${mobileHeaderHeight}px)`, // handle mobile here
        },
      }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.8, duration: 0.5, ease: [0.22, 1, 0.36, 1] }}
    >
      <Outlet />
    </PageBodyStyled>
  );
};

const getPageNavConfig = (
  matchs: UIMatch<
    any,
    { pageNavConfig?: { close?: StoreNavAction; back?: StoreNavAction } }
  >[]
) => {
  let deepestPageNavConfig: { close?: StoreNavAction; back?: StoreNavAction } =
    {};

  matchs.forEach((item) => {
    if (item?.handle && item?.handle?.pageNavConfig) {
      deepestPageNavConfig = item.handle.pageNavConfig;
    }
  });

  return deepestPageNavConfig;
};

interface BrandLogoProps {
  brandId?: string;
  loading?: boolean;
  to: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}
const BrandLogo = ({ loading = false, brandId, ...props }: BrandLogoProps) => {
  if (loading) {
    return null;
  }

  const logoSrc = brandId
    ? getBrandLogo(brandId)
    : "https://media.brandclub.com/brandclub/image_asset/brandclub_logo_no_star.svg";

  return (
    <Link
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100%",
        padding: "7.5px",
        boxSizing: "border-box",
      }}
      {...props}
    >
      <img className="single_logo" alt={"single_logo"} src={logoSrc} />
    </Link>
  );
};

function getHeaderLink(
  appConfig: WebAndBrandAppConfig | null | undefined,
  brandName: string | undefined,
  brandId: string | undefined
): { external: boolean; link: string } {
  // Check if it's a custom DTC store with a custom domain
  const customDomain = appConfig?.domainConfig?.customShopDomainName;
  const storeBrandingType = appConfig?.domainConfig?.storeBrandingType;
  const isCustomDTCStore =
    storeBrandingType === StoreBrandingType.CustomDTCStore;
  const isCustomStore = storeBrandingType === StoreBrandingType.CustomStore;

  if (isCustomDTCStore && customDomain) {
    return { external: true, link: `https://${customDomain}/` };
  }

  // Check if we have both brand name and ID but it's not a custom store
  if (!isCustomStore && brandName && brandId) {
    return {
      external: false,
      link: `/${escapeUriPath(brandName)}/b/${brandId}/home`,
    };
  }

  // Default case
  return { external: false, link: "/" };
}

export const PageHeader = () => {
  const location = useLocation();
  const brandIdFromLocationState = location?.state?.brandId;
  const brandNameFromLocationState = location?.state?.brandName;
  const appConfig = useAppSelector(({ appConfig }) => appConfig);
  const brandEntity = useAppSelector(({ brandEntity }) => brandEntity);
  const navigate = useNavigate();
  const matches = useMatches();

  const brandName = brandNameFromLocationState ?? brandEntity?.entity?.name;
  const brandId = brandIdFromLocationState ?? brandEntity?.entity?.brandId;

  const headerLinkTo = getHeaderLink(appConfig, brandName, brandId);
  const pageNavConfig = getPageNavConfig(matches as any);

  const backButtonConfig = pageNavConfig?.back;
  const closeButtonConfig = pageNavConfig?.close;

  const handleToBag = () => {
    navigate("/mybag");
  };

  const handleBack = () => {
    // check if we are able to go back
    if (window.history.state.idx > 0) {
      navigate(-1);
    } else {
      handleToShopDomain();
    }
  };

  const handleSignout = () => {
    signOut();
  };

  const handleToShopDomain = () => {
    if (headerLinkTo.external) {
      window.location.href = headerLinkTo.link;
    } else {
      navigate(headerLinkTo.link);
    }
  };

  const actionMappping = {
    [StoreNavAction.Back]: handleBack,
    [StoreNavAction.SignOut]: handleSignout,
    [StoreNavAction.ToBag]: handleToBag,
    [StoreNavAction.ToShopDomain]: handleToShopDomain,
  };

  const onBackClick = backButtonConfig
    ? actionMappping[backButtonConfig]
    : undefined;
  const onCloseClick = closeButtonConfig
    ? actionMappping[closeButtonConfig]
    : undefined;

  return (
    <PageHeaderStyled
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: [0.22, 1, 0.36, 1] }}
      style={{ zIndex: 5 }}
    >
      {onBackClick ? (
        <div className="back_button">
          <BackButton onClick={onBackClick} iconSize={20} />
        </div>
      ) : null}

      <BrandLogo
        loading={brandEntity?.loading}
        brandId={brandIdFromLocationState ?? brandEntity?.id}
        to={headerLinkTo.link}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleToShopDomain();
        }}
      />

      {onCloseClick ? (
        <div className="close_button">
          <CloseButton iconSize={20} onClick={onCloseClick} />
        </div>
      ) : null}
    </PageHeaderStyled>
  );
};

const StoreSinglePageWrapperContext =
  createContext<{
    wrapper: RefObject<HTMLDivElement | null>;
  } | null>(null);

export const useScrollSinglePageWrapperToTop = <T,>(trigger: T) => {
  const wrapperContext = useContext(StoreSinglePageWrapperContext);
  if (!wrapperContext) {
    throw new Error("this hook must be used inside StoreSinglePageOutlet");
  }
  useEffect(() => {
    wrapperContext.wrapper.current?.scrollTo(0, 0);
  }, [trigger, wrapperContext.wrapper]);
};

const StoreSinglePageOutlet = () => {
  const ref = useRef<HTMLDivElement>(null);
  useSiteEntityManager();
  return (
    <div
      ref={ref}
      className="store_single_page_outlet"
      style={{ height: "100svh", overflowX: "clip", overflowY: "auto" }}
    >
      <StoreSinglePageWrapperContext.Provider value={{ wrapper: ref }}>
        <PageHeader />
        <PageBody />
      </StoreSinglePageWrapperContext.Provider>
    </div>
  );
};

export default StoreSinglePageOutlet;
