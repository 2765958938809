import {
  AnalyticsTrackingEvent,
  CampaignType,
  RetailerIconGroup,
  RewardPillWithText,
  SuccessCheckCircleIcon,
  brandclub_colors,
} from "@brandclub/common-ui";
import { styled, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import { formatMoney } from "../../../utils/StringUtils";
import { MOBILE_SCREEN_SIZE } from "../../AppNavigation/constants";
import { OutlineButton } from "../StoreButton";
import FiveStarRating from "../../pages/AppPages/Pages/Review/components/FiveStarRating";
import { decodeHTMLCharacters } from "@stackline/ui";
import ShareProductDialog from "../StoreDialogs/ShareProductDialog";
import { useSignedIn } from "@/utils/hooks/useSignedIn";

const LongCardContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  cursor: "pointer",
  height: "100%",
  transition: "background-color 0.4s ease-in-out",
  borderRadius: 10,
  backgroundColor: "#fff",
  "&:hover": {
    ".media_container": {
      ".img_icon": {
        transform: "scale(1.03)",
      },
    },
  },

  "&.useBorder": {
    ".img_container": {
      borderColor: "#dcdddc",
    },
    ".details": {
      borderColor: "#dcdddc",
    },
  },

  "&.media_container": {
    ".img_icon": {
      transition: "transform 0.4s ease-in-out",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      aspectRatio: "180/195",
    },
    "&:hover": {
      ".img_icon": {
        transform: "scale(1.03)",
      },
    },
  },
  "&.brand_container": {
    ".img_container": {
      borderTop: "1px solid",
      borderBottom: "1px solid",
      borderLeft: "1px solid",
      padding: "25px 0px",
      alignItems: "flex-start",
      [`@media screen and (max-width: ${1250}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        minWidth: "unset",
        width: "unset",
      },
    },
    ".img_icon": {
      transition: "transform 0.4s ease-in-out",
      width: 95,
      height: 95,
      objectFit: "contain",
      aspectRatio: "1",
      borderRadius: 900,
      [`@media screen and (max-width: ${1250}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        width: 46,
        height: 46,
        marginLeft: 12,
      },
    },
    ".details": {
      paddingLeft: 0,
      [`@media screen and (max-width: ${1250}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        paddingLeft: 12,
      },
    },
  },
  "&.product_container": {
    ".img_container": {
      borderTop: "1px solid",
      borderBottom: "1px solid",
      borderLeft: "1px solid",
    },
    ".img_icon": {
      transition: "transform 0.4s ease-in-out",
      width: "78%",
      objectFit: "contain",
      aspectRatio: "1",
      padding: "15px 0",
    },
    ".details": {
      ".reward_info": {
        paddingTop: 17,
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          paddingTop: 7,
        },
      },
      ".pill": {
        height: "31.75px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        borderRadius: 999,
        "&.sold_out": {
          boxSizing: "border-box",
          marginTop: 17,
          width: "fit-content",
          color: brandclub_colors.grayBorder,
          background: brandclub_colors.blueWhite,
          padding: "4.66667px 16.7692px 4px",
          fontSize: 14,
          [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
            marginTop: 7,
            padding: "3.66667px 14.46154px 3.14286px",
            fontSize: 11,
          },
        },
        "&.earned": {
          width: "200px",
          color: theme.palette.primary.main,
          background: brandclub_colors.white,
          border: `1px solid ${theme.palette.primary.main}`,
        },
      },
    },
  },
  "&.review_container": {
    ".img_container": {
      padding: "20px 0px",
      borderTop: "1px solid",
      borderBottom: "1px solid",
      borderLeft: "1px solid",
      [`@media screen and (max-width: ${1250}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        padding: "10px 10px",
      },
    },
    ".img_icon": {
      transition: "transform 0.4s ease-in-out",
      maxWidth: 132,
      objectFit: "contain",
      aspectRatio: "1",

      width: 132,
      height: 132,

      borderRadius: 0,
      [`@media screen and (max-width: ${1250}px)`]: {},
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        width: 90,
        height: 90,
        marginLeft: 12,
      },
    },
    ".details": {
      ".info": {
        marginBottom: 5,
      },
    },
  },

  ".img_container": {
    borderColor: "#fff",
    width: "23%",
    minWidth: 96,
    maxWidth: "180px",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ".play_button": {
      width: "26%",
      objectFit: "contain",
      zIndex: 1,
      position: "absolute",
    },
  },

  ".details": {
    borderColor: "#fff",
    overflow: "hidden",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "19.2px 37.5px 25.8px 22.4px",
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderRight: "1px solid",
    backgroundColor: "#fff",
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    // gap: 7,
    [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
      padding: "14.7px 12px",
    },

    ".product_affiliate": {
      height: 32,
    },
    ".info": {
      display: "flex",
      flexDirection: "column",
      gap: "7px",
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        gap: 2,
      },
      // flex: 1,
      ".title": {
        color: theme.palette.primary.main,
        fontSize: 20,
        fontWeight: 600,
        [`@media screen and (max-width: ${1250}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 14,
        },
      },
      ".subtitle": {
        color: theme.palette.primary.main,
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: 0.36,
        WebkitLineClamp: 2,
        lineHeight: 1.5,
        // height: `3em`, // line height * 2
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        wordBreak: "break-word",
        overflow: "hidden",
        [`@media screen and (max-width: ${1250}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 12,
        },
      },
      ".product_title": {
        color: theme.palette.primary.main,
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: 0.36,
        WebkitLineClamp: 2,
        lineHeight: 1.5,
        //   height: `3em`, // line height * 2
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        wordBreak: "break-word",
        overflow: "hidden",
        [`@media screen and (max-width: ${1250}px)`]: {},
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 12,
        },
      },

      ".price": {
        color: theme.palette.primary.main,
        fontSize: 17,
        fontWeight: 600,
        marginTop: 3,
        marginBottom: 0,
        display: "flex",
        columnGap: 14,
        alignItems: "center",
        ".retailer-logos": {
          display: "flex",
        },
        [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          columnGap: 7,
          fontSize: 14,
        },
      },
    },
    ".reward_info": {
      paddingTop: 17,
      flexDirection: "row",
      [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        paddingTop: 7,
      },
    },
  },
}));

const LongRewardCard = ({
  type = "product",
  image,
  title,
  subtitle,
  onClick,
  linkTo,
  linkState = {},
  rewardAmount = 0,
  productTitle = "",
  productPrice = 0,
  productRetailerIds,
  affiliateRewardAmount,
  rewardPercent,
  rewardType,
  border = false,
  productSoldOut,
  productRewardEarned,
  product,
  trackedEventName,
  trackedAdditionalData,
}: {
  type: "media" | "product" | "brand" | "review";
  image: string;
  rewardAmount: number;
  rewardType: CampaignType;
  title?: string;
  subtitle?: string;
  productTitle?: string;
  productPrice?: number;
  productSoldOut?: boolean;
  affiliateRewardAmount?: number;
  productRewardEarned?: boolean;
  onClick?: () => void;
  linkTo?: string;
  linkState?: any;
  productRetailerIds?: number[];
  border?: boolean;
  rewardPercent?: number;
  product?: any;
  trackedEventName?: AnalyticsTrackingEvent;
  trackedAdditionalData?: Record<string, any>;
}) => {
  const mobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  const isLoggedOut = !useSignedIn().signedIn;
  const renderCard = () => (
    <LongCardContainer
      onClick={onClick}
      className={`${type}_container ${border ? "useBorder" : ""}`}
    >
      <div
        className={`img_container`}
        style={{ borderColor: border ? "#dcdddc" : "#fff" }}
      >
        {type === "media" ? (
          <img
            src={
              "https://media.brandclub.com/brandclub/icons/player_control/play_button.svg"
            }
            alt=""
            className="play_button"
          ></img>
        ) : null}
        <img src={image} alt="" className="img_icon" />
      </div>
      <div
        className="details"
        style={{ borderColor: border ? "#dcdddc" : "#fff" }}
      >
        {type === "product" && affiliateRewardAmount ? (
          <div className="product_affiliate">
            <RewardPillWithText
              onPillClick={
                product &&
                ((e) => {
                  e.preventDefault();
                  ShareProductDialog.show(product, isLoggedOut);
                })
              }
              rewardAmount={affiliateRewardAmount ?? 0}
              rewardCampaignType={CampaignType.AffiliateRewardCampaign}
              baseFontSize={mobileView ? 11 : 14}
            />
          </div>
        ) : null}
        <div className="info" style={{ opacity: productSoldOut ? 0.5 : 1 }}>
          {title ? (
            <div className="title">{decodeHTMLCharacters(title)}</div>
          ) : null}
          {subtitle ? (
            <div className="subtitle">{decodeHTMLCharacters(subtitle)}</div>
          ) : null}
          {productTitle ? (
            <div className="product_title">
              {decodeHTMLCharacters(productTitle)}
            </div>
          ) : null}
          {type === "product" ? (
            <div className="price">
              <div>{formatMoney(productPrice ?? 0)}</div>
              <RetailerIconGroup
                retailerIds={productRetailerIds ?? []}
                maxSize={5}
                iconSize={mobileView ? 15 : 23}
              />
            </div>
          ) : null}
        </div>
        {type === "review" ? (
          <div>
            <FiveStarRating size={mobileView ? 20 : 30} />
          </div>
        ) : productRewardEarned ? (
          <OutlineButton
            trackedEventName={trackedEventName}
            trackedAdditionalData={trackedAdditionalData}
            style={{ height: 31.7, width: 180 }}
          >
            <SuccessCheckCircleIcon
              className="button_img"
              sx={{
                transform: "unset !important",
              }}
            />
            You bought this
          </OutlineButton>
        ) : productSoldOut ? (
          <div className="sold_out pill">Sold out</div>
        ) : rewardAmount > 0 ? (
          <div className="reward_info">
            <RewardPillWithText
              rewardAmount={rewardAmount ?? 0}
              rewardCampaignType={
                (rewardType as any) || CampaignType.DailyDealsRewardCampaign
              } // TODO, this need too be dynamic, need to fix common ui first
              rewardPercent={rewardPercent}
              containerStyle={{}}
              baseFontSize={mobileView ? 11 : 14}
              rewardPercentTextOverride={
                rewardType === CampaignType.DailyDealsRewardCampaign
                  ? "instant reward"
                  : rewardType === CampaignType.ContentRewardCampaign
                  ? "content reward"
                  : rewardType === CampaignType.SurveyRewardCampaign
                  ? "survey reward"
                  : undefined
              }
            />
          </div>
        ) : null}
      </div>
    </LongCardContainer>
  );

  if (linkTo) {
    return (
      <Link style={{ textDecoration: "none" }} to={linkTo} state={linkState}>
        {renderCard()}
      </Link>
    );
  } else {
    return renderCard();
  }
};

export default LongRewardCard;
