import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import { Box, styled } from "@mui/material";
import { motion } from "framer-motion";
import { cloneDeep as _cloneDeep, get as _get } from "lodash";
import _isEmpty from "lodash/isEmpty";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { SiteBrandingType } from "../../../types/misc";

import { useSiteContext } from "../../SiteProvider";
import { SingleNavType } from "../constants";
import AuthHeader from "./AuthHeader";
import MenuItem from "./MenuItem";
import { getBrandLogo } from "@brandclub/common-ui";
import { useSignedIn } from "@/utils/hooks/useSignedIn";

const memberCenterNavItems: SingleNavType[] = [
  {
    name: "Dashboard",
    route: "dashboard/me",
    displayName: "Dashboard",
  },
  {
    name: "Purchases",
    route: "dashboard/purchase",
    displayName: "Purchases",
  },
  {
    name: "Accounts",
    route: "dashboard/accounts",
    displayName: "Accounts",
  },
  {
    name: "Rewards",
    displayName: "Rewards",
    subNav: [
      {
        name: "Balance",
        displayName: "Balance",
        route: "dashboard/rewards/balance",
      },
      {
        name: "Activity",
        displayName: "Activity",
        route: "dashboard/rewards/activity",
      },
      {
        name: "Expiring",
        displayName: "Expiring",
        route: "dashboard/rewards/expiring",
      },
      {
        name: "Payouts",
        displayName: "Payouts",
        route: "dashboard/rewards/payouts",
      },
    ],
  },
  {
    name: "Profile",
    route: "dashboard/profile",
    displayName: "Personal",
  },
  {
    name: "Wallet",
    route: "dashboard/wallet",
    displayName: "Wallet",
  },
  {
    name: "Addresses",
    route: "dashboard/address",
    displayName: "Addresses",
  },
];

const BrandLogo = styled("img")({
  width: 40,
  height: 40,
  borderRadius: 100,
  objectPosition: "center",
  objectFit: "contain",
  margin: "0px 20px 10px",
});
const BrandstoreMenuInner = ({
  navStack,
  setNavStack,
  onClose,
  isUniversalStoreMenu,
}: {
  navStack: (SingleNavType[] | SingleNavType)[];
  setNavStack: React.Dispatch<
    React.SetStateAction<(SingleNavType[] | SingleNavType)[]>
  >;
  onClose: ((event: any) => void) | undefined;
  isUniversalStoreMenu?: boolean;
}) => {
  const { routePrefix, persistentQueryParams, siteBrandingType } =
    useSiteContext();
  const brandEntity = useAppSelector((state) => state.brandEntity);
  const appConfig = useAppSelector(({ appConfig }) => appConfig);
  const currentMenu = navStack[navStack.length - 1];

  const moveIn = (item: SingleNavType) => {
    const navStackClone = _cloneDeep(navStack);
    navStackClone.push(item);
    setNavStack(navStackClone);
  };

  const moveOut = () => {
    const navStackClone = _cloneDeep(navStack);
    navStackClone.pop();
    setNavStack(navStackClone);
  };

  if (Array.isArray(currentMenu)) {
    return (
      <motion.div
        key={"home"}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2, ease: "easeInOut" }}
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          margin: isUniversalStoreMenu ? "0" : "30px 0",
        }}
      >
        {[
          SiteBrandingType.UniversalStore,
          SiteBrandingType.CustomStore,
        ].includes(siteBrandingType) || isUniversalStoreMenu ? null : (
          <Link
            to={`${routePrefix}/home?${persistentQueryParams || ""}`}
            onClick={onClose}
          >
            <BrandLogo
              alt="Brand Logo"
              loading="lazy"
              src={getBrandLogo(
                brandEntity?.id || appConfig?.domainConfig.brandId
              )}
            ></BrandLogo>
          </Link>
        )}
        <Box
          sx={{
            flex: 1,
            ".nav_item.top-level": {
              fontSize: 18,
              paddingTop: isUniversalStoreMenu ? 0 : undefined,
              paddingBottom: isUniversalStoreMenu ? 0 : undefined,
            },
          }}
        >
          {currentMenu.map((menu, index) => {
            return (
              <MenuItem
                key={menu.name}
                item={menu}
                onClose={onClose}
                topLevel={true}
                moveIn={moveIn}
              />
            );
          })}
        </Box>
      </motion.div>
    );
  }

  if (currentMenu.subNav) {
    let prevPageName =
      currentMenu.name === "Shop" || currentMenu.name === "BrandShop"
        ? `Shop ${brandEntity?.entity?.name || ""}`
        : currentMenu.displayName;
    if (navStack.length >= 3) {
      prevPageName = _get(navStack, [navStack.length - 2, "name"], "");
    }
    return (
      <motion.div
        key={currentMenu.name}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2, ease: "easeInOut" }}
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          margin: "10px 0",
        }}
      >
        <div style={{ flex: 1 }}>
          <div
            className="nav_item"
            role={"button"}
            onClick={moveOut}
            style={{
              display: "grid",
              gridTemplateColumns: "6.6px auto",
              columnGap: "15px",
              justifyContent: "flex-start",
              fontWeight: 600,
              fontSize: 18,
            }}
          >
            <KeyboardArrowLeftRoundedIcon className="left-icon" />{" "}
            {prevPageName}
          </div>

          {currentMenu.subNav.map((menu) => {
            return (
              <MenuItem
                key={menu.name}
                item={menu}
                onClose={onClose}
                moveIn={moveIn}
              />
            ); // elements rendered as items in an Array need a key prop
          })}
        </div>
      </motion.div>
    );
  }
  return null;
};

const BrandstoreMenu = ({
  navMenu,
  onClose,
}: {
  navMenu: SingleNavType[];
  onClose: ((event: any) => void) | undefined;
}) => {
  const [navStack, setNavStack] = useState<(SingleNavType[] | SingleNavType)[]>(
    [navMenu]
  );
  const [memberCenterNav, setMemberCenterNav] = useState<{
    isExpanded: boolean;
    navStack: Array<SingleNavType[] | SingleNavType>;
  }>({
    isExpanded: false,
    navStack: [
      [
        {
          name: "MemberCenter",
          displayName: "Member Center",
          subNav: memberCenterNavItems,
        },
      ],
    ],
  });

  const updateMemberCenterNavStack = useCallback(
    (action: React.SetStateAction<(SingleNavType | SingleNavType[])[]>) => {
      setMemberCenterNav((prev) => {
        const navStack =
          typeof action === "function" ? action(prev.navStack) : action;
        return {
          isExpanded: navStack.length !== 1,
          navStack,
        };
      });
    },
    []
  );

  const { signedIn } = useSignedIn();

  return (
    <>
      {navStack.length === 1 && !memberCenterNav.isExpanded ? (
        signedIn ? (
          <>
            <div className=" home_header">
              <div className="attachments">
                <AuthHeader onClose={onClose}></AuthHeader>
                <BrandstoreMenuInner
                  navStack={memberCenterNav.navStack}
                  setNavStack={updateMemberCenterNavStack}
                  onClose={onClose}
                  isUniversalStoreMenu
                />
              </div>
            </div>
            {_isEmpty(navMenu) ? null : <div className="divider" />}
          </>
        ) : null
      ) : null}
      <BrandstoreMenuInner
        isUniversalStoreMenu={memberCenterNav.isExpanded}
        navStack={
          memberCenterNav.isExpanded ? memberCenterNav.navStack : navStack
        }
        setNavStack={
          memberCenterNav.isExpanded ? updateMemberCenterNavStack : setNavStack
        }
        onClose={onClose}
      />
    </>
  );
};

export default BrandstoreMenu;
