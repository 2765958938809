import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

import { styled, useMediaQuery } from "@mui/material";
import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { MOBILE_SCREEN_SIZE } from "../../../../AppNavigation/constants";
import {
  isItemCheckoutSuccess,
  isPurchaseCheckoutSuccess,
} from "../../../../Checkout/utils";
import { useSiteContext } from "../../../../SiteProvider";
import StoreLoadingSkeleton from "../../../../StoreComponents/StoreLoadingSkeleton";
import { LandingHeader } from "../../Pages/components/Header";
import LandingContainer from "../../Pages/components/LandingContainer";
import { useFetchPurchaseDetail } from "./data";
import PurchaseDetailBreakdown from "./PurchaseDetailBreakdown";
import { formatMoney } from "../../../../../utils/StringUtils";
import RewardBlockLink from "../../../../Checkout/components/RewardBlockLink";

const getRewardText = (rewardAmount: number, rewardUnlocked: number) => {
  const rewardBlockTitle = `Congratulations!`;
  let rewardBlockText = ``;
  if (rewardAmount > 0 && rewardUnlocked > 0) {
    rewardBlockText = `You earned ${formatMoney(
      rewardAmount
    )} in new rewards and unlocked ${formatMoney(
      rewardUnlocked
    )} in existing rewards.`;
  } else if (rewardAmount > 0) {
    rewardBlockText = `You earned ${formatMoney(rewardAmount)} in new rewards.`;
  } else if (rewardUnlocked > 0) {
    rewardBlockText = `You unlocked ${formatMoney(
      rewardUnlocked
    )} in existing rewards.`;
  }

  return { rewardBlockTitle, rewardBlockText };
};

const HeadSummary = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  ".reward_block_container": {
    margin: "10px 0",
  },
}));

const Divider = styled("div")(({ theme }) => ({
  width: "100%",
  height: "1px",
  backgroundColor: "#dcdddc",
}));

const BackLink = styled("div")(({ theme }) => ({
  color: theme.palette.primary.main,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  fontSize: "16px",
  fontWeight: 600,
  marginBottom: "10px",
  textDecoration: "none",
}));

const OrderByRetailer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  ".order_breakdown + .order_breakdown": {
    borderTop: "1px solid #dcdddc",
  },
}));

const PurchaseDetail = () => {
  const isMobileView = useMediaQuery(`(max-width:${MOBILE_SCREEN_SIZE}px)`);
  const { routePrefix } = useSiteContext();
  const { batchId } = useParams();
  const { data, loading } = useFetchPurchaseDetail({
    batchId: batchId as string,
    pageSize: 100,
  });

  const items = useMemo(
    () =>
      data
        .filter(
          (d) => d.checkoutBatchId === batchId && isPurchaseCheckoutSuccess(d)
        )
        .map((d) => ({
          ...d,
          items: d.items.filter((i) => isItemCheckoutSuccess(i)),
        })),
    [data, batchId]
  );
  const purchasesPageUrl = routePrefix
    ? `${routePrefix}/branddashboard/purchase`
    : `/dashboard/purchase`;

  const rewardAmount = items?.reduce((acc, item) => {
    if (item.status === "checkout_success") {
      Object.keys(item.products).forEach((key) => {
        const product = item.products[key];
        const amount = product?.reward?.rewardAmount || 0;
        acc += amount;
      });
    }
    return acc;
  }, 0);

  const rewardUnlocked = 0;

  const { rewardBlockTitle, rewardBlockText } = getRewardText(
    rewardAmount || 0,
    rewardUnlocked
  );

  return (
    <>
      <LandingContainer sx={{ paddingTop: 0 }}>
        {loading || !items.length ? (
          <StoreLoadingSkeleton cardStyle="longCardList" header row={1} />
        ) : (
          <>
            <Link to={purchasesPageUrl} style={{ textDecoration: "none" }}>
              <BackLink>
                <ChevronLeftRoundedIcon /> All purchases
              </BackLink>
            </Link>
            <LandingHeader
              marginBottom={isMobileView ? 0 : 42}
              title={`Order #${items[0]?.checkoutBatchId}`}
              sx={{
                [`@media (max-width:${MOBILE_SCREEN_SIZE}px)`]: {
                  ".title": {
                    fontWeight: 500,
                    fontSize: 16,
                  },
                },
              }}
            />

            {(!isMobileView || rewardAmount > 0) && (
              <HeadSummary>
                {rewardAmount > 0 || rewardUnlocked > 0 ? (
                  <div className="reward_block_container">
                    <RewardBlockLink
                      title={rewardBlockTitle}
                      text={rewardBlockText}
                    />
                  </div>
                ) : null}
                {!isMobileView && <Divider />}
              </HeadSummary>
            )}

            <OrderByRetailer className="order_by_retailer">
              {items.map((item, index) => {
                return (
                  <PurchaseDetailBreakdown
                    purchase={item}
                    key={item.orderId}
                    sx={
                      index !== 0
                        ? {
                            paddingTop: "43px",
                            [`@media (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
                              paddingTop: "30px",
                            },
                          }
                        : {}
                    }
                  />
                );
              })}
            </OrderByRetailer>
          </>
        )}
      </LandingContainer>
    </>
  );
};

export default PurchaseDetail;
