import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";

export const BrandclubVideoWrapper = styled(motion.div)(() => ({
  overflow: "hidden",
  position: "relative",
  background: "none",
  borderRadius: "5px",
  opacity: 1,
  y: 0,
}));

export const BrandclubVideoPlayer = styled("video")(() => ({
  borderRadius: 0,
  display: "block",
  width: "100%",
  background: "none",
  /*
   * Force Chrome to use GPU instead of CPU for video rendering to prevent border glitch on Intel processors
   * https://stackoverflow.com/a/79193232/28502964
   */
  filter: "grayscale(0)",
}));

export const BrandclubVideoControl = styled("button", {
  shouldForwardProp: (prop) => prop !== "isLowerPlayButton",
})<{ isLowerPlayButton?: boolean }>(({ isLowerPlayButton }) => {
  const lowerPlayButtonStyles = isLowerPlayButton
    ? {
        alignItems: "flex-end",
        justifyContent: "flex-start",
      }
    : {};

  return {
    position: "absolute",
    background: "transparent",
    outline: "none",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1",
    width: "100%",
    height: "100%",
    cursor: "pointer",
    ...lowerPlayButtonStyles,
  };
});

export const ControlButtonImgWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "variant",
})<{ variant?: "normal" | "small" }>(({ variant = "normal" }) => ({
  width: variant === "small" ? "25%" : "23%",
  maxWidth: variant === "small" ? "55px" : "80px",
  marginLeft: variant === "small" ? "20px" : undefined,
  marginBottom: variant === "small" ? "20px" : undefined,
  cursor: "pointer",
}));

export const ControlButtonImg = styled("img", {
  shouldForwardProp: (prop) => prop !== "playing",
})<{ playing?: boolean }>(({ playing }) => ({
  height: "100%",
  width: "100%",
  transition: "opacity 500ms",
  opacity: playing ? 0 : 1,
}));
