import { useEffect, useState } from "react";

export interface UseSendStatusProps {
  onStatusReset?: VoidFunction;
}
export type SendStatus = "notSend" | "sending" | "sent";

export const useSendStatus = ({ onStatusReset }: UseSendStatusProps) => {
  const [sendStatus, setSendStatus] = useState<SendStatus>("notSend");
  const [error, setError] = useState("");

  useEffect(() => {
    if (sendStatus === "sent") {
      // Reset the send status after 3 seconds
      setTimeout(() => {
        setSendStatus("notSend");
        onStatusReset?.();
      }, 3000);
    }
    // only run this effect when sendStatus changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendStatus]);

  return { sendStatus, setSendStatus, error, setError };
};
