import { Outlet } from "react-router-dom";
import { AppMainNavigation } from "../../AppNavigation";
import { AppFade } from "../../StoreComponents/StoreTransition";
import LandingPageContentWithFooter from "./LandingPageContentWithFooter";
import useSiteEntityManager from "@/utils/hooks/useSiteEntityManager";

const AppMainPageOutlet = () => {
  useSiteEntityManager();

  return (
    <AppFade>
      <div>
        <AppMainNavigation></AppMainNavigation>
        <div>
          <LandingPageContentWithFooter>
            <div
              className="main-page-content"
              style={{
                minHeight: "66vh",
                paddingBottom: 100,
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Outlet />
            </div>
          </LandingPageContentWithFooter>
        </div>
      </div>
    </AppFade>
  );
};

export default AppMainPageOutlet;
