import { UserRetailerShoppingCart } from "@/components/Checkout/types";
import ReduxStore from "../types";
import { sumAllItemQuantities } from "@/utils/misc";

type ShoppingCartWithItems = Partial<UserRetailerShoppingCart> &
  Pick<Required<UserRetailerShoppingCart>, "items" | "retailerId">;

/**
 * Redux state selector that gets all carts from the state and return only the ones that have items in them
 *
 * *Note*: This selector returns a new array every time it is called,
 * so an equality function should be passed to the `useAppSelector` hook to prevent unnecessary re-renders
 */
export const getAllCarts = (state: ReduxStore) => {
  const cart = state.checkout.optimisticAllCarts ?? state.checkout.allCarts;
  return cart.filter(
    (c): c is ShoppingCartWithItems =>
      !!c.items && c.retailerId != null && c.items.length > 0
  );
};

export const getCartCounts = (state: ReduxStore) => {
  const carts = state.checkout.optimisticAllCarts ?? state.checkout.allCarts;
  return sumAllItemQuantities(carts);
};
