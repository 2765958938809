import Fade, { FadeProps } from "@mui/material/Fade";
import { motion } from "framer-motion";
import React, { FC } from "react";

export const withFadeTransition = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  const WithFadeTransition: React.FC<P> = (props) => {
    return (
      <motion.div
        className=""
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
        style={{ flex: 1 }}
      >
        <WrappedComponent {...props} />
      </motion.div>
    );
  };
  return WithFadeTransition;
};

export const AppFade: FC<FadeProps> = (props) => {
  return (
    <Fade
      in={true}
      timeout={1000}
      easing={{
        enter: "cubic-bezier(0.22, 1, 0.36, 1)",
        exit: "cubic-bezier(0.22, 1, 0.36, 1)",
      }}
      {...props}
    />
  );
};
