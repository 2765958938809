import { styled } from "@mui/material";
import ContentPreview from "../Pages/Content/preview";
import DailyDealsPreview from "../Pages/DailyDeals/preview";
import SurveysPreview from "../Pages/Survey/preview";
import HomeActionSection from "./HomeActionSection";
import AffiliatePreview from "../Pages/Affiliate/preview";
import TopPicksPreview from "../Pages/TopPicks/preview";
import TrendingPreview from "../Pages/Trending/preview";

import ClubInvitesPreview from "../Pages/ClubInvites/preview";
import ReviewPreview from "../Pages/Review/preview";
import HomeSearchBar from "./HomeSearchBar";
import HomeAccountsPreview from "../Pages/Accounts/HomeAccountsPreview";

import MyClubsPreview from "../Pages/MyClubs/preview";
import { useAppSelector } from "../../../../redux/hooks";
import { StoreBrandingType } from "../../../../types/misc";
import { useSignedIn } from "@/utils/hooks/useSignedIn";

const HomePageContainer = styled("div")({
  marginTop: 30,
  width: "100%",
});

const Home = () => {
  const { signedIn } = useSignedIn();
  const appConfig = useAppSelector((state) => state.appConfig);

  return (
    <HomePageContainer className="HomePageContainer">
      <HomeSearchBar />

      <HomeActionSection />
      <DailyDealsPreview />
      {signedIn ? <ReviewPreview /> : null}
      <SurveysPreview backgroundColor="#fff" arrowColor="grey" />
      <AffiliatePreview />

      <HomeAccountsPreview />
      <TrendingPreview />
      {appConfig?.domainConfig?.storeBrandingType ===
        StoreBrandingType.UniversalStore && <ClubInvitesPreview />}

      <ContentPreview />

      {signedIn &&
        appConfig?.domainConfig?.storeBrandingType ===
          StoreBrandingType.UniversalStore && <MyClubsPreview />}
      <TopPicksPreview />
    </HomePageContainer>
  );
};

export default Home;
