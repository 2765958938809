import { setup, assign, fromPromise } from "xstate";
import { RetailerWithSyncStatus, TopBrand } from "@/redux/types";
import {
  hasJoinedMinBrandclubs,
  shouldConnectRetailer,
} from "./userAuthHelpers";
import { StoreBrandingType } from "../../../types/misc";
import { NavigateFunction, NavigateOptions } from "react-router";
import { AppDispatch } from "@/redux/store";
import { getUserSignedInState, setSharedAuthCookie } from "@/Auth";
import { loadTopBrands, reloadProfile } from "@/redux/actions";
import { loadReferralReward } from "@/redux/actions/referralReward";
import { getActiveCarts } from "@/redux/reducers/checkout/thunk";
import { getCustomerSpendByBrand } from "@/redux/reducers/customerSpendByBrand/thunk";
import {
  loadRetailersAuth,
  loadRetailersUnauth,
} from "@/redux/actions/retailers";
import { loadRewards } from "@/redux/actions/rewards";
import {
  UserLoginMachinePath,
  UserLoginMachineUser,
  UserLoginMachineUserProfile,
  UserLoginMachineAppConfig,
} from "./types";

type InternalNavigateToParams = UserLoginMachinePath & {
  options?: NavigateOptions;
  external?: false;
};
type ExternalNavigateToParams = {
  external: true;
  href: string;
};
type NavigateToParams = InternalNavigateToParams | ExternalNavigateToParams;

interface LoadedUserData {
  userProfile?: UserLoginMachineUserProfile;
  topBrands?: UserLoginContext["topBrands"];
  retailers?: RetailerWithSyncStatus[];
}

interface InitUserDataInput {
  reduxDispatch: AppDispatch | undefined;
}

interface InitUserDataOutput {
  userData: LoadedUserData;
  userInfo?: any;
  signedIn: boolean;
}

const UserLoginStateToRouteMapping = {
  setupUserProfile: "/setupuserprofile",
  signIn: "/signin",
  signInVerify: "/signinverify",
  joinBrandclubs: "/joinbrandclubs",
  connectRetailers: "/connectretailers",
  authenticateWithRetailer: "/authenticatewithretailer",
  connectRetailerInProgress: "/connectretailerinprogress",
  connectRetailerOTP: "/connectretailerotp",
  findAccount: "/findaccount",
  verifyEmail: "/verifyemail",
  updatePhoneNumber: "/updatephonenumber",
  success: "/success",
} as const;

type UserLoginStateToRouteKey = keyof typeof UserLoginStateToRouteMapping;

export type UserLoginContext = Partial<{
  providerType: "email" | "Google" | "Facebook" | "SignInWithApple";
  appConfig: UserLoginMachineAppConfig;
  user?: UserLoginMachineUser;
  appInstallationInfo: any;
  userProfile?: UserLoginMachineUserProfile;
  topBrands?: TopBrand[];
  localAuthenticationInfo: {
    hasHardware: boolean;
    isEnrolled: boolean;
  };
  settleStatus: {
    location: boolean;
    notification: boolean;
  };
  /**
   * used to redirect back to the ORIGINAL page after login flow
   */
  initialOpenPath?: UserLoginMachinePath;
  otpPhoneNumber?: string;
  otpRequestId?: string;
  cognitoSession?: any;
  isNewUser?: boolean;
  ssoRedirectUrl?: string | null;
  navigate: NavigateFunction;
  reduxDispatch: AppDispatch;
  // Phone update flow
  session?: string;
  sessionId?: string;
  emailToVerify?: string;
  errorMessage?: string;
  connectRetailerId?: number;
}>;

export type UserLoginEvents =
  | {
      type: "RESET_ERROR";
    }
  | {
      type:
        | "Hub.Auth.SignOut"
        | "Hub.Auth.signIn_failure"
        | "Hub.Auth.cognitoHostedUI_failure";
      errorMessage?: string;
    }
  /**
   * Event sent when the user navigates using the browser's back/forward buttons
   */
  | {
      type: "BROWSER_NAVIGATION";
      pathname: string;
      prevPath: UserLoginMachinePath;
    }
  /**
   * Event sent when the user refreshes, or navigates directly to a URL that corresponds to a login state
   */
  | {
      type: "SYNC_STATE_WITH_ROUTE";
      pathname: string;
      prevPath: UserLoginMachinePath;
    }
  | {
      type: "SIGN_IN";
      redirectPath?: UserLoginMachinePath;
    }
  | {
      type: "MOVE_TO_VERIFY_OTP";
      cognitoSession: any;
      isNewUser: boolean;
      otpPhoneNumber: string;
    }
  | {
      type: "RESEND_VERIFY_OTP";
      cognitoSession: any;
    }
  | {
      type: "Hub.Auth.SignIn";
      data: unknown;
    }
  | {
      type: "INITIALIZED_USER_DATA";
    }
  | {
      type: "SUBMIT";
      redirectPath?: UserLoginMachinePath;
    }
  | {
      type: "ERROR";
    }
  | {
      type: "SUCCESS";
    }
  | {
      type: "COGNITO";
      data: "google" | "facebook" | "apple";
    }
  | {
      type: "AUTHENTICATE_WITH_RETAILER";
      retailerId: number;
    }
  | {
      type: "CONNECT_RETAILER_IN_PROGRESS";
      retailerId: number;
    }
  | {
      type: "CONNECT_RETAILER_SUCCEEDED";
      retailerId: number;
    }
  | {
      type: "CONNECT_RETAILER_FAILED";
      retailerId: number;
      errorMessage: string;
    }
  | {
      type: "CONNECT_RETAILER_REQUEST_OTP";
    }
  | {
      type: "RERUN_MACHINE";
    }
  | {
      type: "CHANGE_PHONE_NUMBER";
    }
  | {
      type: "FOUND_ACCOUNT";
      session: string;
      sessionId: string;
      emailToVerify: string;
      otpPhoneNumber: string;
    }
  | {
      type: "SUBMIT_OTP_FOR_UPDATE_PHONE";
      session: string;
      sessionId: string;
    };

/**
 * Determines the login state corresponding to a given pathname.
 * Maps a URL pathname to its corresponding login state using the UserLoginStateToRouteMapping.
 *
 * @param pathname - The URL pathname to find the corresponding login state for
 * @returns The matching UserLoginStateToRouteKey if the pathname corresponds to a login state, undefined otherwise
 */
export const getLoginStateFromRoute = (pathname: string) => {
  pathname = pathname.toLowerCase();
  const loginStateToRouteEntries = Object.entries(
    UserLoginStateToRouteMapping
  ).map(
    ([state, route]) => [state as UserLoginStateToRouteKey, route] as const
  );
  const expectedState = loginStateToRouteEntries.find(
    ([, route]) => route === pathname
  )?.[0];

  return expectedState;
};

/**
 * Determines if a given pathname corresponds to a route that's part of the login/sign-up flow.
 * @param pathname - The URL pathname to check
 * @returns True if the pathname corresponds to a login route, false otherwise
 */
export const isLoginRoute = (pathname: string) => {
  return Object.values(UserLoginStateToRouteMapping).includes(
    pathname?.toLowerCase() as any
  );
};

const navigateTo = (
  navigate: NavigateFunction | undefined,
  params: NavigateToParams
) => {
  if (!navigate) {
    throw new Error("navigate function is not defined in context");
  }
  if (params.external) {
    window.location.href = params.href;
  } else {
    const { pathname, search, options } = params;
    if (window.location.pathname !== pathname) {
      navigate({ pathname, search }, options);
    }
  }
};

const loadAuthenticatedUserData = async (
  dispatch: AppDispatch
): Promise<LoadedUserData> => {
  const authenticatedUserData: {
    userProfile?: UserLoginMachineUserProfile;
    topBrands?: TopBrand[];
    retailers?: RetailerWithSyncStatus[];
  } = {};
  const [
    loadTopBrandsResponse,
    reloadProfileResponse,
    loadRetailersAuthResponse,
  ] = await Promise.allSettled([
    dispatch(loadTopBrands()),
    dispatch(reloadProfile()),
    dispatch(loadRetailersAuth()),
    dispatch(loadReferralReward()),
    dispatch(loadRewards()),
    dispatch(getCustomerSpendByBrand()),
    dispatch(getActiveCarts()),
  ]);

  if (
    reloadProfileResponse.status === "fulfilled" &&
    reloadProfileResponse.value
  ) {
    authenticatedUserData.userProfile = {
      givenName: reloadProfileResponse.value.given_name,
      familyName: reloadProfileResponse.value.family_name,
      email: reloadProfileResponse.value.email,
      extendedAttributes: reloadProfileResponse.value.extendedAttributes,
    };
  }
  if (
    loadTopBrandsResponse.status === "fulfilled" &&
    loadTopBrandsResponse.value
  ) {
    authenticatedUserData.topBrands = loadTopBrandsResponse.value;
  }
  if (
    loadRetailersAuthResponse.status === "fulfilled" &&
    loadRetailersAuthResponse.value
  ) {
    authenticatedUserData.retailers = loadRetailersAuthResponse.value;
  }
  return authenticatedUserData;
};

const handleUnauthenticatedUser = async (
  dispatch: AppDispatch
): Promise<LoadedUserData> => {
  const retailers = await dispatch(loadRetailersUnauth());
  if (retailers) {
    return { retailers };
  }
  return {};
};

const setupUserLoginMachine = setup({
  types: {
    context: {} as UserLoginContext,
    events: {} as UserLoginEvents,
  },
  actions: {
    RESET_ERROR: assign({
      errorMessage: undefined,
    }),
    redirectUserAfterLoginFlow: ({ context }) => {
      const { navigate } = context;
      if (!navigate) {
        throw new Error("navigate function is not defined in context");
      }
      if (context.ssoRedirectUrl) {
        window.location.href = context.ssoRedirectUrl;
        return;
      }
      if (
        !context.initialOpenPath ||
        isLoginRoute(context.initialOpenPath.pathname)
      ) {
        const homePath = { pathname: "/" };
        return navigateTo(navigate, homePath);
      }
      navigateTo(navigate, context.initialOpenPath);
    },
  },
  guards: {
    isUniversalStore: ({ context }) => {
      return (
        !!context.appConfig &&
        context.appConfig.storeBrandingType === StoreBrandingType.UniversalStore
      );
    },
    isCustomDTCStore: ({ context }) => {
      return (
        !!context.appConfig &&
        context.appConfig.storeBrandingType === StoreBrandingType.CustomDTCStore
      );
    },
    isCustomStore: ({ context }) => {
      return (
        !!context.appConfig &&
        context.appConfig.storeBrandingType === StoreBrandingType.CustomStore
      );
    },
    isSignedIn: ({ context }) => {
      return !!context.user?.signedIn;
    },
    isNotSignedIn: ({ context }) => {
      return !context.user?.signedIn;
    },
    isInitialLoginStateAllowed: ({ context }) => {
      if (!context.initialOpenPath || context.user?.signedIn) {
        return false;
      }
      return isLoginRoute(context.initialOpenPath.pathname);
    },
    needsProfileSetup: ({ context }) => {
      const needs =
        !context.userProfile?.givenName ||
        !context.userProfile?.familyName ||
        !context.userProfile?.email;
      return Boolean(context.user?.signedIn && needs);
    },
    shouldConnectRetailers: ({ context }) => {
      return Boolean(
        context.user?.signedIn &&
          context.appConfig?.storeBrandingType ===
            StoreBrandingType.UniversalStore &&
          shouldConnectRetailer(context.userProfile)
      );
    },
    shouldJoinBrandClubs: ({ context }) => {
      return Boolean(
        context.user?.signedIn &&
          // user need to join brandclubs if user is on brandclub.com and has less than 4 brandclubs
          context.appConfig?.storeBrandingType ===
            StoreBrandingType.UniversalStore &&
          !hasJoinedMinBrandclubs(context.topBrands)
      );
    },
    isBrowserNavTransitionAllowed: (
      { context, event },
      input: {
        allowedTargetState: UserLoginStateToRouteKey;
        validateContext?: (context: UserLoginContext) => boolean;
      }
    ) => {
      if (event.type !== "BROWSER_NAVIGATION") {
        throw new Error(
          `Invalid event type for isBrowserNavTransitionAllowed guard. Received: ${event.type}, Expected: BROWSER_NAVIGATION`
        );
      }
      const stateFromRoute = getLoginStateFromRoute(event.pathname);
      if (input.validateContext) {
        return (
          stateFromRoute === input.allowedTargetState &&
          input.validateContext(context)
        );
      }
      return stateFromRoute === input.allowedTargetState;
    },
  },
  actors: {
    initUserData: fromPromise<InitUserDataOutput, InitUserDataInput>(
      async ({ input }) => {
        const { reduxDispatch } = input;
        if (!reduxDispatch) {
          throw new Error("reduxDispatch is not defined in input");
        }
        const [{ signedIn, userInfo }] = await Promise.all([
          getUserSignedInState(),
        ]);
        if (userInfo && signedIn) {
          // Authenticated user
          await setSharedAuthCookie();
          const userData = await loadAuthenticatedUserData(reduxDispatch);
          return {
            userData,
            userInfo,
            signedIn,
          };
        } else {
          // Unauthenticated user
          const userData = await handleUnauthenticatedUser(reduxDispatch);
          return {
            userData,
            signedIn,
          };
        }
      }
    ),
  },
});

export const userLoginMachine = setupUserLoginMachine.createMachine({
  initial: "initializeUserData",
  context: ({ input }) => ({
    ...input,
  }),
  on: {
    "Hub.Auth.SignOut": [
      {
        actions: [
          assign(({ context, event }) => {
            return {
              ...context,
              cognitoSession: undefined,
              otpPhoneNumber: undefined,
              user: { signedIn: false },
              userProfile: undefined,
              topBrands: undefined,
              appInstallationInfo: undefined,
              initialOpenPath: undefined,
              errorMessage: event?.errorMessage,
              isNewUser: undefined,
              localAuthenticationInfo: undefined,
              settleStatus: undefined,
              session: undefined,
              sessionId: undefined,
              emailToVerify: undefined,
            };
          }),
          ({ context }) => {
            navigateTo(context.navigate, {
              pathname: "/signoutredirect",
            });
          },
        ],
        target: ".goHome",
      },
    ],
    SYNC_STATE_WITH_ROUTE: [
      {
        target: ".goHome",
        guard: ({ event }) => !isLoginRoute(event.pathname),
      },
    ],
    RERUN_MACHINE: {
      target: ".initializeUserData",
    },
  },
  states: {
    signIn: {
      entry: [
        ({ context }) => {
          navigateTo(context.navigate, {
            pathname: UserLoginStateToRouteMapping.signIn,
          });
        },
      ],
      on: {
        MOVE_TO_VERIFY_OTP: [
          {
            target: "signInVerify",
            actions: assign({
              otpPhoneNumber: ({ event }) => event.otpPhoneNumber,
              cognitoSession: ({ event }) => event.cognitoSession,
              isNewUser: ({ event }) => event.isNewUser,
            }),
          },
        ],
        CHANGE_PHONE_NUMBER: {
          target: "findAccount",
        },
        BROWSER_NAVIGATION: [
          {
            target: "signInVerify",
            guard: {
              type: "isBrowserNavTransitionAllowed",
              params: { allowedTargetState: "signInVerify" },
            },
          },
          {
            target: "findAccount",
            guard: {
              type: "isBrowserNavTransitionAllowed",
              params: { allowedTargetState: "findAccount" },
            },
          },
          {
            target: "initializeUserData",
            guard: ({ event }) => isLoginRoute(event.pathname),
          },
          {
            target: "goHome",
          },
        ],
      },
    },
    findAccount: {
      entry: [
        ({ context }) => {
          navigateTo(context.navigate, {
            pathname: UserLoginStateToRouteMapping.findAccount,
          });
        },
      ],
      on: {
        BROWSER_NAVIGATION: [
          {
            target: "signIn",
            guard: {
              type: "isBrowserNavTransitionAllowed",
              params: { allowedTargetState: "signIn" },
            },
          },
          {
            target: "verifyEmail",
            guard: {
              type: "isBrowserNavTransitionAllowed",
              params: {
                allowedTargetState: "verifyEmail",
                validateContext: (context) =>
                  !!(
                    context.emailToVerify &&
                    context.session &&
                    context.sessionId &&
                    context.otpPhoneNumber
                  ),
              },
            },
          },
          {
            target: "initializeUserData",
          },
        ],
        FOUND_ACCOUNT: {
          target: "verifyEmail",
          actions: [
            assign((ctx, _) => {
              const newContext = {
                ...ctx,
                session: ctx.event?.session,
                sessionId: ctx.event?.sessionId,
                emailToVerify: ctx.event?.emailToVerify,
                otpPhoneNumber: ctx.event?.otpPhoneNumber,
              };

              return newContext;
            }),
          ],
        },
      },
    },
    verifyEmail: {
      entry: [
        ({ context }) => {
          navigateTo(context.navigate, {
            pathname: UserLoginStateToRouteMapping.verifyEmail,
          });
        },
      ],
      on: {
        FOUND_ACCOUNT: {
          target: "verifyEmail",
          actions: [
            assign((ctx, _) => {
              const newContext = {
                ...ctx,
                session: ctx.event?.session,
                sessionId: ctx.event?.sessionId,
                emailToVerify: ctx.event?.emailToVerify,
                otpPhoneNumber: ctx.event?.otpPhoneNumber,
              };

              return newContext;
            }),
          ],
        },
        SUBMIT_OTP_FOR_UPDATE_PHONE: {
          target: "updatePhoneNumber",
          actions: [
            assign((ctx, _) => {
              const newContext = {
                ...ctx,
                session: ctx.event?.session,
                sessionId: ctx.event?.sessionId,
              };

              return newContext;
            }),
          ],
        },
        BROWSER_NAVIGATION: [
          {
            target: "updatePhoneNumber",
            guard: {
              type: "isBrowserNavTransitionAllowed",
              params: {
                allowedTargetState: "updatePhoneNumber",
                validateContext: (context) =>
                  !!(
                    context.emailToVerify &&
                    context.session &&
                    context.sessionId &&
                    context.otpPhoneNumber
                  ),
              },
            },
          },
          {
            target: "findAccount",
            guard: {
              type: "isBrowserNavTransitionAllowed",
              params: {
                allowedTargetState: "findAccount",
              },
            },
          },
          {
            target: "initializeUserData",
          },
        ],
      },
    },
    updatePhoneNumber: {
      entry: [
        ({ context }) => {
          navigateTo(context.navigate, {
            pathname: UserLoginStateToRouteMapping.updatePhoneNumber,
          });
        },
      ],
      on: {
        BROWSER_NAVIGATION: [
          {
            target: "findAccount",
            guard: {
              type: "isBrowserNavTransitionAllowed",
              params: { allowedTargetState: "findAccount" },
            },
          },
          {
            target: "signInVerify",
            guard: {
              type: "isBrowserNavTransitionAllowed",
              params: {
                allowedTargetState: "signInVerify",
                validateContext: (context) =>
                  !!(
                    context.otpPhoneNumber &&
                    context.cognitoSession &&
                    context.isNewUser
                  ),
              },
            },
          },
          {
            target: "initializeUserData",
          },
        ],
        MOVE_TO_VERIFY_OTP: {
          target: "signInVerify",
          actions: [
            assign((ctx, event) => {
              const newContext = {
                ...ctx,
                otpPhoneNumber: ctx.event?.otpPhoneNumber,
                cognitoSession: ctx.event?.cognitoSession,
                isNewUser: ctx.event?.isNewUser,
              };

              return newContext;
            }),
          ],
        },
        "Hub.Auth.SignIn": [{ target: "initializeUserData" }],
      },
    },
    signInVerify: {
      entry: [
        "RESET_ERROR",
        ({ context }) => {
          navigateTo(context.navigate, {
            pathname: UserLoginStateToRouteMapping.signInVerify,
          });
        },
      ],
      on: {
        RESEND_VERIFY_OTP: [
          {
            target: "signInVerify",
            actions: assign({
              cognitoSession: ({ event }) => event.cognitoSession,
            }),
          },
        ],
        SUBMIT: [
          {
            target: "initializeUserData",
          },
        ],
        BROWSER_NAVIGATION: [
          {
            target: "signIn",
            guard: {
              type: "isBrowserNavTransitionAllowed",
              params: { allowedTargetState: "signIn" },
            },
          },
          {
            target: "setupUserProfile",
            guard: {
              type: "isBrowserNavTransitionAllowed",
              params: {
                allowedTargetState: "setupUserProfile",
                validateContext: (context) =>
                  !context.userProfile?.givenName ||
                  !context.userProfile?.familyName ||
                  !context.userProfile?.email,
              },
            },
          },
          {
            target: "initializeUserData",
          },
        ],
      },
    },
    initializeUserData: {
      invoke: {
        id: "initializeUserData",
        input: ({ context }) => ({
          reduxDispatch: context.reduxDispatch,
        }),
        src: "initUserData",
        onError: {
          actions: assign({
            errorMessage: ({ event }) => {
              return typeof event.error === "string"
                ? event.error
                : "Failed to initialize user data";
            },
          }),
        },
        onDone: {
          actions: [
            assign({
              user: ({ event }) => ({
                ...event.output.userInfo,
                signedIn: event.output.signedIn,
              }),
              userProfile: ({ event }) => event.output.userData.userProfile,
              topBrands: ({ event }) => event.output.userData.topBrands,
            }),
            ({ self }) => {
              const { send } = self;
              send({ type: "INITIALIZED_USER_DATA" });
            },
          ],
        },
      },
      on: {
        INITIALIZED_USER_DATA: [
          {
            target: "signIn",
            guard: { type: "isInitialLoginStateAllowed" },
          },
          {
            target: "setupUserProfile",
            guard: "needsProfileSetup",
          },
          {
            target: "joinBrandclubs",
            guard: "shouldJoinBrandClubs",
          },
          {
            target: "connectRetailers",
            guard: "shouldConnectRetailers",
          },
          {
            target: "goHome",
            actions: { type: "redirectUserAfterLoginFlow" },
          },
        ],
      },
    },
    joinBrandclubs: {
      entry: [
        ({ context }) => {
          navigateTo(context.navigate, {
            pathname: UserLoginStateToRouteMapping.joinBrandclubs,
          });
        },
      ],
      on: {
        SUBMIT: [
          { target: "connectRetailers", guard: "shouldConnectRetailers" },
          { target: "success" },
        ],
        BROWSER_NAVIGATION: [{ target: "initializeUserData" }],
      },
    },
    connectRetailers: {
      entry: [
        ({ context }) => {
          navigateTo(context.navigate, {
            pathname: UserLoginStateToRouteMapping.connectRetailers,
          });
        },
      ],
      on: {
        SUBMIT: [{ target: "success" }],
        AUTHENTICATE_WITH_RETAILER: [
          {
            target: "authenticateWithRetailer",
            actions: assign({
              connectRetailerId: ({ event }) => event.retailerId,
            }),
          },
        ],
        CONNECT_RETAILER_REQUEST_OTP: [{ target: "connectRetailerOTP" }],
        BROWSER_NAVIGATION: [{ target: "initializeUserData" }],
      },
    },
    setupUserProfile: {
      entry: [
        ({ context }) => {
          navigateTo(context.navigate, {
            pathname: UserLoginStateToRouteMapping.setupUserProfile,
          });
        },
      ],
      on: {
        SUBMIT: [{ target: "initializeUserData" }],
        BROWSER_NAVIGATION: [{ target: "initializeUserData" }],
      },
    },
    authenticateWithRetailer: {
      entry: [
        ({ context }) => {
          navigateTo(context.navigate, {
            pathname: UserLoginStateToRouteMapping.authenticateWithRetailer,
          });
        },
      ],
      on: {
        CONNECT_RETAILER_IN_PROGRESS: [{ target: "connectRetailerInProgress" }],
        BROWSER_NAVIGATION: [
          {
            target: "initializeUserData",
          },
        ],
        CONNECT_RETAILER_REQUEST_OTP: [{ target: "connectRetailerOTP" }],
      },
    },
    connectRetailerInProgress: {
      entry: [
        ({ context }) => {
          navigateTo(context.navigate, {
            pathname: UserLoginStateToRouteMapping.connectRetailerInProgress,
          });
        },
      ],
      on: {
        CONNECT_RETAILER_SUCCEEDED: [
          {
            target: "connectRetailers",
            actions: assign({
              errorMessage: undefined,
            }),
          },
        ],
        CONNECT_RETAILER_FAILED: [
          {
            target: "authenticateWithRetailer",
            actions: assign({
              errorMessage: ({ event }) => event.errorMessage,
            }),
          },
        ],
        CONNECT_RETAILER_REQUEST_OTP: [
          {
            target: "connectRetailerOTP",
            actions: assign({
              errorMessage: undefined,
            }),
          },
        ],
        BROWSER_NAVIGATION: {
          target: "initializeUserData",
        },
      },
    },
    connectRetailerOTP: {
      entry: [
        ({ context }) => {
          navigateTo(context.navigate, {
            pathname: UserLoginStateToRouteMapping.connectRetailerOTP,
          });
        },
      ],
      on: {
        SUBMIT: [{ target: "connectRetailerInProgress" }],
        BROWSER_NAVIGATION: {
          target: "initializeUserData",
        },
      },
    },
    success: {
      entry: [
        ({ context }) => {
          navigateTo(context.navigate, {
            pathname: UserLoginStateToRouteMapping.success,
          });
        },
      ],
      on: {
        SUBMIT: {
          target: "initializeUserData",
          actions: assign({
            initialOpenPath: ({ context, event }) =>
              event.redirectPath || context.initialOpenPath,
          }),
        },
        BROWSER_NAVIGATION: {
          target: "initializeUserData",
        },
      },
    },
    goHome: {
      on: {
        SYNC_STATE_WITH_ROUTE: [
          {
            target: "signIn",
            guard: ({ context, event }) => {
              return (
                getLoginStateFromRoute(event.pathname) === "signIn" &&
                !context.user?.signedIn
              );
            },
            actions: assign({
              initialOpenPath: ({ event, context }) =>
                isLoginRoute(event.prevPath.pathname)
                  ? context.initialOpenPath
                  : event.prevPath,
            }),
          },
        ],
        BROWSER_NAVIGATION: [
          {
            target: "signIn",
            guard: {
              type: "isBrowserNavTransitionAllowed",
              params: {
                allowedTargetState: "signIn",
                validateContext: (context) => !context.user?.signedIn,
              },
            },
            actions: assign({
              initialOpenPath: ({ event, context }) =>
                isLoginRoute(event.prevPath.pathname)
                  ? context.initialOpenPath
                  : event.prevPath,
            }),
          },
          {
            target: "initializeUserData",
            guard: ({ event }) => isLoginRoute(event.pathname),
            actions: assign({
              initialOpenPath: ({ event, context }) =>
                isLoginRoute(event.prevPath.pathname)
                  ? context.initialOpenPath
                  : event.prevPath,
            }),
          },
        ],
        SIGN_IN: [
          {
            target: "signIn",
            actions: assign({
              initialOpenPath: ({ context, event }) =>
                event.redirectPath ?? context.initialOpenPath,
            }),
            guard: "isNotSignedIn",
          },
        ],
      },
    },
  },
});
