import { ReactNode } from "react";

import { ButtonLoadingEllipsis } from "../../../StoreComponents/StoreButton";
import { SendStatus } from "./useSendStatus";
import { joinWaitList, sendTextInvite } from "@/api";
import { TrackingParams } from "@brandclub/common-ui";

export const getSendStatusMessage = (
  sendStatus: SendStatus,
  defaultMessage: string
): ReactNode => {
  switch (sendStatus) {
    case "sending":
      return (
        <>
          Sending
          <ButtonLoadingEllipsis baseFontSize={16} />
        </>
      );
    case "sent":
      return "Text sent!";
    default:
      return defaultMessage;
  }
};

export const sendText = async ({
  phoneNumber,
  queryParams,
}: {
  phoneNumber: string;
  queryParams?: TrackingParams;
}) => {
  const { data: waitListEntry } = await joinWaitList({
    phoneNumber:
      phoneNumber.indexOf("+") === 0 ? phoneNumber : `+1${phoneNumber}`,
    email: "gettextinvite@stackline.com",
    name: "gettextinvite@stackline.com",
  });
  await sendTextInvite({
    id: waitListEntry.id,
    forceSendInvite: true,
    queryParams,
  });
};
