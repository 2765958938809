import { moveAllUserRetailerShoppingCarts } from "@/api/rest/checkoutApi";
import {
  AnalyticsService,
  AnalyticsTrackingEvent,
  tryGetVisitorId,
} from "@brandclub/common-ui";
import { useActor } from "@xstate/react";
import { Hub } from "aws-amplify";
import { createContext, ReactElement, useCallback, useEffect } from "react";
import { useBus } from "react-bus";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ActorLogicFrom, ActorRefFrom, SnapshotFrom } from "xstate";
import { transferVisitorActions } from "../../../api/rest/authenticated/transferVisitorActions";
import {
  clearSharedAuthCookie,
  getUserSignedInState,
  getVisitorIdCookie,
} from "../../../Auth";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { clearCustomerSpendByBrand } from "../../../redux/reducers/customerSpendByBrand";
import { clearRewards } from "../../../redux/reducers/rewards";
import { clearUserProfile } from "../../../redux/reducers/userProfile";
import { CLOSE_LOGIN_DRAWER } from "../../../utils/busEvents";
import { useTrackActions } from "../../../utils/hooks/useTracking";
import Loading from "../../Loading";
import { userLoginMachine } from "./UserLoginMachines";
import { useSyncBrowserNavigationWithUserLoginState } from "./useSyncBrowserNavigationWithUserLoginState";

export const UserLoginContext = createContext<UserLoginContextType>(
  null as never
);

const loadCartIntoUser = async () => {
  try {
    const uniqueVisitorId = await tryGetVisitorId();
    const uniqueVisitorIdFromCookie = getVisitorIdCookie();
    const uniqueVisitorIdToUse =
      uniqueVisitorIdFromCookie && uniqueVisitorIdFromCookie !== ""
        ? uniqueVisitorIdFromCookie
        : uniqueVisitorId;
    if (uniqueVisitorIdToUse) {
      await moveAllUserRetailerShoppingCarts(uniqueVisitorIdToUse);
    }
  } catch (err) {
    console.error(err);
  }
};

const transferVisitorRewardsToUser = async () => {
  try {
    await transferVisitorActions();
  } catch (err) {
    console.error(err);
  }
};

const UserLoginProvider = ({ children }: { children: ReactElement }) => {
  const [trackAction] = useTrackActions();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const authConfig = useAppSelector(({ appConfig }) => appConfig?.authConfig);
  const domainConfig = useAppSelector(
    ({ appConfig }) => appConfig?.domainConfig
  );

  const [searchParams] = useSearchParams();
  let ssoRedirectUrl = searchParams.get("ssoRedirectUrl");
  if (!ssoRedirectUrl) {
    ssoRedirectUrl = searchParams.get("redirectUrl");
  }

  const bus = useBus();
  const navigate = useNavigate();

  const actor = useActor(userLoginMachine, {
    input: {
      ssoRedirectUrl,
      initialOpenPath: { pathname: location.pathname, search: location.search },
      navigate,
      reduxDispatch: dispatch,
      appConfig: {
        storeBrandingType: domainConfig?.storeBrandingType,
        brandId: domainConfig?.brandId,
        domainName: domainConfig?.domainName,
      },
    },
  });

  const [snapshot, send, actorRef] = actor;

  // used to keep login state in sync with browser navigation
  useSyncBrowserNavigationWithUserLoginState(send);

  const clearStateForSignOut = useCallback(async () => {
    clearSharedAuthCookie();
    // clear all auth snapshot.in redux store
    dispatch(clearUserProfile());
    dispatch(clearRewards());
    dispatch(clearCustomerSpendByBrand());
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      try {
        const { signedIn } = await getUserSignedInState();
        if (signedIn) {
          AnalyticsService.track(AnalyticsTrackingEvent.AUTO_SIGN_IN, {});
        }
      } catch {
      }
      return {};
    })();
  }, []);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signUp":
          trackAction(AnalyticsTrackingEvent.SIGN_UP, {});
          break;
        case "signIn":
          // complete the sign in process but we need to wait for the complete event to after doing token refresh
          trackAction(AnalyticsTrackingEvent.SIGN_IN, {});
          break;
        case "signIn_Complete":
          loadCartIntoUser();
          transferVisitorRewardsToUser();
          break;
        case "signOut":
          bus.emit(CLOSE_LOGIN_DRAWER);
          trackAction(AnalyticsTrackingEvent.SIGN_OUT, {});
          clearStateForSignOut();
          send({ type: "Hub.Auth.SignOut" });
          break;
        case "signIn_failure":
          trackAction(AnalyticsTrackingEvent.SIGN_IN_FAILURE, {});
          console.error("Auth Hub Event: signIn_failure", event, data);
          break;
        case "cognitoHostedUI_failure":
          console.error("Auth Hub Event: Sign in failure", data);
          break;
      }
    });

    return unsubscribe;
  }, [bus, clearStateForSignOut, send, trackAction]);

  if (!authConfig) {
    return <Loading fullscreen star />;
  }

  return (
    <UserLoginContext.Provider
      value={{
        actorRef: actorRef,
        snapshot: snapshot,
        send: send,
      }}
    >
      {children}
    </UserLoginContext.Provider>
  );
};

export type UserLoginContextType = {
  actorRef: ActorRefFrom<ActorLogicFrom<typeof userLoginMachine>>;
  snapshot: SnapshotFrom<typeof userLoginMachine>;
  send: ActorRefFrom<ActorLogicFrom<typeof userLoginMachine>>["send"];
};

export default UserLoginProvider;
