import { ComponentProps, FC, ReactNode, useContext, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@brandclub/common-ui";
import { TABLET_SCREEN_SIZE } from "../../AppNavigation/constants";
import { LinkProps } from "react-router-dom";
import { StyledLink } from "../../AppNavigation/StyledLink";
import { UserLoginMachinePath } from "@/components/pages/Auth/types";
import { UserLoginContext } from "@/components/pages/Auth/UserLoginProvider";

export const LoginButtonsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: 15,
  width: "100%",
}));

const StyledButton = styled(Button)({
  height: 50,
  width: "100%",
  fontSize: 16,
  [`@media (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    fontSize: 14,
  },
});
export interface LoginButtonsProps
  extends ComponentProps<typeof LoginButtonsWrapper> {
  redirectPath?: UserLoginMachinePath;
  createAccountCta?: ReactNode;
}

const useSignInWithRedirect = () => {
  const { send } = useContext(UserLoginContext);

  return useCallback(
    (redirectPath?: UserLoginMachinePath) => {
      if (redirectPath) {
        send({
          type: "SIGN_IN",
          redirectPath,
        });
      } else {
        send({ type: "SIGN_IN" });
      }
    },
    [send]
  );
};

export const CreateAccountLink: FC<Partial<LinkProps>> = ({
  children,
  ...props
}) => {
  return (
    <StyledLink to="/signin" {...props}>
      {children ?? "Sign up"}
    </StyledLink>
  );
};

const LoginButtons: FC<LoginButtonsProps> = ({
  children,
  createAccountCta,
  redirectPath,
  ...props
}) => {
  const signInWithRedirect = useSignInWithRedirect();

  const handleSignIn = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    signInWithRedirect(redirectPath);
  };
  return (
    <LoginButtonsWrapper {...props}>
      <StyledButton onClick={handleSignIn} className="login-button">
        {children ?? "Sign in"}
      </StyledButton>
      <StyledButton
        onClick={handleSignIn}
        variant="outline"
        className="login-button"
      >
        {createAccountCta ?? "Sign up"}
      </StyledButton>
    </LoginButtonsWrapper>
  );
};

export default LoginButtons;
