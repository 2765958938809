import { ComponentProps, useEffect, useRef, useState, forwardRef } from "react";
import { useInView } from "framer-motion";
import {
  BrandclubVideoControl,
  BrandclubVideoPlayer,
  BrandclubVideoWrapper,
  ControlButtonImg,
  ControlButtonImgWrapper,
} from "./BrandclubVideoStyles";
import { joinClassNames } from "@/utils/StringUtils";

interface BrandclubVideoProps
  extends Omit<ComponentProps<typeof BrandclubVideoWrapper>, "onClick"> {
  /**
   * The url of the video to play
   */
  videoUrl: string;
  /**
   * The url of the poster image to display before the video is played
   */
  posterUrl?: string;
  /**
   * If true, the video will have a play/pause button to allow the user to play/pause the video
   */
  canPlay?: boolean;
  /**
   * If true, the play button will be placed at the bottom left of the video
   */
  lowerPlaybutton?: boolean;
  /**
   * The width of the video player
   */
  width?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const MAXIMUM_ERROR_RETRY = 5;

const BrandclubVideo = forwardRef<HTMLDivElement, BrandclubVideoProps>(
  (
    {
      videoUrl,
      posterUrl,
      canPlay = true,
      lowerPlaybutton = false,
      className,
      width,
      onClick,
      ...props
    },
    forwardedRef
  ) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const videoWrapperRef = useRef<HTMLDivElement | null>(null);
    const [errorRetryCount, setErrorRetryCount] = useState(0);
    const [playing, setPlaying] = useState(false);
    const inView = useInView(videoWrapperRef, {
      once: true,
      margin: "100px",
    });

    useEffect(() => {
      if (!canPlay) {
        pauseVideo();
      }
    }, [canPlay]);

    const playVideo = () => {
      videoRef?.current?.play();
      setPlaying(true);
    };

    const pauseVideo = () => {
      videoRef?.current?.pause();
      setPlaying(false);
    };

    const handleEnd = () => {
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
        setPlaying(false);
      }
    };

    return (
      <BrandclubVideoWrapper
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        {...props}
        className={joinClassNames("brandclub_video", className)}
        ref={(r) => {
          videoWrapperRef.current = r;
          if (forwardedRef) {
            if (typeof forwardedRef === "function") {
              forwardedRef(r);
            } else {
              forwardedRef.current = r;
            }
          }
        }}
      >
        {inView && (
          <>
            <BrandclubVideoControl
              isLowerPlayButton={lowerPlaybutton}
              className="video_control"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (canPlay) {
                  if (!playing) {
                    playVideo();
                  } else {
                    pauseVideo();
                  }
                }
                onClick?.(e);
              }}
            >
              {canPlay && (
                <ControlButtonImgWrapper
                  className="control_button"
                  variant={lowerPlaybutton ? "small" : "normal"}
                >
                  <ControlButtonImg
                    playing={playing}
                    src={
                      playing
                        ? "https://media.brandclub.com/brandclub/image_asset/video_pause.svg"
                        : "https://media.brandclub.com/brandclub/image_asset/video_play.svg"
                    }
                    alt={playing ? "play" : "pause"}
                  />
                </ControlButtonImgWrapper>
              )}
            </BrandclubVideoControl>
            {!!videoUrl && (
              <BrandclubVideoPlayer
                ref={videoRef}
                src={videoUrl}
                width={width}
                playsInline
                onError={(e) => {
                  // retry loading the video
                  if (errorRetryCount < MAXIMUM_ERROR_RETRY) {
                    e.currentTarget.load();
                    setErrorRetryCount((prev) => prev + 1);
                  }
                }}
                onEnded={handleEnd}
                controls={false}
                className="video_content"
                poster={posterUrl}
              />
            )}
          </>
        )}
      </BrandclubVideoWrapper>
    );
  }
);

export default BrandclubVideo;
