import { styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DESKTOP_HORIZONTAL_PADDING,
  DESKTOP_SCREEN_SIZE,
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";

import { useNavigate } from "react-router-dom";
import { getUserSignedInState } from "../../../../Auth";

export const MainContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: "5%",
  paddingRight: "5%",
  boxSizing: "border-box",
  color: theme.palette.primary.main,
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
    backgroundColor: "#fff",
  },
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    backgroundColor: "#fff",
  },
}));

export const MainWrapper = styled("div")({
  maxWidth: DESKTOP_SCREEN_SIZE - DESKTOP_HORIZONTAL_PADDING * 2,
  width: "100%",
  height: "100%",
  display: "flex",
  // flexDirection: "column",
  // alignItems: "center",
  justifyContent: "center",
  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
});

export const MainOutlet = ({ children }: { children: React.ReactNode }) => {
  return (
    <MainContainer>
      <MainWrapper>{children}</MainWrapper>
    </MainContainer>
  );
};

type UserLoginStatus = "notChecked" | "loggedIn" | "notLoggedIn";

export const LoggedInMainOutlet = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [userLoginStatus, setUserLoginStatus] =
    useState<UserLoginStatus>("notChecked");
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserInfo = async () => {
      try {
        const { userInfo, signedIn } = await getUserSignedInState();
        if (userInfo && signedIn) {
          setUserLoginStatus("loggedIn");
          return;
        }
      } catch (error) {
        console.error(error);
      }
      // this route requires login, so redirect to login page
      setUserLoginStatus("notLoggedIn");
      // ensure that replace is true so that the user cannot navigate back to this page using the back button on the browser
      navigate("/signin", { replace: true });
    };

    checkUserInfo();
  }, [navigate]);

  if (userLoginStatus === "notChecked") {
    return null;
  }

  if (userLoginStatus === "notLoggedIn") {
    return (
      <MainOutlet>
        <div></div>
      </MainOutlet>
    );
  }

  return (
    <MainContainer className="main_container" sx={{ paddingTop: "30px" }}>
      <MainWrapper>{children}</MainWrapper>
    </MainContainer>
  );
};
