import ConfigUtils from "../../utils/ConfigUtils";
import { AppDispatch } from "../store";
import reduxApolloClient from "../reduxApolloClient";
import {
  getRewardAmountsByStatus,
  GetRewardAmountsByStatusResponse,
} from "../../api/graphQl/authenticated";
import { setRewards, setRewardsLoading } from "../reducers/rewards";

export const loadRewards =
  (brandIds?: number[]) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setRewardsLoading(true));
      const { data } =
        await reduxApolloClient.query<GetRewardAmountsByStatusResponse>({
          query: getRewardAmountsByStatus,
          fetchPolicy: "no-cache",
          variables: {
            forceRefresh: true,
            brandIds,
          },
          context: {
            uri: `${ConfigUtils.getGraphQlUri()}?id=getRewardAmountsByStatus`,
          },
        });
      if (data) {
        dispatch(setRewards(data.RewardSummary));
        return data.RewardSummary;
      }
    } catch (e) {
      console.error("loadRewards error: ", e);
    } finally {
      dispatch(setRewardsLoading(false));
    }
  };
