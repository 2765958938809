import {
  AnalyticsService,
  ProductRewardSearchResult,
  SearchResults,
} from "@brandclub/common-ui";
import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useLocationTrackingParams } from "../../../providers/LocationTrackingProvider";
import { useAppSelector } from "../../../../redux/hooks";
import reduxApolloClient from "../../../../redux/reduxApolloClient";
import axios, { axiosEdgeCached } from "../../../../utils/axios";
import { useSiteContext } from "../../../SiteProvider";
import ShareProductDialog from "../../../StoreComponents/StoreDialogs/ShareProductDialog";
import { handleJoinClub } from "../../../../api";
import { useSignedIn } from "@/utils/hooks/useSignedIn";

const Search = () => {
  const appConfig = useAppSelector(({ appConfig }) => appConfig);
  const retailers = useAppSelector(({ retailers }) => retailers);
  const brandEntity = useAppSelector(({ brandEntity }) => brandEntity);
  const mainEntity = useAppSelector(({ mainEntity }) => mainEntity);
  const userProfile = useAppSelector(({ userProfile }) => userProfile);
  const isLoggedOut = !useSignedIn().signedIn;
  const { persistentQueryParams, site, abTestingTreatments, routePrefix } =
    useSiteContext();
  const [trackingParams] = useLocationTrackingParams();

  return (
    <div style={{ width: "100%" }}>
      <SearchResults
        onShareClick={(
          e: React.MouseEvent<HTMLDivElement, MouseEvent>,
          product?: ProductRewardSearchResult
        ) => {
          e?.preventDefault();

          if (product) {
            ShareProductDialog.show(product, isLoggedOut);
          }
        }}
        LinkComponent={Link}
        useSearchParams={useSearchParams}
        useNavigate={useNavigate}
        mainEntity={mainEntity}
        brandEntity={brandEntity}
        trackingParams={trackingParams}
        analyticsTrack={async (eventName, eventPayload) => {
          await AnalyticsService.track(eventName, eventPayload);
        }}
        persistentQueryParams={persistentQueryParams}
        axios={axios}
        axiosEdgeCached={axiosEdgeCached}
        apolloClient={reduxApolloClient as any}
        site={site}
        pageContext={{
          abTestingTreatments,
        }}
        retailers={retailers ?? []}
        products={[]}
        routePrefix={routePrefix}
        handleJoinClub={handleJoinClub}
        userProfile={userProfile}
        appConfig={{
          domainConfig: appConfig?.domainConfig,
        }}
      />
    </div>
  );
};

export default Search;
